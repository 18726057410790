import React, { useEffect, useState } from 'react'
import {
  Group,
  VStack,
  Link as UIKitLink,
  HStack,
  Tag,
  Token,
  Item,
  Avatar,
  ActionButton,
  BottomSheet,
  Header,
  InputGroup,
  Button,
  Box,
  Image,
  DetailsCell,
  useStatusPopup,
  StatusPopup,
  Text,
  TextButton,
  Icon,
} from '@revolut/ui-kit'
import { z } from 'zod'
import { useParams } from 'react-router-dom'
import pluralize from 'pluralize'
import { capitalize } from 'lodash'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import {
  TenantOnboardingProgress,
  TenantOnboardingSidebar,
  formatAdminName,
} from '../common'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { getAccountSettings } from '@src/api/settings'
import {
  getStringMessageFromError,
  pushNotification,
} from '@src/store/notifications/actions'
import {
  ERROR_DEFAULT_DURATION,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { useTenantOnboardingProgress, useTenantSubscriptionInfo } from '@src/api/tenants'
import {
  BundleFeature,
  CustomPlanInterface,
  SubscriptionInfoInterface,
  SubscriptionPlanInfterface,
  UpdatePlanHeadcountInterface,
} from '@src/interfaces/plans'
import { formatDate, formatMoney } from '@src/utils/format'
import {
  SUBSCRIPTION_CURRENCY,
  formatSubscriptionFreePeriod,
} from '@src/pages/Settings/PlanManagement/common'
import { useGetSelectors } from '@src/api/selectors'
import { OnboardingCheckpointSelectorInterface } from '@src/interfaces/onboardingChecklist'
import { API, selectorKeys } from '@src/constants/api'
import {
  createCustomPlanAndSubscription,
  editSubscriptionPlan,
  updatePlannedHeadcount,
} from '@src/api/plans'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { getLastInvoiceStatusColor } from '@src/constants/columns/settings'
import { subDays } from 'date-fns'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { IdAndName } from '@src/interfaces'

const REFRESH_STATUS_INTERVAL = 5000

const FEATURES_MAP = {
  hr_bundle_features: 'HR',
  recruitment_bundle_features: 'Recruitment',
}

const formatCustomPlanDateRange = (info?: SubscriptionInfoInterface) => {
  if (
    info?.subscription_plan?.manual_payment &&
    info.subscribed_at &&
    info.next_payment_date
  ) {
    return `${formatDate(info.subscribed_at)} - ${formatDate(
      subDays(new Date(info.next_payment_date), 1),
    )}`
  }

  return '-'
}

export const Information = () => {
  const params = useParams()

  const { initialValues, values, reset } = useLapeContext<AccountsSettingsInterface>()

  const { data: subscriptionInfo, refetch } = useTenantSubscriptionInfo(values.id)

  const statusPopup = useStatusPopup()

  const [progressSidebarOpen, setProgressSidebarOpen] = useState(false)
  const [setupAnnualPlanPopupOpen, setSetupAnnualPlanPopupOpen] = useState(false)
  const [addSeatsPopupOpen, setAddSeatsPopupOpen] = useState(false)
  const [editPlanPopupOpen, setEditPlanPopupOpen] = useState(false)

  const onboardingCheckpoints = useGetSelectors<OnboardingCheckpointSelectorInterface>(
    selectorKeys.tenant_onboarding_checkpoints,
  )
  const onboardingProgress = useTenantOnboardingProgress(values.id)
  const showDemoExpirationDate =
    values.state === 'waiting_list' || values.state === 'demo'

  useEffect(() => {
    if (
      values.state === 'setup_failed' ||
      values.state === 'active' ||
      values.state === 'demo' ||
      values.state === 'closed'
    ) {
      return undefined
    }
    const refreshInterval = setInterval(async () => {
      const response = await getAccountSettings(values.id)

      if (response.data.state !== values.state) {
        if (response.data.state === 'active') {
          pushNotification({
            value: 'Account set to active',
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
        if (response.data.state === 'setup_failed') {
          pushNotification({
            value: 'Setup failed',
            duration: ERROR_DEFAULT_DURATION,
            type: NotificationTypes.error,
          })
        }
        reset(response.data)
      }
    }, REFRESH_STATUS_INTERVAL)

    return () => clearInterval(refreshInterval)
  }, [values.state])

  const onSetupAnnualPlanSuccess = () => {
    setSetupAnnualPlanPopupOpen(false)
    setEditPlanPopupOpen(false)
    setAddSeatsPopupOpen(false)

    refetch()

    statusPopup.show(
      <StatusPopup variant="success-result" onClose={statusPopup.hide}>
        <StatusPopup.Title>Success</StatusPopup.Title>
        <StatusPopup.Description>
          {initialValues.company_name} Pricing Plan Updated
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            onClick={() => {
              statusPopup.hide()
              navigateReplace(pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INVOICES, params))
            }}
            variant="secondary"
          >
            View invoices
          </Button>
          <Button
            onClick={() => {
              statusPopup.hide()
              setSetupAnnualPlanPopupOpen(false)
            }}
            variant="secondary"
          >
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <>
      <PageBody>
        <VStack space="s-24">
          {(subscriptionInfo?.subscription_plan === null ||
            subscriptionInfo?.subscription_plan.manual_payment === null) &&
          !!initialValues.custom_plan_requested_at ? (
            <Box mt="s-16">
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="Bell" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Annual plan requested</Item.Title>
                  <Item.Description>
                    {formatDistanceToNow(
                      new Date(initialValues.custom_plan_requested_at),
                    )}{' '}
                    ago
                  </Item.Description>
                </Item.Content>
                <Item.Side>
                  <ActionButton onClick={() => setSetupAnnualPlanPopupOpen(true)}>
                    Set up custom plan
                  </ActionButton>
                </Item.Side>
              </Item>
            </Box>
          ) : null}

          <FormPreview title="Plan &amp; Billing" data={subscriptionInfo}>
            <Group>
              <FormPreview.Item
                title="Subscription date"
                field="subscribed_at"
                type="date"
                insert={() => {
                  if (subscriptionInfo?.subscription_plan?.manual_payment) {
                    return formatCustomPlanDateRange(subscriptionInfo)
                  }
                  return subscriptionInfo?.subscribed_at
                    ? formatDate(subscriptionInfo.subscribed_at)
                    : '-'
                }}
              />
              <FormPreview.Item<SubscriptionInfoInterface>
                title="Subscription state"
                field="state.name"
                color={data => {
                  if (data.state?.id === 'closing') {
                    return Token.color.warning
                  }
                  if (data.state?.id === 'closed') {
                    return Token.color.red
                  }
                  return Token.color.foreground
                }}
              />
              <FormPreview.Item
                title="Plan"
                insert={() => {
                  if (subscriptionInfo?.subscription_plan?.manual_payment) {
                    return (
                      <VStack space="s-8" align="end">
                        <Text>{subscriptionInfo?.subscription_plan?.name}</Text>
                        <TextButton onClick={() => setAddSeatsPopupOpen(true)}>
                          <HStack align="center" space="s-8">
                            <Icon name="TurboTransfer" size={16} />
                            <Text>Add seats</Text>
                          </HStack>
                        </TextButton>
                        <TextButton onClick={() => setEditPlanPopupOpen(true)}>
                          <HStack align="center" space="s-8">
                            <Icon name="Pencil" size={16} />
                            <Text>Edit plan</Text>
                          </HStack>
                        </TextButton>
                      </VStack>
                    )
                  }
                  return (
                    <VStack space="s-8" align="end">
                      <Text>{subscriptionInfo?.subscription_plan?.name || '-'}</Text>
                      <TextButton onClick={() => setSetupAnnualPlanPopupOpen(true)}>
                        Switch to custom plan
                      </TextButton>
                    </VStack>
                  )
                }}
              />
              <FormPreview.Item
                title="Planned headcount"
                field="planned_headcount"
                hidden={subscriptionInfo?.planned_headcount == null}
              />
              <FormPreview.Item
                title="Value for the plan"
                insert={() =>
                  subscriptionInfo?.subscription_plan?.cycle_fee != null
                    ? `${formatMoney(
                        Number(subscriptionInfo.subscription_plan.cycle_fee),
                        SUBSCRIPTION_CURRENCY,
                      )}/active employee`
                    : '-'
                }
              />
              <FormPreview.Item
                title="Trial period"
                insert={() =>
                  subscriptionInfo?.subscription_plan?.free_days
                    ? formatSubscriptionFreePeriod(
                        subscriptionInfo.subscription_plan.free_days,
                      )
                    : '-'
                }
              />
              <FormPreview.Item
                title="Latest subscription invoice status"
                insert={() => (
                  <Text
                    color={getLastInvoiceStatusColor(
                      initialValues.latest_subscription_invoice_status,
                    )}
                  >
                    {typeof initialValues.latest_subscription_invoice_status === 'string'
                      ? capitalize(initialValues.latest_subscription_invoice_status)
                      : initialValues.latest_subscription_invoice_status?.name || '-'}
                  </Text>
                )}
              />
              <FormPreview.Item<SubscriptionInfoInterface>
                title="Payment status for last billing cycle"
                field="last_payment_status.name"
                color={data => {
                  if (data.last_payment_status?.id === 'success') {
                    return Token.color.green
                  }
                  if (data.last_payment_status?.id === 'failure') {
                    return Token.color.red
                  }
                  return Token.color.foreground
                }}
              />
              <FormPreview.Item
                title="Payment details valid"
                insert={() =>
                  values.is_payment_method_set == null
                    ? '-'
                    : values.is_payment_method_set
                    ? 'Yes'
                    : 'No'
                }
              />
              {showDemoExpirationDate && (
                <FormPreview.Item
                  title="Demo mode expiration date"
                  insert={() => {
                    return values?.demo_mode_expiration_date_time
                      ? formatDate(values.demo_mode_expiration_date_time)
                      : '-'
                  }}
                />
              )}
              <FormPreview.Item
                title="Company name for invoices"
                insert={() => values.billing_settings?.company_name || '-'}
              />
              <FormPreview.Item
                title="Company email for invoices"
                insert={() => values.billing_settings?.email || '-'}
              />
              <FormPreview.Item
                title="Company address for invoices"
                insert={() =>
                  values.billing_settings?.address ? (
                    <Text whiteSpace="pre-wrap">
                      {values.billing_settings.address.split(',').join('\n')}
                    </Text>
                  ) : (
                    '-'
                  )
                }
              />
            </Group>
          </FormPreview>

          <FormPreview data={initialValues} title="Company info">
            <Group>
              <FormPreview.Item title="Company name" field="company_name" />
              <FormPreview.Item
                title="Company subdomain"
                insert={() => {
                  const value = `${values.subdomain}.${window.location.hostname}`
                  return (
                    <UIKitLink
                      href={`${window.location.protocol}//${value}`}
                      target="_blank"
                    >
                      {value}
                    </UIKitLink>
                  )
                }}
              />
              <FormPreview.Item title="Country" field="country.name" />
              <FormPreview.Item title="Headcount" field="active_headcount" />
              <FormPreview.Item title="VAT number" field="vat_number" />
              <FormPreview.Item
                title="VAT verified"
                insert={() => (values.vat_number_verified_date_time ? 'Yes' : 'No')}
              />
              {values.labels.length > 0 ? (
                <FormPreview.Item
                  title="Labels"
                  insert={() => (
                    <HStack space="s-4">
                      {values.labels.map(label => (
                        <Tag
                          key={label}
                          variant="faded"
                          color="foreground"
                          bg={Token.color.greyTone8}
                        >
                          {label}
                        </Tag>
                      ))}
                    </HStack>
                  )}
                />
              ) : null}
              <FormPreview.Item title="Company ID" field="id" />
              <FormPreview.Item
                title="Onboarding progress"
                insert={() => (
                  <TenantOnboardingProgress
                    onSelectProgress={() => setProgressSidebarOpen(true)}
                    checkpointsSelector={onboardingCheckpoints.data}
                    progress={onboardingProgress}
                  />
                )}
              />
            </Group>
          </FormPreview>

          <FormPreview data={initialValues} title="Admin info">
            <Group>
              <FormPreview.Item title="Name" insert={formatAdminName} />
              <FormPreview.Item title="Email" field="admin.email" />
              <FormPreview.Item
                title="Phone number"
                insert={() => {
                  if (
                    !initialValues.admin ||
                    !initialValues.admin.phone_country_code ||
                    !initialValues.admin.phone_number_short
                  ) {
                    return '-'
                  }
                  return `${initialValues.admin.phone_country_code.code}${initialValues.admin.phone_number_short}`
                }}
              />
            </Group>
          </FormPreview>
        </VStack>
      </PageBody>

      <TenantOnboardingSidebar
        progress={onboardingProgress}
        open={progressSidebarOpen}
        onClose={() => setProgressSidebarOpen(false)}
      />

      <SetupAnnualPlanPopup
        id={values.id}
        open={setupAnnualPlanPopupOpen}
        onClose={() => setSetupAnnualPlanPopupOpen(false)}
        companyName={initialValues.company_name}
        requestedFeatures={initialValues.custom_plan_requested_bundle_features}
        onSuccess={onSetupAnnualPlanSuccess}
      />

      <EditAnnualPlanPopup
        plan={subscriptionInfo?.subscription_plan}
        requestedFeatures={initialValues.custom_plan_requested_bundle_features}
        open={editPlanPopupOpen}
        onClose={() => setEditPlanPopupOpen(false)}
        onSuccess={onSetupAnnualPlanSuccess}
      />

      <AddSeatsPopup
        id={values.id}
        subscriptionId={subscriptionInfo?.subscription_plan?.id}
        open={addSeatsPopupOpen}
        onClose={() => setAddSeatsPopupOpen(false)}
        onSuccess={onSetupAnnualPlanSuccess}
        subscriptionInfo={subscriptionInfo}
      />
    </>
  )
}

interface EditAnnualPlanPopupProps {
  plan?: SubscriptionPlanInfterface | null
  requestedFeatures?: BundleFeature[]
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

const EditAnnualPlanPopup = ({
  plan,
  open,
  onClose,
  onSuccess,
  requestedFeatures,
}: EditAnnualPlanPopupProps) => {
  if (!plan) {
    return null
  }

  return (
    <>
      <BottomSheet open={open} onClose={onClose}>
        <Header>
          <Header.Title>Edit Plan</Header.Title>
          <Header.Actions>
            <Image
              size={64}
              src="https://assets.revolut.com/assets/3d-images-v2/3D018.png"
              srcSet="https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png 2x, https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png 3x"
              alt="Attention sign"
            />
          </Header.Actions>
          <Header.Description>
            Changes to the price will take effect for the next billing cycle. If you wish
            to update the current plan price, please delete the open invoices of the
            current plan cycle
          </Header.Description>
        </Header>

        <LapeForm<SubscriptionPlanInfterface>
          onSubmit={form => {
            return editSubscriptionPlan(plan.id, form.values).then(resp => {
              onSuccess()
              return resp.data
            })
          }}
        >
          <FormValidatorProvider>
            <EditAnnualPlanForm
              plan={plan}
              requestedFeatures={requestedFeatures}
              onClose={onClose}
            />
          </FormValidatorProvider>
        </LapeForm>
      </BottomSheet>
    </>
  )
}

interface EditAnnualPlanFormProps {
  plan: SubscriptionPlanInfterface
  requestedFeatures?: BundleFeature[]
  onClose: () => void
}

const EditAnnualPlanForm = ({
  plan,
  requestedFeatures,
  onClose,
}: EditAnnualPlanFormProps) => {
  const { values, submit, isSubmitting } = useLapeContext<CustomPlanInterface>()
  const { validate } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  useEffect(() => {
    if (plan.cycle_fee) {
      values.cycle_fee = `${Number(plan.cycle_fee)}`
    }
    if (plan.cycle_period_days) {
      values.cycle_period_days = plan.cycle_period_days
    }
    if (plan.description) {
      values.description = plan.description
    }
    if (plan.name) {
      values.name = plan.name
    }
    if (requestedFeatures) {
      values.custom_plan_requested_bundle_features = requestedFeatures
    }
  }, [plan])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeNewTextArea name="description" label="Description" required />
        <LapeNewMultiSelect<IdAndName<BundleFeature>>
          name="custom_plan_requested_bundle_features"
          placeholder="Features"
          value={values.custom_plan_requested_bundle_features?.map(feature => {
            return {
              id: feature,
              label: FEATURES_MAP[feature],
              value: { id: feature, name: FEATURES_MAP[feature] },
            }
          })}
          onChange={options => {
            values.custom_plan_requested_bundle_features = options.map(
              opt => opt.value.id,
            )
          }}
          selector={selectorKeys.tenant_subscription_plan_bundle_features}
        >
          {option => {
            return FEATURES_MAP[option.value.id]
          }}
        </LapeNewMultiSelect>
        <LapeNewInput
          name="cycle_fee"
          label="Price per seat, GBP"
          type="number"
          required
          message={<PriceMessage />}
        />
        <LapeNewInput
          name="cycle_period_days"
          label="Duration (days)"
          type="number"
          required
        />
      </InputGroup>

      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to edit plan</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                  <StatusPopup.Actions>
                    <Button elevated onClick={statusPopup.hide} variant="secondary">
                      Close
                    </Button>
                  </StatusPopup.Actions>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
        >
          Update
        </Button>
      </BottomSheet.Actions>
    </>
  )
}

interface SetupAnnualPlanPopupProps {
  id: string | number
  open: boolean
  onClose: () => void
  companyName?: string
  onSuccess: () => void
  requestedFeatures?: BundleFeature[]
}

const SetupAnnualPlanPopup = ({
  id,
  open,
  onClose,
  companyName,
  requestedFeatures,
  onSuccess,
}: SetupAnnualPlanPopupProps) => {
  const queryClient = useQueryClient()

  return (
    <>
      <BottomSheet open={open} onClose={onClose}>
        <Header>
          <Header.Title>Set up custom plan</Header.Title>
          <Header.Description>Provide the information below</Header.Description>
        </Header>

        <LapeForm<CustomPlanInterface>
          onSubmit={form => {
            return createCustomPlanAndSubscription(id, form.values).then(resp => {
              queryClient.removeQueries(API.TENANTS)
              onSuccess()
              return resp.data
            })
          }}
        >
          <FormValidatorProvider>
            <SetupAnnualPlanForm
              companyName={companyName}
              onClose={onClose}
              requestedFeatures={requestedFeatures}
            />
          </FormValidatorProvider>
        </LapeForm>
      </BottomSheet>
    </>
  )
}

const validateAnnualPlanForm = (values: unknown) =>
  z
    .object({
      planned_headcount: z.coerce.number().gt(0).int(),
      cycle_fee: z
        .string()
        .min(1)
        .refine(value => value === null || !isNaN(Number(value)))
        .transform(value => (value === null ? null : Number(value))),
      cycle_period_days: z.coerce.number().gt(0),
    })
    .safeParse(values)

const getTotalAmount = (fee?: string, plannedHeadcount?: string) => {
  const monthlyFee = Number(fee)
  const headcount = Number(plannedHeadcount)

  if (isNaN(monthlyFee) || isNaN(headcount)) {
    return '-'
  }
  return formatMoney(monthlyFee * headcount, 'GBP')
}

const PriceMessage = () => (
  <Text color={Token.color.greyTone50}>Price per seat for the whole cycle</Text>
)

interface SetupAnnualPlanFormProps {
  companyName?: string
  requestedFeatures?: BundleFeature[]
  onClose: () => void
}

const SetupAnnualPlanForm = ({
  companyName,
  onClose,
  requestedFeatures,
}: SetupAnnualPlanFormProps) => {
  const { values, submit, isSubmitting } = useLapeContext<CustomPlanInterface>()
  const { validate } = useSafeFormValidator()
  const statusPopup = useStatusPopup()

  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false)

  const isValidForm = validateAnnualPlanForm(values).success

  useEffect(() => {
    values.name = 'Custom annual plan'
    values.description =
      'Perfect for companies of all sizes. For all your HR, Recruitment and Performance needs'
    values.cycle_period_days = 365
    values.custom_plan_requested_bundle_features = requestedFeatures
      ? [...requestedFeatures]
      : []
  }, [])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeNewTextArea name="description" label="Description" required />
        <LapeNewInput
          name="planned_headcount"
          label="Number of seats"
          required
          type="number"
        />
        <LapeNewInput
          name="cycle_fee"
          label="Price per seat, GBP"
          type="number"
          required
          message={<PriceMessage />}
        />
        <LapeNewMultiSelect<IdAndName<BundleFeature>>
          name="custom_plan_requested_bundle_features"
          placeholder="Features"
          required
          value={values.custom_plan_requested_bundle_features?.map(feature => {
            return {
              id: feature,
              label: FEATURES_MAP[feature],
              value: { id: feature, name: FEATURES_MAP[feature] },
            }
          })}
          onChange={options => {
            values.custom_plan_requested_bundle_features = options.map(
              opt => opt.value.id,
            )
          }}
          selector={selectorKeys.tenant_subscription_plan_bundle_features}
        >
          {option => {
            return FEATURES_MAP[option.value.id]
          }}
        </LapeNewMultiSelect>
        <LapeNewInput
          name="cycle_period_days"
          label="Billing cycle duration (days)"
          type="number"
          required
        />
        <DetailsCell py="s-20">
          <DetailsCell.Title>Total per billing cycle</DetailsCell.Title>
          <DetailsCell.Content>
            {getTotalAmount(values?.cycle_fee, values?.planned_headcount)}
          </DetailsCell.Content>
        </DetailsCell>
      </InputGroup>

      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => setConfirmationPopupOpen(true)}
          disabled={!isValidForm}
          pending={isSubmitting}
        >
          Confirm
        </Button>
      </BottomSheet.Actions>

      <BottomSheet
        open={confirmationPopupOpen}
        onClose={() => setConfirmationPopupOpen(false)}
      >
        <Header>
          <Header.Title>Attention</Header.Title>
          <Header.Actions>
            <Image
              size={64}
              src="https://assets.revolut.com/assets/3d-images-v2/3D018.png"
              srcSet="https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png 2x, https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png 3x"
              alt="Attention sign"
            />
          </Header.Actions>
          <Header.Description>
            Setting up this plan will enrol the customer onto the plan immediately. Are
            you happy to proceed?
          </Header.Description>
        </Header>

        <Group>
          <DetailsCell>
            <DetailsCell.Title>Tenant</DetailsCell.Title>
            <DetailsCell.Content>{companyName}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Number of seats</DetailsCell.Title>
            <DetailsCell.Content>{values?.planned_headcount}</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Price per seat</DetailsCell.Title>
            <DetailsCell.Content>
              {formatMoney(Number(values?.cycle_fee), 'GBP')}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Billing cycle duration (days)</DetailsCell.Title>
            <DetailsCell.Content>
              {pluralize('day', values?.cycle_period_days, true)}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Total per billing cycle</DetailsCell.Title>
            <DetailsCell.Content>
              {getTotalAmount(values?.cycle_fee, values.planned_headcount)}
            </DetailsCell.Content>
          </DetailsCell>
        </Group>

        <BottomSheet.Actions horizontal>
          <Button onClick={() => setConfirmationPopupOpen(false)} variant="secondary">
            Back
          </Button>
          <Button
            onClick={() => {
              setConfirmationPopupOpen(false)

              validate(
                () => submit(),
                error => {
                  setConfirmationPopupOpen(false)

                  statusPopup.show(
                    <StatusPopup variant="error">
                      <StatusPopup.Title>Failed to setup pricing plan</StatusPopup.Title>
                      <StatusPopup.Description>
                        {getStringMessageFromError(error)}
                      </StatusPopup.Description>
                      <StatusPopup.Actions>
                        <Button elevated onClick={statusPopup.hide} variant="secondary">
                          Close
                        </Button>
                      </StatusPopup.Actions>
                    </StatusPopup>,
                  )
                },
              )()
            }}
          >
            Set up
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}

interface AddSeatsPopupProps {
  id: string | number
  subscriptionId?: number
  open: boolean
  onClose: () => void
  onSuccess: () => void
  subscriptionInfo?: SubscriptionInfoInterface
}

const AddSeatsPopup = ({
  id,
  subscriptionId,
  open,
  onClose,
  onSuccess,
  subscriptionInfo,
}: AddSeatsPopupProps) => {
  if (!subscriptionId) {
    return null
  }

  return (
    <>
      <BottomSheet open={open} onClose={onClose}>
        <Header>
          <Header.Title>Set Up Additional Seats</Header.Title>
          <Header.Description>
            For period {formatCustomPlanDateRange(subscriptionInfo)}
          </Header.Description>
        </Header>

        <LapeForm<UpdatePlanHeadcountInterface>
          onSubmit={form => {
            return updatePlannedHeadcount(id, form.values).then(resp => {
              onSuccess()
              return resp.data
            })
          }}
        >
          <FormValidatorProvider>
            <AddSeatsPopupForm onClose={onClose} />
          </FormValidatorProvider>
        </LapeForm>
      </BottomSheet>
    </>
  )
}

const validateAddSeatsForm = (values: unknown) =>
  z
    .object({
      headcount_change: z.coerce.number().gt(0).int(),
      fee: z
        .string()
        .min(1)
        .refine(value => value === null || !isNaN(Number(value)))
        .transform(value => (value === null ? null : Number(value))),
      effective_date: z.string().min(1),
    })
    .safeParse(values)

interface AddSeatsPopupFormProps {
  onClose: () => void
}

const AddSeatsPopupForm = ({ onClose }: AddSeatsPopupFormProps) => {
  const { values, submit, isSubmitting } = useLapeContext<UpdatePlanHeadcountInterface>()
  const { validate } = useSafeFormValidator()
  const statusPopup = useStatusPopup()

  const isValidForm = validateAddSeatsForm(values).success

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="headcount_change"
          label="Number of additional seats"
          required
          type="number"
        />
        <LapeNewInput
          name="fee"
          label="Price per seat, GBP"
          type="number"
          required
          message={<PriceMessage />}
        />
        <DetailsCell>
          <DetailsCell.Title>Total amount</DetailsCell.Title>
          <DetailsCell.Content>
            {getTotalAmount(values.fee, values.headcount_change)}
          </DetailsCell.Content>
        </DetailsCell>
        <LapeDatePickerInput name="effective_date" label="Effective date" required />
      </InputGroup>

      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to add seats</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                  <StatusPopup.Actions>
                    <Button elevated onClick={statusPopup.hide} variant="secondary">
                      Close
                    </Button>
                  </StatusPopup.Actions>
                </StatusPopup>,
              )
            },
          )}
          disabled={!isValidForm}
          pending={isSubmitting}
        >
          Set up
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
