import React, { useEffect, useMemo, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import { Flex, Search, VStack, Widget } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { BaseOption, CycleOption } from '@src/interfaces/selectors'
import { FilterByInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName } from '@src/utils/toIdAndName'
import { navigateTo } from '@src/actions/RouterActions'
import { SpecialisationFilterTitle } from '@src/features/Roles/helpers'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import {
  ChartTypeOption,
  ChartTypeSelector,
  getChartTypeOption,
} from '@src/features/ReviewCycle/Analytics/Skills/ChartTypeSelector'
import { CycleFilterButton } from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { SkillRatingsDistributionBarChart } from '@src/features/ReviewCycle/Analytics/Skills/Charts/RatingDistributionGridBarChart/BarChart'
import {
  SkillRatingsDistributionFilterKeys,
  SkillRatingsDistributionGroupTypes,
  useGetSkillsRatingsDistribution,
} from '@src/features/ReviewCycle/Analytics/api/skills'
import {
  BENCHMARK_CHART_COLORS,
  DISTRIBUTION_CHART_COLORS,
} from '@src/features/ReviewCycle/Analytics/Skills/Charts/RatingDistributionGridBarChart/constants'
import { useDistributionOptions } from '@src/features/ReviewCycle/Analytics/Skills/hooks/useDistributionOptions'
import { defaultGroupType } from '@src/features/ReviewCycle/Analytics/Skills/constants'

interface Props {
  cycleId?: number
  departmentId?: number
  cycleOptions: CycleOption[]
  cycleFilterKey: string
  onFilterCycle: (cycleId: number) => void
}

export const SkillsRatingsDistributionWidget = ({
  cycleId,
  departmentId,
  cycleOptions,
  cycleFilterKey,
  onFilterCycle,
}: Props) => {
  const [chartTypeOption, setChartTypeOption] = useState<ChartTypeOption>(
    getChartTypeOption(defaultGroupType),
  )
  const [teamsOptions, setTeamsOptions] = useState<BaseOption<number>[]>([])
  const [specialisationsOptions, setSpecialisationsOptions] = useState<
    BaseOption<number>[]
  >([])
  const [ratingsOptions, setRatingsOptions] = useState<BaseOption<string>[]>([])
  const [searchQuery, setSearchQuery] = useState<string>()
  const [deferredSearchQuery, setDeferredSearchQuery] = useState(searchQuery)

  const groupType = chartTypeOption.id
  const isDistributionChart =
    groupType === SkillRatingsDistributionGroupTypes.Distribution
  const ratingFilterKey = isDistributionChart
    ? SkillRatingsDistributionFilterKeys.RatingCategory
    : SkillRatingsDistributionFilterKeys.RatingExpectation

  const { data = [], isLoading } = useGetSkillsRatingsDistribution({
    [SkillRatingsDistributionFilterKeys.CycleId]: cycleId,
    [SkillRatingsDistributionFilterKeys.DepartmentId]: departmentId,
    [SkillRatingsDistributionFilterKeys.TeamId]:
      teamsOptions.length > 0 ? teamsOptions.map(({ id }) => id) : undefined,
    [SkillRatingsDistributionFilterKeys.SpecialisationId]:
      specialisationsOptions.length > 0
        ? specialisationsOptions.map(({ id }) => id)
        : undefined,
    [SkillRatingsDistributionFilterKeys.GroupType]: groupType,
    [ratingFilterKey]:
      ratingsOptions.length > 0
        ? ratingsOptions.map(({ id }) => id).join(',')
        : undefined,
    [SkillRatingsDistributionFilterKeys.Search]: deferredSearchQuery || undefined,
  })
  const { data: distributionOptions, isLoading: isDistributionOptionsLoading } =
    useDistributionOptions(groupType)

  const { height: chartContainerHeight, ref: chartContainerRef } =
    useResizeObserver<HTMLDivElement>()

  useEffect(() => {
    const timerId = setTimeout(() => setDeferredSearchQuery(searchQuery), 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [searchQuery])

  const dataColors = isDistributionChart
    ? DISTRIBUTION_CHART_COLORS
    : BENCHMARK_CHART_COLORS

  const cycleFilter = useMemo<FilterByInterface[]>(
    () => [
      {
        columnName: cycleFilterKey,
        filters: cycleId !== undefined ? [toIdAndName(String(cycleId))] : [],
      },
    ],
    [cycleFilterKey, cycleId],
  )

  const handleFilterCycle = (filter: FilterByInterface) => {
    const [cycleFilterOption] = filter.filters

    const filteredCycleId = Number(cycleFilterOption.id)

    if (!Number.isFinite(filteredCycleId)) {
      return
    }

    onFilterCycle(filteredCycleId)
  }

  const handleSelectChartType = (value: ChartTypeOption) => {
    setRatingsOptions([])
    setChartTypeOption(value)
  }

  const handleRowClick = (skillId: number) => {
    const params = {
      id: departmentId,
      skillId,
    }

    const queryParams = {
      [SkillRatingsDistributionFilterKeys.GroupType]: groupType,
      [SkillRatingsDistributionFilterKeys.CycleId]: cycleId ? String(cycleId) : '',
      [SkillRatingsDistributionFilterKeys.SpecialisationId]: specialisationsOptions
        .map(({ id }) => id)
        .join(','),
      [SkillRatingsDistributionFilterKeys.TeamId]: teamsOptions
        .map(({ id }) => id)
        .join(','),
    }

    navigateTo(
      pathToUrl(
        ROUTES.FORMS.DEPARTMENT.SKILLS_ANALYTICS.RATING_DISTRIBUTION,
        params,
        queryParams,
      ),
    )
  }

  return (
    <Widget
      p="s-16"
      data-testid="performance_analytics_skills_ratings_distribution_widget"
    >
      <VStack space="s-16">
        <Flex
          gap="s-16"
          flexWrap="wrap"
          data-testid="performance_analytics_skills_ratings_distribution_filters"
        >
          <Search
            variant="compact"
            placeholder="Search by skill name"
            value={searchQuery}
            onChange={setSearchQuery}
          />
          <CycleFilterButton
            columnName={cycleFilterKey}
            filter={cycleFilter}
            selector={() => Promise.resolve({ options: cycleOptions })}
            onFilterChange={handleFilterCycle}
          />
          <ButtonFilter<BaseOption<number>>
            value={teamsOptions}
            type={FilterSelectType.MultiSelect}
            selector={selectorKeys.team}
            title={teamsOptions.length ? 'Selected teams' : 'All teams'}
            useIcon="Profile"
            variant="inactive"
            onChange={setTeamsOptions}
          />
          <ButtonFilter<BaseOption<number>>
            value={specialisationsOptions}
            type={FilterSelectType.MultiSelect}
            selector={selectorKeys.specialisations}
            title={<SpecialisationFilterTitle count={specialisationsOptions.length} />}
            useIcon="RepairTool"
            variant="inactive"
            onChange={setSpecialisationsOptions}
          />
          <ButtonFilter<BaseOption<string>>
            value={ratingsOptions}
            type={FilterSelectType.MultiSelect}
            selector={() => Promise.resolve({ options: distributionOptions })}
            title={ratingsOptions.length ? 'Selected ratings' : 'All ratings'}
            useIcon="Trophy"
            variant="inactive"
            onChange={setRatingsOptions}
          />
          <ChartTypeSelector value={chartTypeOption} onSelect={handleSelectChartType} />
        </Flex>
        <Widget p="s-16" ref={chartContainerRef}>
          <SkillRatingsDistributionBarChart
            data={data}
            dataKeys={distributionOptions}
            dataColors={dataColors}
            isLoading={isLoading || isDistributionOptionsLoading}
            containerHeight={chartContainerHeight}
            onRowClick={handleRowClick}
          />
        </Widget>
      </VStack>
    </Widget>
  )
}
