import { Token } from '@revolut/ui-kit'

export const normalizedScoreToColors = (score: number | null | undefined) => {
  if (score == null) {
    return undefined
  }
  if (score > 8) {
    return { color: Token.color.success, bgColor: Token.color.green_20 }
  }
  if (score > 5) {
    return { color: Token.color.warning, bgColor: Token.color.yellow_20 }
  }
  return { color: Token.color.danger, bgColor: Token.color.red_20 }
}

export const participationRateToColor = (
  participationRate: number | null | undefined,
) => {
  if (participationRate == null) {
    return undefined
  }
  if (participationRate >= 80) {
    return Token.color.success
  }
  if (participationRate >= 50) {
    return Token.color.warning
  }
  return Token.color.greyTone20
}
