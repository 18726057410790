import React, { useState } from 'react'
import {
  Button,
  Item,
  MoreBar,
  Portal,
  Side,
  Skeleton,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { Info, Warning, Questionnaire } from '@revolut/icons'
import SideBar from '@src/components/SideBar/SideBar'
import pluralize from 'pluralize'
import StageCounts from '@src/features/CommonCandidatesTable/StageCounts'
import { useGetStages } from '@src/api/recruitment/candidates'
import {
  useGetBulkHiringStages,
  useGetSpecialisationHiringStage,
} from '@src/api/hiringProcess'
import SendOnlineTestPreview from '@src/pages/Forms/SendOnlineTest/SendOnlineTestPreview'
import { bulkSendOnlineTest } from '@src/api/recruitment/interviews'
import BulkStatusPopup from '@src/components/BulkStatusPopup/BulkStatusPopup'
import { OnlineTestInterface } from '@src/interfaces/hiringProccess'

type SendOnlineTestActionProps = {
  disabled: boolean
  onClick: () => void
}

export const SendOnlineTestAction = ({
  disabled,
  onClick,
}: SendOnlineTestActionProps) => {
  const tooltip = useTooltip()
  return (
    <>
      <MoreBar.Action
        {...tooltip.getAnchorProps()}
        useIcon={Questionnaire}
        aria-disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onClick()
          }
        }}
      >
        Send online test
      </MoreBar.Action>
      {disabled && (
        <Tooltip {...tooltip.getTargetProps()}>
          You can only send online test in bulk only for candidate(s) from the same
          process
        </Tooltip>
      )}
    </>
  )
}

type SendOnlineTestSidebarProps = {
  candidateIds: number[]
  candidates: {
    id: number
    full_name: string
  }[]
  specialisationIds: number[]
  open: boolean
  onClose: () => void
}

const SendOnlineTestSidebar = ({
  candidateIds,
  candidates,
  specialisationIds,
  open,
  onClose,
}: SendOnlineTestSidebarProps) => {
  const [pending, setPending] = useState(false)
  const [success, setSuccess] = useState<boolean | null>(null)
  const [onlineTest, setOnlineTest] = useState<OnlineTestInterface>()

  const {
    data: hiringStagesData,
    isError: isHiringStageIdsError,
    isLoading: isHiringStageIdsLoading,
  } = useGetBulkHiringStages(candidateIds)

  const hiringStageIds = [
    ...(hiringStagesData?.hiring_stages || []),
    ...(hiringStagesData?.posting_hiring_stages || []),
  ]

  const hiringStageId = hiringStageIds?.length === 1 ? hiringStageIds[0] : null

  const { data: hiringStage } = useGetSpecialisationHiringStage(hiringStageId)

  if (!onlineTest && hiringStage?.online_test) {
    setOnlineTest({ ...hiringStage.online_test })
  }

  const { data: stagesData, isError: stagesError } = useGetStages(
    open ? candidateIds : undefined,
    specialisationIds,
  )
  const stages = stagesData?.filter(({ stage_type }) => stage_type !== 'Total') ?? []

  const handleClose = () => {
    setSuccess(null)
    onClose()
  }

  const handleSubmit = async () => {
    if (!onlineTest) {
      return
    }
    setPending(true)
    try {
      await bulkSendOnlineTest({
        candidate_ids: candidateIds,
        test_id: onlineTest.id,
      })
      setSuccess(true)
    } catch (e) {
      setSuccess(false)
    } finally {
      setPending(false)
    }
  }

  const renderForm = () => {
    if (isHiringStageIdsLoading) {
      return <Skeleton />
    }

    if (stagesError || isHiringStageIdsError) {
      return (
        <Item useIcon={Warning}>
          <Item.Content>
            <Item.Title>Something went wrong</Item.Title>
            <Item.Description>Please refresh the page and try again</Item.Description>
          </Item.Content>
        </Item>
      )
    }

    if (!hiringStageId) {
      return (
        <Item useIcon={Info}>
          <Item.Content>
            You can only send online test in bulk only for candidate(s) from the same
            process
          </Item.Content>
        </Item>
      )
    }

    return (
      <>
        <StageCounts stages={stages} />
        <SendOnlineTestPreview
          hiringStageId={hiringStageId}
          onlineTest={onlineTest}
          onChange={setOnlineTest}
        />
        <Side.Actions horizontal>
          <Button variant="secondary" onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            elevated
            disabled={pending || !onlineTest}
            onClick={handleSubmit}
            pending={pending}
          >
            Send test
          </Button>
        </Side.Actions>
      </>
    )
  }

  return (
    <>
      <SideBar
        variant="wide"
        isOpen={open}
        onClose={handleClose}
        title={`Send online test for ${pluralize('candidate', candidates?.length, true)}`}
      >
        {renderForm()}
      </SideBar>
      <Portal>
        <BulkStatusPopup isSuccess={success} onClose={handleClose} />
      </Portal>
    </>
  )
}

export default SendOnlineTestSidebar
