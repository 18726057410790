import { PermissionTypes } from '@src/store/auth/types'

export const hasHRPermissions = (permissions: string[]) => {
  return permissions.some(
    permission =>
      permission === PermissionTypes.HRManagerPermissions ||
      permission === PermissionTypes.LeadHRManagerPermissions ||
      permission === PermissionTypes.AdminHRManagerPermissions,
  )
}
