import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { apiV2 } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  OneToOneMeeting,
  FreeBusyCalendar,
  GoogleCalendarEventAttendee,
  MeetingEvent,
  OneToOneMeetingsStats,
  MeetingTemplateInterface,
  TimeSlot,
  MeetingNotesFeedback,
  CreateOneToOneMeetingNotePayloadInterface,
  UpdateOneToOneMeetingNotePayloadInterface,
  OneToOneMeetingChangelogItem,
  MeetingsSummaryInterface,
} from '@src/interfaces/meetings'
import {
  useFetchV2,
  usePostV2,
  UseQueryOptions,
  useUpdateV2,
} from '@src/utils/reactQuery'
import {
  FetchDataQueryInterface,
  FilterByInterface,
  SortByInterface,
} from '@src/interfaces/data'
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useContext } from 'react'
import { ExtensionApiHandlerContext } from '@src/utils/extension'

export const companyMeetingsRequests: TableRequestInterface<
  OneToOneMeeting,
  OneToOneMeetingsStats
> = {
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get(
      `${API.MEETINGS}/managerMeetings`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
  getStats: async ({ filters }) =>
    apiV2.get(
      `${API.MEETINGS}/managerMeetings/stats`,
      {
        params: filterSortPageIntoQuery(undefined, filters),
      },
      'v1',
    ),
}

export const templateRequests: TableRequestInterface<MeetingTemplateInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get(`${API.FEEDBACK_TEMPLATES}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const meetingsTemplatesFormRequests: RequestInterfaceNew<MeetingTemplateInterface> =
  {
    delete: async ({ id }) => apiV2.delete(`${API.FEEDBACK_TEMPLATES}/${id}`),
    get: async ({ id }) => apiV2.get(`${API.FEEDBACK_TEMPLATES}/${id}`),
    update: async (data, { id }) => apiV2.patch(`${API.FEEDBACK_TEMPLATES}/${id}`, data),
    submit: async data =>
      apiV2.post(API.FEEDBACK_TEMPLATES, {
        ...data,
        audiences: data.audiences || [],
        items: data.items || [],
      }),
  }

export const meetingsTemplatesDefaultSettingsFormRequests: RequestInterfaceNew<MeetingTemplateInterface> =
  {
    get: async () => apiV2.get(`${API.FEEDBACK_DEFAULT_TEMPLATES}/1`),
    update: async data => apiV2.patch(`${API.FEEDBACK_DEFAULT_TEMPLATES}/1`, data),
    submit: async data =>
      apiV2.post(API.FEEDBACK_DEFAULT_TEMPLATES, {
        ...data,
        items: data.items || [],
      }),
  }

export const useGetEmployeeMeeting = (
  employeeId: number | string,
  meetingId: number | string,
) =>
  useFetchV2<OneToOneMeeting>({
    url: `${API.MEETINGS}/employeeManagerMeetings/${employeeId}/${meetingId}`,
  })

export const useUpdateEmployeeManagerMeeting = () => {
  const queryClient = useQueryClient()

  const apiHandler = useContext(ExtensionApiHandlerContext)
  let apiFunc = apiHandler || apiV2

  return useMutation<
    AxiosResponse<OneToOneMeeting>,
    AxiosError,
    [
      { meetingId: string | number; employeeId: string | number },
      Partial<OneToOneMeeting>,
    ]
  >(
    ([{ meetingId, employeeId }, data]) =>
      apiFunc.patch(
        `${API.MEETINGS}/employeeManagerMeetings/${employeeId}/${meetingId}`,
        data,
      ),
    {
      onSuccess: (response, variables) => {
        if (response.data) {
          queryClient.setQueryData<OneToOneMeeting>(
            [
              `${API.MEETINGS}/employeeManagerMeetings/${variables[0].employeeId}/${variables[0].meetingId}`,
            ],
            _oldData => response.data,
          )
        }
      },
    },
  )
}

export const useGetEmployeeMeetingChangelog = (
  meetingId: number,
  options?: { filters?: FilterByInterface[]; sort?: SortByInterface[]; page?: number },
) =>
  useFetchV2<GetRequestInterface<OneToOneMeetingChangelogItem>>({
    url: `${API.FEEDBACK_ONE_TO_ONE_NOTES}/${meetingId}/changelog`,
    queryOptions: {
      enabled: !!meetingId,
      keepPreviousData: true,
    },
    params: {
      params: filterSortPageIntoQuery(options?.sort, options?.filters, options?.page),
    },
  })

export const getEmployeeMeetings = (
  id: number,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<OneToOneMeeting>> =>
  apiV2.get(`${API.MEETINGS}/employeeManagerMeetings/${id}`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export enum OneToOneMeetingsQueryKeys {
  EmployeeId = 'employee_id',
  ManagerId = 'manager_id',
  EmployeeFullName = 'employee__full_name',
  ManagerFullName = 'manager__full_name',
  EmployeeRelationType = 'employee_relation_type',
  RelationType = 'relation_type',
  ManagerTeamDepartmentId = 'manager__team__department_id',
  ManagerTeamDepartmentName = 'manager__team__department__name',
  NoMeeting = 'is_missing_meeting',
}

interface EmployeeMeetingsParams
  extends Partial<{
    [OneToOneMeetingsQueryKeys.EmployeeId]: number
    [OneToOneMeetingsQueryKeys.ManagerId]: number
  }> {}

export const useGetEmployeeMeetings = (
  employeeId?: number,
  params?: EmployeeMeetingsParams,
  queryOptions?: UseQueryOptions<GetRequestInterface<OneToOneMeeting>>,
) =>
  useFetchV2<GetRequestInterface<OneToOneMeeting>>({
    url: `${API.MEETINGS}/employeeManagerMeetings/${employeeId}`,
    params: { params },
    queryOptions: {
      ...(queryOptions || {}),
      enabled: queryOptions?.enabled !== false && employeeId !== undefined,
    },
  })

export enum MeetingTemplatesQueryKeys {
  CreatedBy = 'created_by',
  Frequency = 'frequency',
  TemplateName = 'name',
  Duration = 'duration',
}

export enum OneToOneMeetingsFiltersQueryKeys {
  ParticipantId = 'participant_id',
  ParticipantFullName = 'participant__full_name',
  // searching by following fields: manager__full_name, employee__full_name
  Search = 'search',
}

export interface EmployeeMeetingEventsParams {
  employee_id: number
  start?: string
  // employee1.id,employee2.id,...employeeN.id
  attendees?: string
  is_assigned?: boolean
  is_series?: boolean
}

export const useGetEmployeeMeetingEvents = (params: EmployeeMeetingEventsParams) =>
  useFetchV2<{ results: MeetingEvent<GoogleCalendarEventAttendee>[] }>({
    url: API.MEETINGS_EVENTS,
    params: { params: { ...params, ordering: '-start' } },
  })

export const useGetMeetingNotesFeedback = (id?: number) =>
  useFetchV2<MeetingNotesFeedback>({
    url: `${API.FEEDBACK_ONE_TO_ONE_NOTES}/${id}/meetingFeedback`,
    version: 'v1',
    queryOptions: {
      enabled: Boolean(id),
    },
  })

export const useSyncMeetings = () => {
  const queryClient = useQueryClient()
  const apiHandler = useContext(ExtensionApiHandlerContext)
  let apiFunc = apiHandler || apiV2
  return useMutation<AxiosResponse<{}>, AxiosError>(
    () => apiFunc.post('/meetings/newMeetings/fetch', undefined, undefined, 'v1'),
    {
      onSuccess: response => {
        if (response.status === 204) {
          queryClient.refetchQueries([API.MEETINGS_EVENTS])
        }
      },
    },
  )
}

export const useCreateOneToOneNote = (meetingId?: number) =>
  usePostV2<MeetingNotesFeedback, undefined, CreateOneToOneMeetingNotePayloadInterface>({
    url: Number.isInteger(meetingId)
      ? `${API.FEEDBACK_ONE_TO_ONE_NOTES}/${meetingId}`
      : '',
  })

export const useGenerateOneToOneNoteSummary = (meetingId?: number, noteId?: number) =>
  usePostV2({
    url:
      Number.isInteger(meetingId) && Number.isInteger(noteId)
        ? `${API.FEEDBACK_ONE_TO_ONE_NOTES}/${meetingId}/${noteId}/generateSummary`
        : '',
  })

export const useUpdateOneToOneNote = () => {
  const queryClient = useQueryClient()

  const apiHandler = useContext(ExtensionApiHandlerContext)
  let apiFunc = apiHandler || apiV2

  return useMutation<
    AxiosResponse<MeetingNotesFeedback>,
    AxiosError,
    [
      { meetingId: string | number; noteId: string | number },
      Partial<UpdateOneToOneMeetingNotePayloadInterface> &
        Pick<UpdateOneToOneMeetingNotePayloadInterface, 'origin' | 'save_hash'>, // required on every patch
    ]
  >(
    ([{ meetingId, noteId }, data]) =>
      apiFunc.patch(`${API.FEEDBACK_ONE_TO_ONE_NOTES}/${meetingId}/${noteId}`, data),
    {
      onSuccess: (response, variables) => {
        if (response.data) {
          queryClient.setQueryData<MeetingNotesFeedback>(
            [
              `${API.FEEDBACK_ONE_TO_ONE_NOTES}/${variables[0].meetingId}/${variables[0].noteId}`,
            ],
            _oldData => response.data,
          )
        }
      },
    },
  )
}

export const deleteMeetingNotesFeedback = (meetingId: number, noteId: number) =>
  apiV2.delete<MeetingNotesFeedback>(
    `${API.FEEDBACK_ONE_TO_ONE_NOTES}/${meetingId}/${noteId}`,
  )

export interface EmployeeFreeBusySlotsParams {
  time_min: string
  time_max: string
  employee_ids: string
}

export const useGetEmployeeFreeBusySlots = (params: EmployeeFreeBusySlotsParams) =>
  useFetchV2<FreeBusyCalendar>({
    url: `${API.MEETINGS}/slots/freebusy`,
    params: { params },
  })

export const useGetMeetingTemplate = (id: string) =>
  useFetchV2<MeetingTemplateInterface>({
    url: `${API.FEEDBACK_TEMPLATES}/${id}`,
  })

export const useGetOneToOneMeetingManagerTemplate = (managerId?: number) =>
  useFetchV2<MeetingTemplateInterface>({
    url: `${API.FEEDBACK_TEMPLATES}/match/${managerId}`,
    queryOptions: {
      enabled: managerId !== undefined,
    },
  })

export interface ScheduleOneToOneMeetingPayload extends TimeSlot {
  recurrence: string[]
  title: string
}

export const useScheduleOneToOneMeeting = (
  employeeId: number | string,
  meetingId: number | string,
) =>
  usePostV2<OneToOneMeeting, undefined, ScheduleOneToOneMeetingPayload>({
    url: `${API.MEETINGS}/employeeManagerMeetings/${employeeId}/${meetingId}/createSeries`,
  })

export const useLinkOneToOneMeeting = (employeeId: number | string) =>
  useUpdateV2<OneToOneMeeting, { meeting_series: { id: number } }>({
    url: `${API.MEETINGS}/employeeManagerMeetings/${employeeId}`,
    version: 'v1',
    updater: (_oldData, newData) => newData,
  })

export const useGetMeetingsSummary = (
  employeeId: number,
  cycleId: number | string | undefined,
  enabled = true,
) =>
  useFetchV2<GetRequestInterface<MeetingsSummaryInterface>>({
    url: `${API.FEEDBACK_ONE_TO_ONE_SUMMARY}/${employeeId}`,
    params: {
      params: {
        cycle_id: cycleId,
      },
    },
    queryOptions: {
      enabled: enabled && cycleId !== undefined,
    },
  })
