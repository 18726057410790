import { useEffect, useState } from 'react'

import { IdAndName } from '@src/interfaces'
import { formatDate } from '@src/utils/format'
import { toIdAndName } from '@src/utils/toIdAndName'
import { RoundOption, TimelineFilterMode } from '../types'

const DATE_FORMAT = 'yyyy-MM-dd'

export const useGetDatesRange = (
  selectedRound: RoundOption | null | undefined,
  filterMode: TimelineFilterMode,
) => {
  const [selectedDatesRange, setSelectedDatesRange] = useState<IdAndName<string>[]>([])

  useEffect(() => {
    if (selectedRound) {
      setSelectedDatesRange([
        toIdAndName(selectedRound.dateFrom),
        toIdAndName(selectedRound.dateTo),
      ])
    }
  }, [selectedRound])

  const calendarDateFrom = selectedDatesRange[0]
    ? formatDate(selectedDatesRange[0].id, DATE_FORMAT)
    : ''
  const calendarDateTo = selectedDatesRange[1]
    ? formatDate(selectedDatesRange[1].id, DATE_FORMAT)
    : ''

  const roundDateFrom = selectedRound?.dateFrom
    ? formatDate(selectedRound.dateFrom, DATE_FORMAT)
    : ''
  const roundDateTo = selectedRound?.dateTo
    ? formatDate(selectedRound.dateTo, DATE_FORMAT)
    : ''

  return {
    selectedDatesRange,
    setSelectedDatesRange,
    dateFrom: filterMode === 'calendar' ? calendarDateFrom : roundDateFrom,
    dateTo: filterMode === 'calendar' ? calendarDateTo : roundDateTo,
  }
}
