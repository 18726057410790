import React, { useMemo, useState } from 'react'
import {
  Group,
  Subheader,
  Item,
  Placeholder,
  Token,
  Icon,
  Header,
  ActionButton,
  Side,
  Button,
  useToggle,
  Search,
  Box,
} from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import SideBar from '@src/components/SideBar/SideBar'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useMatrixData } from '@src/features/Specialisations/hooks/useMatrixData'
import {
  CompetencyMatrixSkill,
  CompetencyPerformanceWeightType,
  RoleInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { getDefaultCompetencyFor } from '@src/features/CompetencyMatrixTable/utils'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { CreateNewSkillSideBar } from '@src/features/Skills/CreateNewSkillSideBar'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { FormattedMessage } from 'react-intl'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

interface Props {
  onClose: VoidFunction
  role: RoleInterface | undefined
  seniorities: SeniorityInterface[]
  withWeights: boolean
  type: 'specialisation' | 'role'
}

export const SkillsSideBar = ({
  onClose,
  role,
  seniorities,
  withWeights,
  type,
}: Props) => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const [isCreateNewSkillOpened, toggleCreateNewSkillOpened] = useToggle()
  const [searchValue, setSearchValue] = useState('')
  const {
    specialisationFunctionalMatrix,
    deliverablesMatrix,
    roleFunctionalMatrix,
    onChangeCompetencyMatrix,
  } = useMatrixData({ values, role, type })

  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const competencyMatrixV2 =
    useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2) && type === 'specialisation'

  const [selectedSkills, setSelectedSkills] = useState(
    values.functional_competency_matrix || [],
  )
  const [selectedWeights, setSelectedWeights] = useState(
    // check get all performance weights based on the existing skills
    values.performance_weights?.filter(weight => {
      return [
        ...deliverablesMatrix,
        ...(specialisationFunctionalMatrix || []),
        ...(roleFunctionalMatrix || []),
      ]
        .map(skill => skill.skill?.id)
        .includes(weight.skill_id)
    }),
  )
  const [competencyMatrix, setCompetencyMatrix] = useState(values.competency_matrix || [])

  const { data: fetchedSkills = [], refetch: refetchSkills } =
    useGetSelectors<CompetencyMatrixSkill>(selectorKeys.approved_functional_skills)

  const onAddSkill = (skill: CompetencyMatrixSkill) => {
    const competencies = seniorities.map(seniority => getDefaultCompetencyFor(seniority))

    if (competencyMatrixV2) {
      if (skill.id && skill.name) {
        setCompetencyMatrix([
          ...competencyMatrix,
          {
            skill_id: skill.id,
            name: skill.name,
            weight: 0,
            weight_type: CompetencyPerformanceWeightType.Skill,
            competencies,
            parent_skill: false,
          },
        ])
      }
    } else {
      setSelectedSkills(prev => [...prev, { skill, competencies }])

      if (withWeights) {
        setSelectedWeights(prev => [
          ...(prev || []),
          {
            skill_id: skill.id,
            weight: 0,
            weight_type: CompetencyPerformanceWeightType.Skill,
          },
        ])
      }
    }
  }

  const onRemoveSkill = (skillToRemove?: CompetencyMatrixSkill) => {
    if (competencyMatrixV2) {
      setCompetencyMatrix(prev =>
        prev.filter(skill => skill.skill_id !== skillToRemove?.id),
      )
    } else {
      setSelectedSkills(prev =>
        prev.filter(skill => skill.skill?.id !== skillToRemove?.id),
      )
      setSelectedWeights(prev =>
        prev?.filter(skill => skill.skill_id !== skillToRemove?.id),
      )
    }
  }

  const handleSave = () => {
    if (competencyMatrixV2) {
      onChangeCompetencyMatrix(competencyMatrix)
    } else {
      values.functional_competency_matrix = selectedSkills
      values.performance_weights = selectedWeights
    }
    onClose()
  }

  const filteredSkills = useMemo(() => {
    return fetchedSkills.filter(skill => {
      return (
        !specialisationFunctionalMatrix?.some(
          specialisationSkill => specialisationSkill.skill?.id === skill.id,
        ) &&
        !deliverablesMatrix.some(
          deliverableSkill => deliverableSkill.skill?.id === skill.id,
        ) &&
        !roleFunctionalMatrix?.some(roleSkill => roleSkill.skill?.id === skill.id) &&
        !selectedSkills.some(selectedSkill => selectedSkill.skill?.id === skill.id) &&
        !competencyMatrix.some(selectedSkill => selectedSkill.skill_id === skill.id) &&
        skill.name?.includes(searchValue)
      )
    })
  }, [
    searchValue,
    fetchedSkills,
    specialisationFunctionalMatrix,
    roleFunctionalMatrix,
    deliverablesMatrix,
    selectedSkills,
    competencyMatrix,
  ])

  const allSelectedSkills = competencyMatrixV2
    ? competencyMatrix
        .filter(skill => skill.skill_id !== null)
        .map(skill => ({
          ...skill,
          skill: { id: skill.skill_id, name: skill.name },
        }))
    : selectedSkills

  return (
    <>
      <SideBar
        title="Add skills"
        isOpen
        onClose={onClose}
        headerContent={
          <Header.Actions>
            <ActionButton
              useIcon="Plus"
              variant="accent"
              size="xs"
              onClick={e => {
                e.preventDefault()
                toggleCreateNewSkillOpened.on()
              }}
            >
              Create new skill
            </ActionButton>
          </Header.Actions>
        }
      >
        <Subheader>{values.name} skills</Subheader>
        <Group>
          {allSelectedSkills?.map(skill => (
            <Item
              key={skill.skill?.id}
              use="button"
              onClick={e => {
                e.preventDefault()
                onRemoveSkill(skill.skill)
              }}
            >
              <Item.Prefix>
                <Icon
                  name="MinusCircle"
                  aria-label="Remove skill"
                  color={Token.color.danger}
                />
              </Item.Prefix>
              <Item.Content>
                <Item.Title>{skill.skill?.name}</Item.Title>
              </Item.Content>
            </Item>
          ))}
          {!allSelectedSkills?.length && (
            <Placeholder>
              <Placeholder.Image
                size={104}
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images-v2/3D241.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D241@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D241@3x.png',
                }}
              />
              <Placeholder.Description>
                <FormattedMessage
                  id="skillsSidebar.description"
                  defaultMessage=" No skills assigned to this {type}. Add skills from Skills library or create new"
                  values={{
                    type:
                      type === 'role' || !specialisationsEnabled
                        ? 'role'
                        : 'specialisation',
                  }}
                />
              </Placeholder.Description>
            </Placeholder>
          )}
        </Group>
        <Subheader>All skills</Subheader>

        <Group>
          <Box p="s-16">
            <Search
              value={searchValue}
              placeholder="Search"
              onChange={newValue => setSearchValue(newValue)}
            />
          </Box>
          {filteredSkills.map(skill => (
            <Item
              key={skill.id}
              use="button"
              onClick={e => {
                e.preventDefault()
                onAddSkill(skill)
              }}
            >
              <Item.Prefix>
                <Icon
                  name="PlusCircle"
                  aria-label="Add skill"
                  color={Token.color.accent}
                />
              </Item.Prefix>
              <Item.Content>
                <Item.Title>{skill.name}</Item.Title>
              </Item.Content>
            </Item>
          ))}
        </Group>
        <Side.Actions>
          <Button
            onClick={e => {
              e.preventDefault()
              handleSave()
            }}
          >
            Save
          </Button>
        </Side.Actions>
      </SideBar>
      {isCreateNewSkillOpened && (
        <CreateNewSkillSideBar
          onClose={toggleCreateNewSkillOpened.off}
          onAfterSubmit={async () => {
            await refetchSkills()
            toggleCreateNewSkillOpened.off()
          }}
        />
      )}
    </>
  )
}
