import React from 'react'
import { Box, Ellipsis, Flex, Icon, Tooltip, useTooltip } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Table from '@components/TableV2/Table'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'
import { ratingToColor } from '@src/utils/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { getRatingExpectationIconName } from '@src/features/ReviewCycle/Analytics/Skills/constants'
import { RatingAssessors } from '@src/features/ReviewCycle/Analytics/Skills/components/RatingAssessors'

export const employeeColumn: ColumnInterface<TalentSkillRatingInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: null,
  filterKey: null,
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.employee.id }),
  insert: ({ data }) => {
    return <Table.EmployeeCell employee={data.employee} />
  },
  selectorsKey: selectorKeys.none,
  title: 'Employee',
}

export const teamColumn: ColumnInterface<TalentSkillRatingInterface> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  dynamicHyperlinks: ({ team }) =>
    team ? pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: team.id }) : null,
  title: 'Team',
}

export const getSpecialisationColumn = (
  isSpecialisationsEnabled: boolean,
): ColumnInterface<TalentSkillRatingInterface> => ({
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  dynamicHyperlinks: ({ specialisation }) =>
    specialisation
      ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id: specialisation.id })
      : null,
  title: getSpecialisationTitle(isSpecialisationsEnabled),
})

export const seniorityColumn: ColumnInterface<TalentSkillRatingInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) =>
    data.seniority ? <Table.SeniorityCell seniority={data.seniority.name} /> : '-',
}

export const ratingColumn: ColumnInterface<TalentSkillRatingInterface> = {
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Skill level',
  background: data => ratingToColor(data.rating) || 'inherit',
  insert: ({ data }) => <RatingColumn {...data} />,
}

const RatingColumn = (props: TalentSkillRatingInterface) => {
  const { rating, rating_expectation } = props

  const tooltip = useTooltip()

  if (!rating) {
    return <>-</>
  }

  return (
    <Flex
      alignItems="center"
      gap="s-4"
      py="s-16"
      width="100%"
      height="100%"
      {...tooltip.getAnchorProps()}
    >
      <Ellipsis>{PerformanceRatingTitle[rating]}</Ellipsis>
      {rating_expectation && (
        <Icon name={getRatingExpectationIconName(rating_expectation)} size={12} />
      )}
      <Tooltip {...tooltip.getTargetProps()}>
        <Box p="s-2">
          <RatingAssessors {...props} />
        </Box>
      </Tooltip>
    </Flex>
  )
}
