import React, { useState, useMemo } from 'react'
import {
  VStack,
  Text,
  TabBar,
  Token,
  TabBarSkeleton,
  Grid,
  Box,
  useMatchMedia,
  Button,
  chain,
} from '@revolut/ui-kit'
import { plansConfig } from './config'
import { ROUTES } from '@src/constants/routes'
import { useSubscriptionPlanContextV2 } from './SubscriptionPlanProvider'
import { useAvailableSubscriptionPlansV2, useSubsciptionInfo } from '@src/api/plans'
import { pathToUrl } from '@src/utils/router'
import { useScrollToTop } from '@src/hooks/useScrollToTop'
import { PlanWidget } from '@src/pages/Forms/Plans/PlanWidget'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { formatPercentage } from '@src/utils/format'
import { PlanType, DiscountOption } from '@src/pages/Forms/Plans/types'
import { PageActions } from '@components/Page/PageActions'
import { AvailableSubscriptionPlanInterfaceV2 } from '@src/interfaces/plans'
import { SelectPlanButton } from '@src/pages/Forms/Plans/SelectPlanButton'

const MAX_ANNUAL_DISCOUNT = 0.25

const COMPANY_SIZES: DiscountOption[] = [
  { id: 10, name: '10-50 employees', discount: 5 },
  { id: 50, name: '50-100 employees', discount: 10 },
  { id: 100, name: '100-250 employees', discount: 15 },
  { id: 250, name: '250-500 employees', discount: 20 },
  { id: 500, name: '500-1000 employees', discount: 25 },
  { id: 1000, name: '1000+ employees', discount: MAX_ANNUAL_DISCOUNT * 100 },
]

const COMPANY_SIZES_OPTIONS = COMPANY_SIZES.map(size => ({
  value: size,
  label: size.name,
}))

export const SelectPlanV2 = () => {
  useScrollToTop()

  const { plans } = useAvailableSubscriptionPlansV2()
  const { selectedPlan, setSelectedPlan } = useSubscriptionPlanContextV2()
  const [mobilePlan, setMobilePlan] = useState<AvailableSubscriptionPlanInterfaceV2>()
  const { info, isLoading: isInfoLoading } = useSubsciptionInfo()
  const isMd = useMatchMedia(Token.media.md)
  const [planType, setPlanType] = useState<PlanType>()
  const [companySize, setCompanySize] = useState<DiscountOption>(COMPANY_SIZES[0])
  const [hasAppliedForAnnualPlan, setHasAppliedForAnnualPlan] = useState(false)

  useScrollToTop()

  const availablePlans = useMemo(
    () =>
      plans?.filter(p =>
        plansConfig.find(planConfig => {
          return planConfig.id === p.slug
        }),
      ),
    [plans],
  )
  const isOnCustomPlan = !!info?.subscription_plan?.manual_payment
  const isOnMonthlyPlan = info?.subscription_plan?.manual_payment === null
  const subscribedPlan = info?.subscription_plan
  const subscribedPlanId = info?.subscription_plan?.id
  const isCurrentPlanSelected = selectedPlan && subscribedPlanId === selectedPlan.id
  const noPaymentMethod = info?.revolut_merchant_api_payment_method === null

  if (!selectedPlan && subscribedPlanId && availablePlans) {
    setSelectedPlan(availablePlans.find(p => p.id === subscribedPlanId))
  }

  if (info?.custom_plan_requested_at && !hasAppliedForAnnualPlan) {
    setHasAppliedForAnnualPlan(true)
  }

  if (!planType && (isOnCustomPlan || hasAppliedForAnnualPlan)) {
    setPlanType('annual')
  }

  if (
    !planType &&
    (isOnMonthlyPlan || isCurrentPlanSelected || (!isInfoLoading && !subscribedPlanId))
  ) {
    setPlanType('monthly')
  }

  if (!mobilePlan && availablePlans?.[0]) {
    setMobilePlan(availablePlans[0])
  }

  return (
    <PageWrapper fullWidth>
      <PageHeader
        title={subscribedPlan ? 'Manage your plan' : 'Find your perfect plan'}
        backUrl={pathToUrl(ROUTES.MAIN)}
      />
      <PageBody maxWidth={Token.breakpoint.xl}>
        <VStack gap="s-24">
          <TabBar
            display={{ all: 'flex', md: 'none' }}
            value={String(mobilePlan?.id)}
            onChange={value => {
              if (value) {
                setMobilePlan(availablePlans?.find(plan => plan.id === Number(value)))
              }
            }}
          >
            {availablePlans?.map(plan => (
              <TabBar.Item to={String(plan.id)} key={plan.id}>
                {plan.name}
              </TabBar.Item>
            ))}
          </TabBar>

          <VStack space="s-16" align="center">
            {planType ? (
              <TabBar
                value={planType}
                onChange={value => {
                  if (value) {
                    setPlanType(value)
                  }
                }}
                variant="segmented fit"
                mx="auto"
                display={{ all: 'none', md: 'flex' }}
              >
                <TabBar.Item to="monthly">Monthly</TabBar.Item>
                <TabBar.Item to="annual">
                  {chain('Annual', `Save ${formatPercentage(MAX_ANNUAL_DISCOUNT)}`)}
                </TabBar.Item>
              </TabBar>
            ) : (
              <TabBarSkeleton variant="segmented" width={300}>
                <TabBarSkeleton.Item aria-selected />
                <TabBarSkeleton.Item />
              </TabBarSkeleton>
            )}

            {planType === 'annual' ? (
              <Box
                width={{ all: '100%', md: undefined }}
                maxWidth={{ all: '100%', md: 460 }}
              >
                <RadioSelectInput
                  options={COMPANY_SIZES_OPTIONS}
                  label="How big is your company?"
                  onChange={val => {
                    if (val) {
                      setCompanySize(val)
                    }
                  }}
                  value={companySize}
                />
              </Box>
            ) : null}
          </VStack>

          <Grid columns={{ all: 1, md: 4 }} gap="s-24" data-testId={'plans-widget'}>
            {planType &&
              plansConfig.map(planConfig => {
                const plan = plans?.find(p => p.slug === planConfig.id)
                if (!plan || (!isMd && plan.id !== mobilePlan?.id)) {
                  return null
                }

                return (
                  <PlanWidget
                    plan={plan}
                    backgroundColor={
                      !isMd ? Token.color.widgetBackground : planConfig.bgColor
                    }
                    features={planConfig.features}
                    planDescription={planConfig.planDescription}
                    planType={planType}
                    key={plan.id}
                    discount={companySize}
                  >
                    <SelectPlanButton
                      plan={plan}
                      isSelectedPlan={info?.subscription_plan?.id === plan.id}
                      planType={planType}
                      noPaymentMethod={noPaymentMethod}
                    />
                  </PlanWidget>
                )
              })}
          </Grid>

          <Text
            color={Token.color.greyTone20}
            variant="emphasis2"
            textAlign="center"
            use="div"
            pb="s-32"
          >
            All prices exclude tax, which may be added as applicable.
          </Text>
        </VStack>
      </PageBody>

      <PageActions display={{ all: 'flex', md: 'none' }}>
        <VStack gap="s-16" pb="s-32">
          {mobilePlan && planType ? (
            <SelectPlanButton
              plan={mobilePlan}
              isSelectedPlan={info?.subscription_plan?.id === mobilePlan.id}
              planType={planType}
              noPaymentMethod={noPaymentMethod}
              variant="primary"
            />
          ) : null}

          <Button
            variant="secondary"
            onClick={() => setPlanType(planType === 'monthly' ? 'annual' : 'monthly')}
          >
            {planType === 'monthly' ? 'View annual plans' : 'View monthly plans'}
          </Button>
        </VStack>
      </PageActions>
    </PageWrapper>
  )
}
