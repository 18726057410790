import React, { useEffect, useRef, useState } from 'react'
import { Flex, Placeholder, TabBar, VStack, Widget } from '@revolut/ui-kit'
import { PerformanceSummaryHeader } from '@src/pages/Forms/DepartmentForm/Performance/PerformanceSummaryHeader'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { PerformanceCyclesNavigationCollapsable } from '@src/pages/Forms/DepartmentForm/Performance/PerformanceCyclesNavigationCollapsable'
import { PerformanceSummary } from '@src/pages/Forms/DepartmentForm/Performance/PerformanceSummary'
import { PerformanceAnalytics } from './PerformanceAnalytics'
import {
  Cycle,
  PerformanceHistoryContentType,
} from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { CycleOption } from '@src/interfaces/selectors'
import { DepartmentInterface } from '@src/interfaces/deparment'
import {
  useGetAggregatedStats,
  useGetDepartmentPerformanceStats,
} from '@src/api/performance'
import PageLoading from '@components/PageLoading/PageLoading'
import { useQuery } from '@src/utils/queryParamsHooks'
import { goalsListTableRequests } from '@src/api/goals'
import { roadmapsRequests } from '@src/api/roadmaps'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { getDepartmentKarma, getDepartmentKarmaStats } from '@src/api/department'
import { Statuses } from '@src/interfaces'
import { SkillsRatingsDistributionWidget } from '@src/features/ReviewCycle/Analytics/Skills/SkillsRatingsDistribution'
import { useCanViewDepartmentSkillsAnalytics } from '@src/features/ReviewCycle/Analytics/Skills/hooks/useSkillsAnalyticsAvailability'
import { useDepartmentReviewCycles } from '@src/pages/Forms/DepartmentForm/Performance/hooks/useReviewCycles'
import { useParams } from 'react-router-dom'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

enum Tabs {
  Summary = 'summary',
  Analytics = 'analytics',
  SkillsAnalytics = 'skills-analytics',
}

const defaultTab = Tabs.Summary

interface PerformanceTabContentProps {
  department: DepartmentInterface
  cyclesList: Cycle[]
  cycleOptions: CycleOption[]
  contentType: PerformanceHistoryContentType
  currentlySelectedCycleId?: number
}

const SELECTED_REVIEW_CYCLE_IDS_PARAM = 'cycle__id'

const PerformanceTabContent = ({
  department,
  contentType,
  cyclesList,
  cycleOptions,
  currentlySelectedCycleId,
}: PerformanceTabContentProps) => {
  const HEADER_OFFSET = 70

  const { subtab } = useParams<{ subtab: string }>()
  const { query, changeQueryParam } = useQuery(true)

  const [navigationTabValue, setNavigationTabValue] = useState<Tabs | null>(
    Object.values(Tabs).find(tab => tab === subtab) || defaultTab,
  )
  const [selectedCycle, setSelectedCycle] = useState<Cycle | undefined>()
  const [latestPerformanceReviewCycleId] = selectedCycle?.review_cycle_ids ?? []

  const isSkillsAnalyticsEnabled = useCanViewDepartmentSkillsAnalytics(department)

  const onSetSelectedCycle = (cycle: Cycle) => {
    changeQueryParam(SELECTED_REVIEW_CYCLE_IDS_PARAM, cycle.review_cycle_ids.toString())
    setSelectedCycle(cycle)
  }

  useEffect(() => {
    if (!(query[SELECTED_REVIEW_CYCLE_IDS_PARAM] && cyclesList.length)) {
      onSetSelectedCycle(cyclesList[1])
    }
  }, [])

  useEffect(() => {
    if (query[SELECTED_REVIEW_CYCLE_IDS_PARAM] && cyclesList.length) {
      const target = cyclesList?.find(
        cycle =>
          cycle.review_cycle_ids.toString() === query[SELECTED_REVIEW_CYCLE_IDS_PARAM] &&
          (selectedCycle?.type === undefined || selectedCycle.type === cycle.type),
      )
      setSelectedCycle(target)
    }
  }, [cyclesList, query[SELECTED_REVIEW_CYCLE_IDS_PARAM], selectedCycle])

  useEffect(() => {
    const validSubTab = Object.values(Tabs).find(tab => tab === subtab)

    if (!validSubTab || validSubTab === navigationTabValue) {
      return
    }

    setNavigationTabValue(validSubTab)
  }, [subtab])

  const goalsSettings = {
    initialFilters: [
      {
        columnName: 'object_id',
        filters: [{ id: department.id, name: department.name || '' }],
        nonResettable: true,
        nonInheritable: true,
      },
      {
        columnName: 'content_type_id',
        filters: [{ id: contentType.id, name: contentType.model }],
        nonResettable: true,
        nonInheritable: true,
      },
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
    ],
    initialSort: [{ sortBy: 'goal_cycle_weight', direction: SORT_DIRECTION.ASC }],
  }

  const roadmapsSettings = {
    initialFilters: [
      {
        columnName: 'department__id',
        filters: [{ id: department.id, name: department.name || '' }],
        nonResettable: true,
        nonInheritable: true,
      },
    ],
    initialSort: [
      {
        sortBy: 'team__name',
        direction: SORT_DIRECTION.DESC,
      },
      {
        sortBy: 'priority',
        direction: SORT_DIRECTION.DESC,
      },
      {
        sortBy: 'due_date',
        direction: SORT_DIRECTION.DESC,
      },
    ],
  }

  const karmaSettings = {
    initialFilters: [],

    initialSort: [
      {
        sortBy: 'issue_date_time',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  }

  const { data: cycleStats } = useGetDepartmentPerformanceStats(
    contentType.id,
    selectedCycle?.review_cycle_ids,
    department.id,
  )

  const { data: summaryStats } = useGetAggregatedStats({
    contentTypeId: contentType.id,
    departmentId: department.id,
    navigationType: selectedCycle?.type,
    reviewCycleIds: selectedCycle?.review_cycle_ids,
  })

  const scrollGoalsRef = useRef<HTMLDivElement>(null)
  const scrollRoadmapRef = useRef<HTMLDivElement>(null)
  const scrollKarmaRef = useRef<HTMLDivElement>(null)

  const handleStatClick = (statName: 'goal' | 'roadmap' | 'karma') => {
    setTimeout(() => {
      const refMapping: Record<string, React.RefObject<HTMLDivElement>> = {
        goal: scrollGoalsRef,
        roadmap: scrollRoadmapRef,
        karma: scrollKarmaRef,
      }

      const element = refMapping[statName]?.current

      if (element) {
        let elementPosition = element.getBoundingClientRect().top
        let offsetPosition = elementPosition - HEADER_OFFSET

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }, 300)
  }

  const onFilterSkillsRatingsDistributionCycle = (cycleId: number) => {
    changeQueryParam(SELECTED_REVIEW_CYCLE_IDS_PARAM, String(cycleId))
  }

  const onSwitchNavigationTab = (tab: Tabs | null) => {
    if (!tab) {
      return
    }

    let cycleIdParam = String(latestPerformanceReviewCycleId)

    // specific requirement for skills analytics - navigating to "Skills" sub-tab
    // from a previous review cycle (defined by offset) should keep existing cycle__id
    // query param as skills distribution already evaluated for those,
    // when navigating from current or future cycle we select the one with
    // performance_reviews_selected_cycle field set to "true" (has review phase on)
    if (tab === Tabs.SkillsAnalytics) {
      const [latestPerformanceReviewCycleOffset] =
        selectedCycle?.review_cycle_offsets ?? []

      if (
        latestPerformanceReviewCycleOffset <= 0 &&
        currentlySelectedCycleId !== undefined &&
        currentlySelectedCycleId !== latestPerformanceReviewCycleId
      ) {
        cycleIdParam = String(currentlySelectedCycleId)
      }
    }

    const params = {
      id: department.id,
      subtab: tab,
    }
    const queryParams = {
      [SELECTED_REVIEW_CYCLE_IDS_PARAM]: cycleIdParam,
    }

    navigateTo(pathToUrl(ROUTES.FORMS.DEPARTMENT.PERFORMANCE.ANY, params, queryParams))
  }

  return (
    <VStack space="s-16" width="100%">
      <Widget>
        <VStack width="100%">
          <Flex flexDirection="row">
            <PerformanceSummaryHeader
              summaryStats={summaryStats}
              cycleType={selectedCycle?.type}
              selectedCycle={selectedCycle}
              handleStatClick={handleStatClick}
            />
          </Flex>
        </VStack>
      </Widget>
      <Flex alignItems="center" justifyContent="space-between">
        <TabBar
          minWidth={260}
          onChange={onSwitchNavigationTab}
          value={navigationTabValue}
        >
          <TabBar.Item to={Tabs.Summary}>Review cycles</TabBar.Item>
          <TabBar.Item to={Tabs.Analytics}>Analytics</TabBar.Item>
          {isSkillsAnalyticsEnabled && (
            <TabBar.Item to={Tabs.SkillsAnalytics}>Skills</TabBar.Item>
          )}
        </TabBar>
      </Flex>
      {navigationTabValue === Tabs.Summary && (
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={{ md: 270, all: '100%' }}
          left={
            <PerformanceCyclesNavigationCollapsable
              cyclesList={cyclesList}
              setSelectedCycle={onSetSelectedCycle}
              selectedCycle={selectedCycle}
            />
          }
          right={
            <PerformanceSummary
              cycleStats={cycleStats}
              selectedCycle={selectedCycle}
              goalTableRequests={goalsListTableRequests}
              roadmapsTableRequests={roadmapsRequests}
              karmaTableRequests={{
                getItems: getDepartmentKarma(department.id),
                getStats: getDepartmentKarmaStats(department.id),
              }}
              goalsSettings={goalsSettings}
              roadmapsSettings={roadmapsSettings}
              karmaSettings={karmaSettings}
              summaryStats={summaryStats}
              scrollGoalsRef={scrollGoalsRef}
              scrollRoadmapRef={scrollRoadmapRef}
              scrollKarmaRef={scrollKarmaRef}
            />
          }
        />
      )}
      {navigationTabValue === Tabs.Analytics && (
        <PerformanceAnalytics
          cyclesList={cyclesList}
          setSelectedCycle={onSetSelectedCycle}
          entityId={department.id}
        />
      )}
      {navigationTabValue === Tabs.SkillsAnalytics && isSkillsAnalyticsEnabled && (
        <SkillsRatingsDistributionWidget
          cycleId={latestPerformanceReviewCycleId}
          departmentId={department.id}
          cycleFilterKey={SELECTED_REVIEW_CYCLE_IDS_PARAM}
          cycleOptions={cycleOptions}
          onFilterCycle={onFilterSkillsRatingsDistributionCycle}
        />
      )}
    </VStack>
  )
}

export const PerformanceTab = ({ data }: { data: DepartmentInterface }) => {
  const { cycles, options, currentlySelectedCycleId, contentType, isLoading } =
    useDepartmentReviewCycles(data.id)

  if (isLoading) {
    return <PageLoading />
  }

  if (!contentType) {
    return null
  }

  if (cycles.length === 0) {
    return (
      <Widget>
        <Placeholder>
          <Placeholder.Image
            image={{
              default: `https://assets.revolut.com/assets/3d-images-v2/3D299.png`,
              '2x': `https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png`,
              '3x': `https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png`,
            }}
          />
          <Placeholder.Title>Department Performance will appear here</Placeholder.Title>
        </Placeholder>
      </Widget>
    )
  }

  return (
    <PerformanceTabContent
      department={data}
      contentType={contentType}
      cyclesList={cycles}
      cycleOptions={options}
      currentlySelectedCycleId={currentlySelectedCycleId}
    />
  )
}
