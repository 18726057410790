import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { DepartmentResourcesTotal } from '@src/pages/Forms/DepartmentForm/Resources/Tabs/Total'
import { DepartmentResourcesAnalytics } from '@src/pages/Forms/DepartmentForm/Resources/Tabs/Analytics'
import { DepartmentInterface } from '@src/interfaces/deparment'

interface DepartmentResourcesProps {
  data: DepartmentInterface
}

export const DepartmentResources = ({ data }: DepartmentResourcesProps) => {
  const params = useParams()

  const tabs = [
    {
      title: 'Total',
      path: ROUTES.FORMS.DEPARTMENT.RESOURCES.TOTAL,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RESOURCES.TOTAL, params),
      component: <DepartmentResourcesTotal />,
    },
    {
      title: 'Analytics',
      path: ROUTES.FORMS.DEPARTMENT.RESOURCES.ANALYTICS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RESOURCES.ANALYTICS, params),
      component: <DepartmentResourcesAnalytics data={data} />,
    },
  ]

  return (
    <Switch>
      {tabs.map(tab => (
        <Route path={tab.path} exact key={tab.title}>
          {tab.component}
        </Route>
      ))}
    </Switch>
  )
}
