import {
  chain,
  Flex,
  HStack,
  Icon,
  IconName,
  Tag,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import React from 'react'
import { EngagementInsightsTarget } from '@src/interfaces/engagement'
import { InsightWithScore } from './hooks/useGetInsightsWithScore'
import { normalizedScoreToColors } from '@src/features/Engagement/helpers/statValuesToColor'

interface InsightsItemProps {
  insight: InsightWithScore
  onClick: (insight: InsightWithScore) => void
  titleIconName?: IconName
  insightType?: EngagementInsightsTarget
}

export const InsightsItem = ({
  insight,
  onClick,
  titleIconName,
  insightType,
}: InsightsItemProps) => {
  return (
    <VStack width="100%" onClick={() => onClick(insight)} style={{ cursor: 'pointer' }}>
      <Flex
        gap={{ all: 's-6', xl: 's-16' }}
        flexDirection={{ all: 'column', xl: 'row' }}
        justifyContent="space-between"
      >
        <HStack gap="s-6" align="center">
          <Text variant="body2">{insight.title}</Text>
          {titleIconName && (
            <Icon name={titleIconName} color={Token.color.greyTone50} size={14} />
          )}
        </HStack>
        <HStack color={Token.color.greyTone50} style={{ flexShrink: 0 }}>
          {insightType !== 'driver' &&
            chain(
              <Tag useIcon="People" color={Token.color.grey50_40}>
                {insight.driver.name}
              </Tag>,
              insight.score !== undefined && Number.isFinite(insight.score) ? (
                <Tag
                  variant="outlined"
                  color={normalizedScoreToColors(insight.score)?.color}
                >
                  {insight.score.toFixed(1)}
                </Tag>
              ) : null,
            )}
        </HStack>
      </Flex>
      <Text variant="body2" color={Token.color.greyTone50} mt="s-6">
        {insight.subtitle}
      </Text>
    </VStack>
  )
}
