import { useParams } from 'react-router-dom'
import { getDepartmentResourcesAnalyticsInitialFilter } from './components/StatsNavigation'
import { useFetchV2 } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { GetRequestInterface } from '@src/interfaces'

// TODO: https://revolut.atlassian.net/browse/REVCOR-4013 implement with proper API when BE is done
export const useGetResourcesStats = () => {
  const params = useParams<{ id?: string }>()

  const result = useFetchV2<GetRequestInterface<{}>>({
    url: `${API.DASHBOARDS}/ordering`,
    params: {
      params: filterSortPageIntoQuery(
        undefined,
        getDepartmentResourcesAnalyticsInitialFilter(Number(params.id)),
      ),
    },
  })

  return {
    ...result,
    data: result.data
      ? {
          total_count: result.data.count,
          analytics_count: result.data.count,
        }
      : undefined,
  }
}
