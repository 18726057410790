import React, { useMemo } from 'react'
import { FilterOption, RowInterface } from '@src/interfaces/data'
import { CycleOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { SpecialisationFilterTitle } from '@src/features/Roles/helpers'
import {
  SkillRatingsDistributionFilterKeys,
  SkillRatingsDistributionGroupTypes,
} from '@src/features/ReviewCycle/Analytics/api/skills'
import { ChartTypeSelector } from '@src/features/ReviewCycle/Analytics/Skills/ChartTypeSelector'
import {
  RowDataInterface,
  useTableData,
} from '@src/features/ReviewCycle/Analytics/Skills/Tables/TalentSkillRating/hooks/useTableData'
import { RowSeparator } from '@src/features/ReviewCycle/Analytics/Skills/Tables/TalentSkillRating/RowSeparator'
import { useTableFilters } from '@src/features/ReviewCycle/Analytics/Skills/Tables/TalentSkillRating/hooks/useTableFilters'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import Table from '@components/TableV2/Table'
import { CycleFilterButton } from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import {
  employeeColumn,
  getSpecialisationColumn,
  ratingColumn,
  seniorityColumn,
  teamColumn,
} from '@src/features/ReviewCycle/Analytics/Skills/Tables/TalentSkillRating/columns'

interface Props {
  departmentId: number
  skillId: number
  cycleOptions: CycleOption[]
  groupType: SkillRatingsDistributionGroupTypes
}

const getRow = (
  isSpecialisationsEnabled: boolean,
): RowInterface<TalentSkillRatingInterface> => ({
  cells: [
    { ...employeeColumn, width: 200 },
    { ...teamColumn, width: 100 },
    { ...getSpecialisationColumn(isSpecialisationsEnabled), width: 100 },
    { ...seniorityColumn, width: 100 },
    { ...ratingColumn, width: 75 },
  ],
})

export const TalentSkillRatingTable = ({
  departmentId,
  skillId,
  groupType,
  cycleOptions,
}: Props) => {
  const isSpecialisationsEnabled = useIsSpecialisationsEnabled()

  const table = useTableData({
    departmentId,
    skillId,
    groupType,
  })
  const row = useMemo(() => getRow(isSpecialisationsEnabled), [isSpecialisationsEnabled])
  const {
    teams: { options: teamsOptions },
    specialisations: { options: specialisationsOptions },
    group: { options: groupOptions },
  } = useTableFilters(table)

  const renderSeparatorContent = ({ separatorProps }: RowDataInterface) => {
    if (!separatorProps) {
      return null
    }

    return <RowSeparator {...separatorProps} />
  }

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <Table.Search
          variant="compact"
          placeholder="Search by employee"
          columnName={SkillRatingsDistributionFilterKeys.SearchEmployee}
          onFilter={searchQuery => table.onFilterChange(searchQuery, false)}
        />
        <CycleFilterButton
          columnName={SkillRatingsDistributionFilterKeys.CycleId}
          filter={table.filterBy}
          selector={() => Promise.resolve({ options: cycleOptions })}
          onFilterChange={table.onFilterChange}
        />
        <ButtonFilter<FilterOption>
          value={teamsOptions}
          type={FilterSelectType.MultiSelect}
          selector={selectorKeys.team}
          title={teamsOptions.length ? 'Selected teams' : 'All teams'}
          useIcon="Profile"
          variant="inactive"
          onChange={filters =>
            table.onFilterChange({
              columnName: SkillRatingsDistributionFilterKeys.TeamId,
              filters,
            })
          }
        />
        <ButtonFilter<FilterOption>
          value={specialisationsOptions}
          type={FilterSelectType.MultiSelect}
          selector={selectorKeys.specialisations}
          title={<SpecialisationFilterTitle count={specialisationsOptions.length} />}
          useIcon="RepairTool"
          variant="inactive"
          onChange={filters =>
            table.onFilterChange({
              columnName: SkillRatingsDistributionFilterKeys.SpecialisationId,
              filters,
            })
          }
        />
        <ChartTypeSelector
          value={groupOptions[0]}
          onSelect={option => {
            table.onFilterChange({
              columnName: SkillRatingsDistributionFilterKeys.GroupType,
              filters: [option],
            })
          }}
        />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<RowDataInterface>
          name={TableNames.TalentSkillRating}
          row={row}
          {...table}
          emptyState={<EmptyTableRaw title="No rating distribution data found" />}
          renderSeparatorContent={renderSeparatorContent}
          useWindowScroll
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
