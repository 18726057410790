import React from 'react'
import { useSelector } from 'react-redux'
import { VStack, Widget } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { selectPermissions } from '@src/store/auth/selectors'
import { Grid } from '@src/components/CommonSC/Grid'
import { InternalLink } from '@components/InternalLink/InternalLink'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import { getSettingsLinkConfig } from '@src/pages/Settings/common/config'
import { useIsPerformanceAiInsightsEnabled } from '@src/features/AiInsights/Performance/hooks/useAiInsightsAvailability'
import { performanceSettingsLinkConfig } from './config'

export const SettingsLanding = () => {
  const permissions = useSelector(selectPermissions)
  const canViewAiPerformanceInsights = useIsPerformanceAiInsightsEnabled()

  const config = getSettingsLinkConfig({
    config: performanceSettingsLinkConfig,
    permissions,
    type: 'settings',
  })

  return (
    <Widget>
      <Grid flow="row" gap={24}>
        <VStack width="100%">
          {config.performanceGeneral?.component}
          {config.performanceCycleDuration?.component}
          {config.performanceReviewTypes?.component}
          {config.performancePIP?.component}
          {config.performanceProbation?.component}
          {canViewAiPerformanceInsights && (
            <NewSelectionCard
              icon="Gift"
              title="Insights"
              to={ROUTES.PERFORMANCE.INSIGHTS.INSIGHTS_GROUPS}
              use={InternalLink}
            />
          )}
        </VStack>
      </Grid>
    </Widget>
  )
}
