import React from 'react'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Flex, Group, Subheader, ActionButton, Token } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { Statuses } from '@src/interfaces'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
} from '@src/constants/columns/hiringProcess'
import { useParams } from 'react-router-dom'
import PreviewStageSidebar from '@src/pages/Forms/RoleForm/Preview/PreviewStageSidebar/index'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import SpecialisationSubmitButton from '@src/pages/Forms/SpecialisationForm/Buttons/SpecialisationSubmitButton'
import { navigateTo } from '@src/actions/RouterActions'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { parseLegacyApprovalSteps } from '@src/utils/approvalFlow'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import PostingsViewer from '@src/pages/Forms/SpecialisationForm/Postings/PostingsViewer'
import { useGetOrganisationSettings, useGlobalSettings } from '@src/api/settings'
import { useGetSpecialisationPreferredHiringLocations } from '@src/api/specialisations'
import { PermissionTypes } from '@src/store/auth/types'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { PageActions } from '@src/components/Page/PageActions'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'

export const HiringProcessRow: RowInterface<HiringProcessInterface> = {
  cells: [
    {
      ...hiringProcessTitleWithLockColumn,
      width: 188,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 144,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 100,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 134,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 154,
    },
  ],
}

const Preview = () => {
  const context = useLapeContext<SpecialisationInterface>()
  const { values } = context
  const { id } = useParams<{ id: string }>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const {
    settings: { job_postings_enabled, promotions_enabled },
  } = useGlobalSettings()

  const { data: settings } = useGetOrganisationSettings()
  const { data: preferredLocations } = useGetSpecialisationPreferredHiringLocations(
    values.id,
  )
  const approvalsEnabled = !!settings?.enable_specialisations_approvals

  const permissions = context.values.field_options.permissions || []
  const canEdit = permissions.includes(PermissionTypes.ChangeSpecialisation)

  if (context.loading) {
    return (
      <Flex>
        <Loader />
      </Flex>
    )
  }

  const renderEditLink = (url: string, label = 'Edit') => {
    return canEdit ? (
      <ActionButton
        onClick={() =>
          navigateTo(
            pathToUrl(url, {
              id: values.id,
            }),
          )
        }
      >
        {label}
      </ActionButton>
    ) : null
  }

  return (
    <>
      <PreviewStageSidebar isSpecialisation />
      <PageBody maxWidthMd={Token.breakpoint.md}>
        {approvalsEnabled && values.status !== Statuses.draft && (
          <ApprovalFlow
            isLoading={!values.approval_flow_status}
            steps={parseLegacyApprovalSteps(values.approval_flow_status?.approval_steps)}
          />
        )}
        <FormPreview<SpecialisationInterface>
          data={values}
          title={specialisationsEnabled ? 'About the specialisation' : 'About the role'}
          onEdit={
            canEdit
              ? () =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.SPECIALISATIONS.EDIT.GENERAL_INFO, {
                      id,
                    }),
                  )
              : undefined
          }
        >
          <Group>
            <FormPreview.Item title="Name" field="name" />
            <FormPreview.Item title="Owner" type="employee" field="owner" />
            {!specialisationsEnabled && (
              <FormPreview.Item
                title="Parent Function"
                field="function.name"
                to={() =>
                  pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, {
                    id: values.function?.id,
                  })
                }
              />
            )}
            {specialisationsEnabled && (
              <FormPreview.Item
                title="Parent Role"
                field="role.name"
                to={() =>
                  pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, {
                    id: values.role?.id,
                  })
                }
              />
            )}
            <HideIfCommercial>
              {values.hiring_playbook_url && (
                <FormPreview.Item
                  title="Playbook"
                  field="hiring_playbook_url"
                  type="link"
                />
              )}
            </HideIfCommercial>
            <FormPreview.Item<SpecialisationInterface>
              title="Locations"
              field="locations"
              insert={data =>
                data?.locations?.length
                  ? data.locations?.map(loc => loc.name).join(', ')
                  : 'All'
              }
            />
            <FormPreview.Item<SpecialisationInterface>
              title="Preferred locations"
              insert={() => preferredLocations?.map(loc => loc.name).join(', ') || '-'}
            />
            <FormPreview.Details title="Mission" field="mission" />
            <FormPreview.Details
              title="Goals"
              field="goals"
              insert={() => (
                <ul style={{ paddingInlineStart: 16 }}>
                  {values.goals?.map((goal, index) => {
                    return <li key={index}>{goal.text}</li>
                  })}
                </ul>
              )}
            />
            {promotions_enabled && (
              <FormPreview.Item<SpecialisationInterface>
                title={specialisationsEnabled ? 'Linked specialisations' : 'Linked roles'}
                field="related_specialisations"
                insert={data =>
                  data?.related_specialisations?.length
                    ? data.related_specialisations.map(spec => spec.name).join(', ')
                    : '-'
                }
              />
            )}
          </Group>
        </FormPreview>
        {job_postings_enabled && (
          <>
            <Subheader variant="nested">
              <Subheader.Title>Job posting</Subheader.Title>
              {renderEditLink(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, 'View details')}
            </Subheader>
            <PostingsViewer noButtons noEditing noTable />
          </>
        )}
      </PageBody>
      <PageActions>
        {(!values?.id || values.status === Statuses.draft) && (
          <>
            <RoleSaveDraftButton
              title="specialisation"
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL)}
              pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.PREVIEW}
              isNew
              notification={{
                updateMsg: 'Specialisation draft successfully updated.',
                createMsg: 'Specialisation draft successfully created.',
              }}
            />
            <SpecialisationSubmitButton
              isNew
              notification={{
                updateMsg: 'Specialisation successfully updated.',
                createMsg: 'Specialisation successfully created.',
              }}
              dialog={{
                title: 'Why do we need this specialisation?',
                placeholder: 'a couple of words about the reason',
                fieldName: 'description',
              }}
            />
          </>
        )}
      </PageActions>
    </>
  )
}

export default connect(Preview)
