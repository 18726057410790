import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Placeholder, VStack } from '@revolut/ui-kit'

import { useGetEngagementSurvey } from '@src/api/engagement'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { IdAndName } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { FeatureFlags } from '@src/store/auth/types'

import TableLoader from '@src/components/TableV2/TableLoader'
import { useSubtabs } from '../../hooks/useSubtabs'
import { SurveySubheader } from '../Subheader'
import {
  useSurveyRoundSelector,
  UseSurveyRoundSelectorReturnType,
} from '../Rounds/Selector/hooks/useSurveyRoundSelector'
import { TimelineFilterInterface } from '../Rounds/Selector/types'
import { ResultsWidget } from './ResultsWidget'
import {
  useSurveySelector,
  UseSurveySelectorReturnType,
} from './ResultsWidget/SurverySelector'
import { useTimelineFilter } from './ResultsWidget/TimelineFilter/useTimelineFilter'
import { useHeatmapFilters } from './ResultsWidget/hooks/useHeatmapFilters'
import { InsightsWidget } from '@src/pages/Performance/Engagement/components/SurveyResults/InsightsWidget'
import { groupFiltersIntoURLQuery } from '@src/utils/table'

interface Props {
  entityId: number | undefined
  isCompany?: boolean
  scopeFilters: FilterByInterface[] | undefined
  invalidParamsWarning?: string
  subheaderRoundData?: UseSurveyRoundSelectorReturnType
  timelineFilter?: TimelineFilterInterface
  surveySelector?: UseSurveySelectorReturnType
}

const SurveyResultsPublishedInner = ({
  entityId,
  scopeFilters,
  invalidParamsWarning,
  isCompany,
  subheaderRoundData,
  surveySelector,
  timelineFilter: timelineFilterFromProps,
}: Props) => {
  const isEngagementDev = useHasFeatureFlag(FeatureFlags.DevEngagementV2)
  const { data: surveyOptions, isLoading: isLoadingSurveyOptions } =
    useGetSelectors<IdAndName>(selectorKeys.engagement_survey_titles_sorted)

  const timelineFilter = (subheaderRoundData ||
    timelineFilterFromProps) as TimelineFilterInterface
  const surveyId = surveySelector?.value?.id || subheaderRoundData?.survey.value?.id
  const heatmapFilters = useHeatmapFilters(surveyId, scopeFilters)
  const { data: survey, refetch: refetchSurvey } = useGetEngagementSurvey(surveyId)
  const subtabs = useSubtabs(entityId, true)
  const isNewLayout = isEngagementDev && !isCompany

  if (isLoadingSurveyOptions) {
    return <TableLoader rowHeight="large" />
  }
  if (!isLoadingSurveyOptions && !surveyOptions?.length) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
        />
        <Placeholder.Title>Could not find latest survey</Placeholder.Title>
      </Placeholder>
    )
  }
  if (invalidParamsWarning) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
        />
        <Placeholder.Title>Could not get surveys data</Placeholder.Title>
        <Placeholder.Description>{invalidParamsWarning}</Placeholder.Description>
      </Placeholder>
    )
  }
  return (
    <VStack space="s-24">
      {subheaderRoundData && (
        <>
          <SurveySubheader
            isNestedRoundSelector
            roundSelectorData={subheaderRoundData}
            scopeFilters={scopeFilters}
          />
          {surveyId && subheaderRoundData.round.value?.id && (
            <InsightsWidget
              surveyId={surveyId}
              insightType="survey"
              surveyRoundId={subheaderRoundData.round.value?.id}
              datesRange={{
                dateFrom: timelineFilter.dateFrom,
                dateTo: timelineFilter.dateTo,
              }}
              scopeParams={groupFiltersIntoURLQuery(scopeFilters)}
            />
          )}
          <TabBarNavigation tabs={subtabs} />
        </>
      )}
      {isNewLayout && survey ? (
        <Switch>
          {subtabs.map(tab => (
            <Route path={tab.path} key={tab.title}>
              <tab.component
                survey={survey}
                refetchSurvey={refetchSurvey}
                timelineFilter={timelineFilter}
                heatmapFilters={heatmapFilters}
                scopeFilters={scopeFilters}
              />
            </Route>
          ))}
        </Switch>
      ) : survey ? (
        <ResultsWidget
          survey={survey}
          itemsToAnalyse={isEngagementDev ? 'categories' : undefined}
          refetchSurvey={refetchSurvey}
          timelineFilter={timelineFilter}
          heatmapFilters={heatmapFilters}
          scopeFilters={scopeFilters}
          surveySelector={surveySelector}
          isCompany={isCompany}
        />
      ) : (
        <TableLoader rowHeight="large" />
      )}
    </VStack>
  )
}

const SurveyResultsPublishedWithoutSubheader = (props: Props) => {
  const surveySelector = useSurveySelector()
  const timelineFilter = useTimelineFilter(surveySelector.value?.id)

  return (
    <SurveyResultsPublishedInner
      {...props}
      surveySelector={surveySelector}
      timelineFilter={timelineFilter}
    />
  )
}

const SurveyResultsPublishedWithSubheader = (props: Props) => {
  const subheaderRoundData = useSurveyRoundSelector()
  return (
    <SurveyResultsPublishedInner {...props} subheaderRoundData={subheaderRoundData} />
  )
}

export const SurveyResultsPublished = (props: Props) => {
  const isEngagementDev = useHasFeatureFlag(FeatureFlags.DevEngagementV2)

  if (isEngagementDev && !props.isCompany) {
    return <SurveyResultsPublishedWithSubheader {...props} />
  }
  return <SurveyResultsPublishedWithoutSubheader {...props} />
}
