import React from 'react'
import { Box } from '@revolut/ui-kit'
import { RatingHistoryItemData } from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/ChartData'
import { RatingAssessors } from '@src/features/ReviewCycle/Analytics/Skills/components/RatingAssessors'
import { LIGHT_BACKGROUND_TOOLTIP_COLOR } from '@src/pages/Forms/QueryForm/components/Charts/components/LightBackgroundTooltip'

export const Tooltip = (props: RatingHistoryItemData) => (
  <Box color={LIGHT_BACKGROUND_TOOLTIP_COLOR}>
    <RatingAssessors {...props.rawData} />
  </Box>
)
