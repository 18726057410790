import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { InputGroup, Item, Button, MoreBar } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { OrganisationStructureLevel, OrganisationStructureTab } from '../types'
import { API, selectorKeys } from '@src/constants/api'
import { UploadAvatarPreview } from '@src/components/AvatarUploadPopup/components/UploadAvatarPreview'
import { EntityAvatarUpload } from '@src/features/EntityAvatarUpload/EntityAvatarUpload'
import {
  deleteStructureLevel,
  useOrganisationStructure,
  useUpdateStructureLevel,
} from '../api'
import { StructureTabsSidebar } from '@src/pages/Settings/Structures/Structure/components/TabsSidebar'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { getFieldSetter } from '@src/utils/setDefaultField'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { goBack } from '@src/actions/RouterActions'
import { DeleteButton } from '@src/features/SettingsButtons'
import { PermissionTypes } from '@src/store/auth/types'
import { TabsItem } from '../Structure/components/TabsItem'
import { EditStructureTabPopup } from '../Structure/components/EditStructureTabPopup'

export const StructureLevelGeneral = () => {
  const { id: structureId } = useParams<{ id?: string; levelId?: string }>()
  const { values, reset } = useLapeContext<OrganisationStructureLevel>()

  const {
    data: orgStructureData,
    isLoading: isOrgStructureLoading,
    error: orgStructureError,
    refetch: refetchOrgStructure,
  } = useOrganisationStructure(Number(structureId))

  const errorPopup = useErrorPopup()

  const [openedSidebar, setOpenedSidebar] = useState<'tabs' | 'fields' | null>(null)
  const [avatarUploadOpen, setAvatarUploadOpen] = useState(false)
  const [editTab, setEditTab] = useState<number | null>(null)

  const setDefaultIfEmpty = getFieldSetter<OrganisationStructureLevel>({ values })

  const levelOptions = useMemo(
    () =>
      orgStructureData?.levels
        ?.filter(level => level.id !== values.id)
        .map(level => ({
          label: level.name,
          value: { id: level.id, name: level.name },
        })) || [],
    [orgStructureData?.levels, values.id],
  )

  const backUrl = pathToUrl(ROUTES.SETTINGS.STRUCTURE.PREVIEW, { id: structureId })

  const pageTitle = (() => {
    if (values.id) {
      return orgStructureData?.name
        ? `Edit ${orgStructureData.name} level`
        : 'Edit structure level'
    }
    return orgStructureData?.name
      ? `New ${orgStructureData.name} level`
      : 'New structure level'
  })()

  useEffect(() => {
    if (!values.id && orgStructureData) {
      if (orgStructureData.icon) {
        setDefaultIfEmpty('icon', orgStructureData.icon)
      }
      if (orgStructureData.avatar) {
        setDefaultIfEmpty('avatar', orgStructureData.avatar)
      }
      setDefaultIfEmpty('organisation_structure', { id: orgStructureData.id })
      setDefaultIfEmpty('default_layout', orgStructureData.default_layout)
    }
  }, [orgStructureData, values.id])

  useEffect(() => {
    if (orgStructureError) {
      errorPopup.show({
        fallbackTitle: 'Failed to fetch organisation structure details',
        forceFallbackTitle: true,
        error: orgStructureError,
        popupProps: {
          preventUserClose: true,
        },
        actions: (
          <>
            <Button
              onClick={() => {
                errorPopup.hide()
                refetchOrgStructure()
              }}
            >
              Try again
            </Button>
            <Button
              onClick={() => {
                errorPopup.hide()
                goBack(backUrl)
              }}
              variant="secondary"
            >
              Go back
            </Button>
          </>
        ),
      })
    }
  }, [orgStructureError])

  const onChangeSidebarTabs = (tabs: OrganisationStructureTab[]) => {
    values.default_layout = tabs
  }

  return (
    <>
      <PageHeader
        title={pageTitle}
        subtitle="Manage details and choose default fields and pages for levels of this company structure."
        backUrl={backUrl}
        isLoading={isOrgStructureLoading || !!orgStructureError}
      />

      <PageBody>
        {values.id ? (
          <MoreBar>
            <DeleteButton
              title="structure"
              deleteApi={() => deleteStructureLevel(values.id)}
              backUrl={ROUTES.SETTINGS.STRUCTURES.ALL}
              globalPermissions={[PermissionTypes.ChangeOrganisationPreferences]}
              dialogProps={{
                label: 'Delete structure level',
                body: 'Please confirm that you want to delete this structure level. This operation is final and cannot be undone.',
                noMessage: 'Cancel',
                yesBtnVariant: 'negative',
                yesMessage: 'Delete',
              }}
              useGenericErrorHandler
            />
          </MoreBar>
        ) : null}

        <NewStepperTitle title="General details" noAutoStep />
        <InputGroup>
          <LapeNewInput
            name="name"
            label="Name"
            required
            description="Choose the name of the level to use in navigation, settings and employee details"
          />
          <LapeNewTextArea
            name="description"
            label="Description"
            required
            description="Provide a description to help your employees understand the level purpose"
          />
          <LapeRadioSelectInput
            name="parent_level"
            label="Parent level"
            disabled={!levelOptions?.length}
            options={levelOptions}
            optional
          />
          <LapeRadioSelectInput
            name="employee_assignment"
            label="Employee assignment"
            message="Choose to assign employees directly or automatically inherit them from lower levels"
            selector={selectorKeys.org_structure_employee_assignments}
          />
        </InputGroup>

        <NewStepperTitle
          title="Default unit settings"
          subtitle="Choose default fields and pages for units of this level. These settings could be overridden for each unit."
          noAutoStep
        />
        <InputGroup>
          {values.id ? (
            <Item
              onClick={() => setAvatarUploadOpen(true)}
              use="button"
              type="button"
              variant="disclosure"
            >
              <Item.Avatar>
                <UploadAvatarPreview useIcon={values.icon} avatar={values.avatar} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Default icon</Item.Title>
                <Item.Description>
                  Set a default icon to visually represent this structure across the
                  platform
                </Item.Description>
              </Item.Content>
            </Item>
          ) : null}

          <TabsItem
            title="Default tabs"
            description="Configure which tabs will be available for levels of this structure"
            selectedTabs={values.default_layout?.filter(
              tab => tab.state?.id === 'enabled',
            )}
            onOpen={() => setOpenedSidebar('tabs')}
          />
        </InputGroup>
      </PageBody>

      {openedSidebar ? null : (
        <PageActions>
          <NewSaveButtonWithPopup
            successText={values.id ? 'Level updated' : 'The level has been created!'}
            useValidator
            previewUrl={res =>
              pathToUrl(ROUTES.SETTINGS.STRUCTURE.LEVELS, {
                levelId: res.id,
                id: structureId,
              })
            }
          >
            {values.id ? 'Update' : 'Create'}
          </NewSaveButtonWithPopup>
        </PageActions>
      )}

      <EntityAvatarUpload
        open={avatarUploadOpen}
        onClose={() => setAvatarUploadOpen(false)}
        onSuccess={response =>
          reset({ ...values, icon: response?.icon, avatar: response?.avatar })
        }
        api={useUpdateStructureLevel}
        apiUrl={API.ORGANISATION_STRUCTURES_LEVELS}
        id={values.id}
        icon={values.icon}
      />

      <StructureTabsSidebar
        isOpen={openedSidebar === 'tabs'}
        tabs={values.default_layout}
        onChange={onChangeSidebarTabs}
        onClose={() => setOpenedSidebar(null)}
        onTabClick={id => setEditTab(id)}
        title="Manage page tabs"
        subtitle="Define the default layout of pages of this structure"
      />

      <EditStructureTabPopup
        open={!!editTab}
        onClose={() => setEditTab(null)}
        initialValues={values.default_layout?.find(f => f.id === editTab)}
        onSave={tabState => {
          values.default_layout = values.default_layout.map(tab =>
            tab.id === tabState.id ? tabState : tab,
          )
          setEditTab(null)
        }}
        key={editTab}
      />
    </>
  )
}
