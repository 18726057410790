import { GoalFilterNames, goalsListTableRequests } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { Statuses } from '@src/interfaces'
import { GoalContentType } from '@src/interfaces/goals'
import { toIdAndName } from '@src/utils/toIdAndName'
import { isEmployeeEntity } from './isEmployeeEntity'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { BaseOptionId } from '@src/interfaces/selectors'
import { FilterByInterface } from '@src/interfaces/data'
import { useCallback } from 'react'

type EmployeeGoalsType =
  | 'individual'
  | 'individual-with-drafts'
  | 'team'
  | 'team-with-drafts'

type Result = {
  employeeGoalType: EmployeeGoalsType
  relevantFilters: FilterByInterface[]
} | null

interface Props {
  entity: OrgEntityInterface
  cycleCategory?: ReviewCycleCategory
  cycleId?: BaseOptionId
}

export const useEmployeeGoals = ({ entity, cycleId, cycleCategory }: Props) => {
  const { data: contentTypeData } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )

  const teamContentTypeId = contentTypeData?.find(type => type.model === 'teams')?.id
  const employeeContentTypeId = contentTypeData?.find(
    type => type.model === 'employees',
  )?.id

  const getEmployeeGoalType = useCallback(async (): Promise<Result> => {
    if (
      !entity ||
      !isEmployeeEntity(entity) ||
      !contentTypeData ||
      !cycleId ||
      !cycleCategory
    ) {
      return null
    }
    const goalFilters: Record<EmployeeGoalsType, FilterByInterface[]> = {
      individual: [
        {
          columnName: GoalFilterNames.ApprovalStatus,
          filters: [
            toIdAndName(Statuses.pending),
            toIdAndName(Statuses.approved),
            toIdAndName(Statuses.requires_changes),
          ],
        },
        {
          columnName: GoalFilterNames.ContentTypeId,
          filters: [toIdAndName(String(employeeContentTypeId))],
        },
        {
          columnName: GoalFilterNames.ObjectId,
          filters: [toIdAndName(String(entity.data.id))],
        },
      ],
      'individual-with-drafts': [
        {
          columnName: GoalFilterNames.ApprovalStatus,
          filters: [
            toIdAndName(Statuses.pending),
            toIdAndName(Statuses.approved),
            toIdAndName(Statuses.requires_changes),
            toIdAndName(Statuses.draft),
          ],
        },
        {
          columnName: GoalFilterNames.ContentTypeId,
          filters: [toIdAndName(String(employeeContentTypeId))],
        },
        {
          columnName: GoalFilterNames.ObjectId,
          filters: [toIdAndName(String(entity.data.id))],
        },
      ],
      team: [
        {
          columnName: GoalFilterNames.ApprovalStatus,
          filters: [
            toIdAndName(Statuses.pending),
            toIdAndName(Statuses.approved),
            toIdAndName(Statuses.requires_changes),
          ],
        },
        {
          columnName: GoalFilterNames.ContentTypeId,
          filters: [toIdAndName(String(teamContentTypeId))],
        },
        {
          columnName: GoalFilterNames.ObjectId,
          filters: [toIdAndName(String(entity.data.team?.id))],
        },
      ],
      'team-with-drafts': [
        {
          columnName: GoalFilterNames.ApprovalStatus,
          filters: [
            toIdAndName(Statuses.draft),
            toIdAndName(Statuses.pending),
            toIdAndName(Statuses.approved),
            toIdAndName(Statuses.requires_changes),
          ],
        },
        {
          columnName: GoalFilterNames.ContentTypeId,
          filters: [toIdAndName(String(teamContentTypeId))],
        },
        {
          columnName: GoalFilterNames.ObjectId,
          filters: [toIdAndName(String(entity.data.team?.id))],
        },
      ],
    }
    const {
      data: { count: individualCount },
    } = await goalsListTableRequests.getItems({
      filters: [
        ...goalFilters.individual,
        {
          columnName: GoalFilterNames.CycleId,
          filters: [toIdAndName(String(cycleId))],
        },
      ],
    })
    if (individualCount > 0) {
      return {
        employeeGoalType: 'individual',

        relevantFilters: goalFilters.individual,
      }
    }
    const {
      data: { count: individualWithDraftCount },
    } = await goalsListTableRequests.getItems({
      filters: [
        ...goalFilters['individual-with-drafts'],
        {
          columnName: GoalFilterNames.CycleId,
          filters: [toIdAndName(String(cycleId))],
        },
      ],
    })
    if (individualWithDraftCount > 0) {
      return {
        employeeGoalType: 'individual-with-drafts',
        relevantFilters: goalFilters['individual-with-drafts'],
      }
    }

    if (cycleCategory === ReviewCycleCategory.Performance) {
      const {
        data: { count: teamCount },
      } = await goalsListTableRequests.getItems({
        filters: [
          ...goalFilters.team,
          {
            columnName: GoalFilterNames.CycleId,
            filters: [toIdAndName(String(cycleId))],
          },
        ],
      })

      if (teamCount > 0) {
        return { employeeGoalType: 'team', relevantFilters: goalFilters.team }
      }
      return {
        employeeGoalType: 'team-with-drafts',
        relevantFilters: goalFilters['team-with-drafts'],
      }
    }

    return {
      employeeGoalType: 'individual-with-drafts',
      relevantFilters: goalFilters['individual-with-drafts'],
    }
  }, [cycleCategory, entity, cycleId])

  return getEmployeeGoalType
}
