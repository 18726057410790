import { useDeleteV2, useFetchV2, UseQueryOptions } from '@src/utils/reactQuery'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { API } from '@src/constants/api'
import { apiV2 } from '@src/api'
import {
  PerformanceInsightsGroupInterface,
  PerformanceInsightsInterface,
  SkillOneToOneInsightsInterface,
  SkillPerformanceInsightsInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { EmployeeInterface } from '@src/interfaces/employees'
import { isEmpty } from 'lodash'

export const useGetPerformanceInsightsGroups = (
  queryOptions?: UseQueryOptions<GetRequestInterface<PerformanceInsightsGroupInterface>>,
) =>
  useFetchV2<GetRequestInterface<PerformanceInsightsGroupInterface>>({
    url: API.AI_PERFORMANCE_INSIGHTS_GROUPS,
    queryOptions,
  })

export const useDeleteInsightsGroup = () =>
  useDeleteV2({ url: API.AI_PERFORMANCE_INSIGHTS_GROUPS })

export const performanceInsightsGroupFormRequests: RequestInterfaceNew<PerformanceInsightsGroupInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.AI_PERFORMANCE_INSIGHTS_GROUPS}/${id}`),
    submit: async data => apiV2.post(API.AI_PERFORMANCE_INSIGHTS_GROUPS, data),
    update: async (data, { id }) =>
      apiV2.patch(`${API.AI_PERFORMANCE_INSIGHTS_GROUPS}/${id}`, data),
    delete: async ({ id }) => apiV2.delete(`${API.AI_PERFORMANCE_INSIGHTS_GROUPS}/${id}`),
  }

export const useGetPerformanceInsightsGroupEligibleEmployees = (id?: number) =>
  useFetchV2<GetRequestInterface<EmployeeInterface>>({
    url: `/performance/insightsGroups/${id}/employees`,
    queryOptions: { enabled: id !== undefined },
  })

export const useGetEmployeePerformanceKeyInsights = (
  employeeId?: number,
  cycleId?: number,
) =>
  useFetchV2<PerformanceInsightsInterface | null>({
    url: `/employees/${employeeId}/performance/keyInsights`,
    version: 'v2',
    params: { params: { cycle_id: cycleId } },
    queryOptions: {
      enabled: employeeId !== undefined && cycleId !== undefined,
      select: data => (isEmpty(data) ? null : data),
    },
  })

export const useGetEmployeeSkillPerformanceInsights = (
  employeeId?: number,
  cycleId?: number,
  skillId?: number,
) =>
  useFetchV2<SkillPerformanceInsightsInterface | null>({
    url: `/employees/${employeeId}/performance/skillInsights`,
    version: 'v2',
    params: { params: { cycle_id: cycleId, skill_id: skillId } },
    queryOptions: {
      enabled: employeeId !== undefined && cycleId !== undefined && skillId !== undefined,
      select: data => (isEmpty(data) ? null : data),
    },
  })

export const useGetEmployeeSkillOneToOneInsights = (
  employeeId?: number,
  insightId?: number,
  queryOptions?: UseQueryOptions<SkillOneToOneInsightsInterface[]>,
) =>
  useFetchV2<SkillOneToOneInsightsInterface[]>({
    url: `/employees/${employeeId}/performance/skillInsights/${insightId}/oneToOnes`,
    version: 'v2',
    queryOptions: {
      ...(queryOptions || {}),
      enabled:
        queryOptions?.enabled !== false &&
        employeeId !== undefined &&
        insightId !== undefined,
    },
  })
