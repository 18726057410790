import React from 'react'
import { connect } from 'lape'
import { EditSkill } from '@src/pages/Forms/SkillForm/Edit'
import Preview from '@src/pages/Forms/SkillForm/Preview'
import { SkillFormTalentTab } from '@src/pages/Forms/SkillForm/SkillFormTalentTab'
import Form from '@src/features/Form/Form'
import {
  silentDeleteSkill,
  skillRequestsNew,
  useGetSkillApprovals,
  useUpdateSkill,
} from '@src/api/skills'
import { SkillInterface } from '@src/interfaces/skills'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { getStatusThemeColor } from '@components/CommonSC/General'
import upperFirst from 'lodash/upperFirst'
import { Box, Color, useToggle } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import ScorecardPreview from '@src/pages/Forms/SkillForm/ScorecardPreview'
import { Statuses } from '@src/interfaces'
import { SkillSpecialisations } from '@src/pages/Forms/SkillForm/SkillSpecialisations'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import SettingsButtons, { CopyButton } from '@src/features/SettingsButtons'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'
import { goBack } from '@src/actions/RouterActions'
import useApproval from '@src/features/ApprovalFlow/useApproval'
import { SKILLS_DEFAULT_ICON } from '@src/constants/common'
import { API } from '@src/constants/api'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { getRolesSpecialisationsTitle } from '@src/features/Roles/helpers'
import MoreBarButton from '@src/features/SettingsButtons/MoreBarButton'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Instructions from './Instructions'

const backUrl = ROUTES.APPS.POSITIONS.SKILLS

const getTabs = (
  id: string,
  skillTalentEnabled: boolean,
  specialisationsEnabled: boolean,
) => [
  {
    title: 'Overview',
    path: ROUTES.FORMS.SKILL.PREVIEW,
    to: pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id }),
    component: Preview,
    canView: !!id,
  },
  {
    title: 'Talent',
    path: ROUTES.FORMS.SKILL.TALENT,
    to: pathToUrl(ROUTES.FORMS.SKILL.TALENT, { id }),
    component: SkillFormTalentTab,
    canView: !!id && skillTalentEnabled,
  },
  {
    title: getRolesSpecialisationsTitle(specialisationsEnabled),
    path: ROUTES.FORMS.SKILL.SPECIALISATIONS,
    to: pathToUrl(ROUTES.FORMS.SKILL.SPECIALISATIONS, { id }),
    component: SkillSpecialisations,
    canView: !!id,
  },
]

const getStatusColorAndText = (status: Statuses): { text: string; color: Color } => {
  switch (status) {
    case Statuses.expired:
      return { text: 'Outdated', color: 'warning' }
    case Statuses.pending_reapproval:
      return {
        text: 'Re-approval',
        color: 'warning',
      }
    default:
      return {
        text: upperFirst(status),
        color: getStatusThemeColor(status),
      }
  }
}

const SkillForm = connect(() => {
  const [isInstructionsOpen, toggleInstructions] = useToggle()
  const { values, initialValues } = useLapeContext<SkillInterface>()
  const { id } = useParams<{ id: string }>()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const featureFlags = useSelector(selectFeatureFlags)
  const skillTalentEnabled =
    featureFlags?.includes(FeatureFlags.SkillTalent) &&
    !!values.field_options?.permissions?.includes(PermissionTypes.CanViewSkillTalent)

  const tabs = getTabs(id, skillTalentEnabled, specialisationsEnabled).filter(
    tab => tab.canView,
  )
  const status = getStatusColorAndText(values.status)
  const canEdit = !!values.field_options?.actions?.includes?.(EntityPermissions.Change)
  const isPendingApproval = [
    Statuses.pending,
    Statuses.pending_reapproval,
    Statuses.expired,
  ].includes(initialValues.status || values.status)

  const isInReapproval = [Statuses.pending_reapproval, Statuses.expired].includes(
    initialValues.status || values.status,
  )

  const { approvalButtons } = useApproval({
    data: values,
    useGetApprovals: useGetSkillApprovals,
    isPendingApproval,
    noReject: isInReapproval,
    approveButtonActionText: isInReapproval ? 'Mark as reviewed' : undefined,
  })

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={
            <PageHeader.Title
              title={values.id ? values.name : 'Create a new skill'}
              avatar={
                <EntityAvatar
                  data={values}
                  defaultIcon={SKILLS_DEFAULT_ICON}
                  api={useUpdateSkill}
                  apiUrl={API.SKILLS}
                  canEdit={canEdit}
                  onSuccess={data => {
                    values.icon = data?.icon || null
                    values.avatar = data?.avatar || null
                  }}
                />
              }
              labels={
                <PageHeader.LabelsBar>
                  {values.status && (
                    <PageHeader.Label useTag color={status.color}>
                      {status.text}
                    </PageHeader.Label>
                  )}
                  <PageHeader.Label useTag icon="RepairTool">
                    Skill
                  </PageHeader.Label>
                  {values.owner && (
                    <PageHeader.Label
                      icon="Profile"
                      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                        id: values.owner.id,
                      })}
                    >
                      {values.owner.name}
                    </PageHeader.Label>
                  )}
                </PageHeader.LabelsBar>
              }
              actions={
                values.id ? (
                  <SettingsButtons>
                    {approvalButtons}
                    <CopyButton
                      afterSubmitUrl={pathToUrl(ROUTES.FORMS.SKILL.GENERAL, {})}
                    />
                    {values.id && (
                      <MoreBarButton
                        useIcon="Pencil"
                        use={InternalLink}
                        to={pathToUrl(ROUTES.FORMS.SKILL.GENERAL, { id: values.id })}
                      >
                        Edit
                      </MoreBarButton>
                    )}
                    <MoreBarButton
                      useIcon="InfoOutline"
                      data-testid="open_instructions_button"
                      onClick={toggleInstructions.on}
                    >
                      Show instructions
                    </MoreBarButton>
                    <LapeDeleteOrgUnitButton
                      onAfterDelete={() => goBack(ROUTES.FUNCTION.SKILLS)}
                      deleteApi={silentDeleteSkill}
                      prefix="skill"
                      displayName="skill"
                    />
                  </SettingsButtons>
                ) : undefined
              }
            />
          }
          backUrl={backUrl}
        />
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <Box pb="s-12">
                <TabBarNavigation tabs={tabs} />
              </Box>
              <tab.component />
            </Route>
          ))}
        </Switch>
      </PageWrapper>
      <Instructions isOpen={isInstructionsOpen} onClose={toggleInstructions.off} />
    </>
  )
})

const PageSkill = () => {
  const isFormPath = useRouteMatch(ROUTES.FORMS.SKILL.GENERAL)
  return (
    // need to disable catching in the form as deleting elements of array cause the cached version to be `[null]`
    // that breaks the page on refresh
    <Form api={skillRequestsNew} disableLocalStorageCaching={!!isFormPath}>
      <Switch>
        <Route exact path={ROUTES.FORMS.SKILL.GENERAL}>
          <EditSkill />
        </Route>
        <Route exact path={ROUTES.FORMS.SKILL.SCORECARD_PREVIEW}>
          <ScorecardPreview />
        </Route>
        <SkillForm />
      </Switch>
    </Form>
  )
}

export default connect(PageSkill)
