import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Details,
  HStack,
  Widget,
  Text,
  Icon,
  Token,
  VStack,
  TextButton,
  Flex,
  Copyable,
} from '@revolut/ui-kit'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { pushNotification } from '@src/store/notifications/actions'
import { ActionItem, AiGenerationStatus } from '@src/interfaces/meetings'
import { debounce } from 'lodash'
import { Actionable } from '../../components/Actionable'
import SectionStatus from './SectionStatus'
import { useActionPointContent } from '../hooks/useActionPointContent'
import { getContentItemsByRole } from '../utils/getContentItemsByRole'
import { useMeetingNoteContext } from '../context/meetingNoteContext'

export const ActionPoints = () => {
  const {
    onContentChange,
    updatingSection,
    meetingNotesDetails,
    settings: { hasEditDisabled },
  } = useMeetingNoteContext()

  const isUpdating = updatingSection === 'action_items'

  const actionPoints = useMemo(
    () =>
      getContentItemsByRole(meetingNotesDetails, 'action_items')?.content.content || [],
    [meetingNotesDetails],
  )

  const { actionItems, toggleComplete, textAreaRefs, updateContent, addNew, isEdited } =
    useActionPointContent(actionPoints)

  const [isTyping, setIsTyping] = useState(false)

  const updateDebounced = useCallback(
    debounce((newItems: ActionItem[]) => {
      onContentChange({
        newValue: { content: newItems },
        role: 'action_items',
      })
    }, 1000),
    [onContentChange],
  )

  const isGeneratedByAi = Boolean(
    meetingNotesDetails?.enable_ai &&
      meetingNotesDetails?.action_items_status === AiGenerationStatus.COMPLETED,
  )

  useEffect(() => {
    if (!isUpdating) {
      setIsTyping(false)
    }
  }, [isUpdating])

  useEffect(() => {
    if (isTyping && !isUpdating) {
      // this is to prevent initial call to update when it's not needed only wait for user input
      updateDebounced(
        actionItems.map(item => ({ completed: item.completed, value: item.value })),
      )
    }
  }, [actionItems])

  const isLoading = isUpdating || isTyping

  return (
    <Widget py="s-12" px="s-16" height="fit-content">
      <Details style={{ paddingTop: 0 }}>
        <Details.Title>
          <SectionStatus
            title="Action items"
            isGeneratedByAi={isGeneratedByAi}
            isEdited={isEdited}
            isLoading={isLoading}
          />
        </Details.Title>
        <Details.Content>
          <Flex alignItems="center" justifyContent="center" height="100%">
            <Copyable
              style={{ color: Token.color.greyTone20 }}
              value={actionItems.map(({ value }) => value).join(' \n')}
              labelButtonCopy="Copy"
              onCopy={() => {
                pushNotification({
                  value: 'Action items are copied to clipboard',
                  duration: SUCCESS_DEFAULT_DURATION,
                  type: NotificationTypes.success,
                })
              }}
            />
          </Flex>
        </Details.Content>
      </Details>
      <VStack gap="s-4">
        {actionItems.map(actionItem => (
          <Actionable
            disabled={false} // for now editing is enabled later this will change
            actionItem={actionItem}
            key={actionItem.id}
            onEnterPressed={addNew}
            onTextChange={newValue => {
              setIsTyping(true)
              updateContent(actionItem.id, newValue)
            }}
            onChecked={() => {
              setIsTyping(true)
              toggleComplete(actionItem.id)
            }}
            inputRef={el => {
              textAreaRefs.current[actionItem.id] = el
            }}
          />
        ))}
        {!hasEditDisabled && (
          <Flex justifyContent="end" width="100%">
            <TextButton onClick={addNew}>
              <HStack gap="s-4" align="center">
                <Icon name="Plus" size={16} />
                <Text variant="caption">Add action item</Text>
              </HStack>
            </TextButton>
          </Flex>
        )}
      </VStack>
    </Widget>
  )
}
