import React from 'react'
import { EntityTypes } from '@src/constants/api'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import {
  departmentResourcesAnalyticsDashboardDescriptionColumn,
  departmentResourcesAnalyticsDashboardNameColumn,
  departmentResourcesAnalyticsDashboardOwnerColumn,
  departmentResourcesAnalyticsDashboardSourceColumn,
} from '@src/pages/Forms/DepartmentForm/Resources/constants/columns'
import { TableNames } from '@src/constants/table'
import {
  DepartmentResourcesStatsNavigation,
  getDepartmentResourcesAnalyticsInitialFilter,
} from '@src/pages/Forms/DepartmentForm/Resources/components/StatsNavigation'

const row: RowInterface<GenericAnalyticsDashboardInterface> = {
  cells: [
    {
      ...departmentResourcesAnalyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...departmentResourcesAnalyticsDashboardSourceColumn,
      width: 80,
    },
    {
      ...departmentResourcesAnalyticsDashboardOwnerColumn,
      width: 100,
    },
    {
      ...departmentResourcesAnalyticsDashboardDescriptionColumn,
      width: 200,
    },
  ],
}

interface DepartmentResourcesAnalyticsProps {
  data: DepartmentInterface
}

export const DepartmentResourcesAnalytics = ({
  data,
}: DepartmentResourcesAnalyticsProps) => {
  const initialSortBy = [
    {
      sortBy: 'position_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.department}
      row={row}
      initialFilter={getDepartmentResourcesAnalyticsInitialFilter(data.id)}
      initialSort={initialSortBy}
      name={TableNames.DepartmentResourcesAnalytics}
      navigation={<DepartmentResourcesStatsNavigation />}
    />
  )
}
