import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetEmployeePerformanceKeyInsights } from '@src/features/AiInsights/Performance/api/insights'
import {
  PerformanceInsightsGroupInterface,
  PerformanceInsightsInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { BaseOption } from '@src/interfaces/selectors'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { useGetEmployee } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  MetricsParamsKeys,
  useGetEmployeeBitbucketMetrics,
  useGetEmployeeInterviewsMetrics,
  useGetEmployeeJiraMetrics,
  useGetOrgUnitBitbucketMetrics,
  useGetOrgUnitInterviewsMetrics,
  useGetOrgUnitJiraMetrics,
} from '@src/features/AiInsights/Performance/api/metrics'
import {
  IndividualBitbucketMetricsInterface,
  IndividualInterviewsMetricsInterface,
  IndividualJiraMetricsInterface,
  OrgUnitBitbucketMetricsInterface,
  OrgUnitInterviewsMetricsInterface,
  OrgUnitJiraMetricsInterface,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { FilterByInterface } from '@src/interfaces/data'
import { toIdAndName } from '@src/utils/toIdAndName'
import { useTable, useTableReturnType } from '@components/TableV2/hooks'
import { jiraEpicsContributionsTableRequests } from '@src/api/roadmaps'
import { JiraEpicInterface } from '@src/interfaces/roadmaps'

interface Props {
  employee: EmployeeInterface | null
  aiInsights: PerformanceInsightsInterface | null
  insightsGroup: PerformanceInsightsGroupInterface | null
  selectedOrgUnit: BaseOption<string> | null
  orgUnits: BaseOption<string>[]
  employeeJiraMetrics: IndividualJiraMetricsInterface | null
  employeeBitbucketMetrics: IndividualBitbucketMetricsInterface | null
  employeeInterviewsMetrics: IndividualInterviewsMetricsInterface | null
  orgUnitJiraMetrics: OrgUnitJiraMetricsInterface | null
  orgUnitBitbucketMetrics: OrgUnitBitbucketMetricsInterface | null
  orgUnitInterviewsMetrics: OrgUnitInterviewsMetricsInterface | null
  jiraEpicsTable: useTableReturnType<JiraEpicInterface, undefined>
  isLoading: boolean
  isJiraMetricsLoading: boolean
  isBitbucketMetricsLoading: boolean
  isInterviewsMetricsLoading: boolean
  onSelectOrgUnit: (orgUnit: BaseOption<string>) => void
}

export const usePerformanceInsights = (employeeId: number, cycleId: number): Props => {
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<BaseOption<string> | null>(null)

  const { data: employee, isLoading: isEmployeeLoading } = useGetEmployee(employeeId)

  const { data: aiInsights, isLoading: isInsightsLoading } =
    useGetEmployeePerformanceKeyInsights(employeeId, cycleId)

  const { data: orgUnits = [], isLoading: isOrgUnitsLoading } = useGetSelectors<
    BaseOption<string>
  >(selectorKeys.performance_insights_org_units)

  const { data: employeeJiraMetrics, isLoading: isEmployeeJiraMetricsLoading } =
    useGetEmployeeJiraMetrics(employeeId, {
      [MetricsParamsKeys.CycleId]: cycleId,
    })
  const { data: orgUnitJiraMetrics, isLoading: isOrgUnitJiraMetricsLoading } =
    useGetOrgUnitJiraMetrics(employeeId, {
      [MetricsParamsKeys.OrgUnitId]: selectedOrgUnit?.id,
      [MetricsParamsKeys.CycleId]: cycleId,
    })

  const jiraEpicsTableFilters = useMemo<FilterByInterface[]>(
    () => [
      {
        filters: [toIdAndName(String(employeeId))],
        columnName: 'employee_id',
      },
      {
        filters: [toIdAndName(String(cycleId))],
        columnName: 'cycle_id',
      },
    ],
    [employeeId, cycleId],
  )
  const jiraEpicsTable = useTable(
    jiraEpicsContributionsTableRequests,
    jiraEpicsTableFilters,
  )

  const { data: employeeBitbucketMetrics, isLoading: isEmployeeBitbucketMetricsLoading } =
    useGetEmployeeBitbucketMetrics(employeeId, {
      [MetricsParamsKeys.CycleId]: cycleId,
    })
  const { data: orgUnitBitbucketMetrics, isLoading: isOrgUnitBitbucketMetricsLoading } =
    useGetOrgUnitBitbucketMetrics(employeeId, {
      [MetricsParamsKeys.OrgUnitId]: selectedOrgUnit?.id,
      [MetricsParamsKeys.CycleId]: cycleId,
    })

  const {
    data: employeeInterviewsMetrics,
    isLoading: isEmployeeInterviewsMetricsLoading,
  } = useGetEmployeeInterviewsMetrics(employeeId, {
    [MetricsParamsKeys.CycleId]: cycleId,
  })
  const { data: orgUnitInterviewsMetrics, isLoading: isOrgUnitInterviewsMetricsLoading } =
    useGetOrgUnitInterviewsMetrics(employeeId, {
      [MetricsParamsKeys.OrgUnitId]: selectedOrgUnit?.id,
      [MetricsParamsKeys.CycleId]: cycleId,
    })

  useEffect(() => {
    if (!selectedOrgUnit && orgUnits.length > 0) {
      setSelectedOrgUnit(orgUnits[0])
    }
  }, [orgUnits.length, selectedOrgUnit])

  const onSelectOrgUnit = useCallback(orgUnit => {
    setSelectedOrgUnit(orgUnit)
  }, [])

  return useMemo<Props>(
    () => ({
      orgUnits,
      employee: employee ?? null,
      insightsGroup: employee ? employee.performance_insights_group : null,
      aiInsights: aiInsights ?? null,
      selectedOrgUnit: selectedOrgUnit ?? null,
      employeeJiraMetrics: employeeJiraMetrics?.results[0] ?? null,
      employeeBitbucketMetrics: employeeBitbucketMetrics?.results[0] ?? null,
      employeeInterviewsMetrics: employeeInterviewsMetrics?.results[0] ?? null,
      orgUnitJiraMetrics: orgUnitJiraMetrics ?? null,
      orgUnitBitbucketMetrics: orgUnitBitbucketMetrics ?? null,
      orgUnitInterviewsMetrics: orgUnitInterviewsMetrics ?? null,
      jiraEpicsTable: { ...jiraEpicsTable, count: jiraEpicsTable.data.length },
      isLoading: isInsightsLoading || isOrgUnitsLoading || isEmployeeLoading,
      isJiraMetricsLoading:
        isEmployeeJiraMetricsLoading ||
        isOrgUnitJiraMetricsLoading ||
        jiraEpicsTable.loading,
      isBitbucketMetricsLoading:
        isEmployeeBitbucketMetricsLoading || isOrgUnitBitbucketMetricsLoading,
      isInterviewsMetricsLoading:
        isEmployeeInterviewsMetricsLoading || isOrgUnitInterviewsMetricsLoading,
      onSelectOrgUnit,
    }),
    [
      employee?.id,
      aiInsights?.id,
      selectedOrgUnit?.id,
      orgUnits.length,
      employeeJiraMetrics?.results.length,
      employeeBitbucketMetrics?.results.length,
      employeeInterviewsMetrics?.results.length,
      orgUnitJiraMetrics,
      orgUnitBitbucketMetrics,
      orgUnitInterviewsMetrics,
      jiraEpicsTable,
      isInsightsLoading,
      isEmployeeLoading,
      isEmployeeJiraMetricsLoading,
      isEmployeeBitbucketMetricsLoading,
      isEmployeeInterviewsMetricsLoading,
      isOrgUnitJiraMetricsLoading,
      isOrgUnitBitbucketMetricsLoading,
      isOrgUnitInterviewsMetricsLoading,
    ],
  )
}
