import { useMemo } from 'react'
import { Token } from '@revolut/ui-kit'
import {
  RatingHistoryDataKey,
  RatingHistoryItemData,
} from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/ChartData'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { useGetSelectors } from '@src/api/selectors'
import { IdAndName } from '@src/interfaces'
import { BaseOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { PerformanceRating } from '@src/interfaces/performance'

export const RATING_COLOR = Token.colorChannel.blue
export const EXPECTED_RATING_COLOR = Token.color.foreground
const HISTORY_RECORDS_COUNT = 6

interface Props {
  dataKeys: RatingHistoryDataKey[]
  data: RatingHistoryItemData[]
  yAxisTicks: IdAndName<string>[]
  isLoading: boolean
}

export const useChartData = (historyData: TalentSkillRatingInterface[]): Props => {
  const { data: ratingOptions = [], isLoading } = useGetSelectors<
    BaseOption<PerformanceRating>
  >(selectorKeys.skills_distribution_choices)

  const dataKeys = useMemo<RatingHistoryDataKey[]>(
    () => [
      {
        id: 0,
        label: 'Skill level',
        value: 'rating',
        color: RATING_COLOR,
      },
      {
        id: 1,
        label: 'Expected level',
        value: 'expected_rating',
        isProjected: true,
      },
    ],
    [],
  )

  const data = useMemo<RatingHistoryItemData[]>(
    () =>
      historyData
        .reduce<RatingHistoryItemData[]>((ratedData, datum, index) => {
          const { rating_category, expected_rating, cycle } = datum

          const ratingCategorySortedIndex = ratingOptions.findIndex(
            ({ id }) => id === rating_category,
          )
          const expectedRatingCategorySortedIndex = ratingOptions.findIndex(
            ({ id }) => id === expected_rating,
          )

          if (
            rating_category === null ||
            ratingCategorySortedIndex === -1 ||
            expectedRatingCategorySortedIndex === -1
          ) {
            return ratedData
          }

          const itemData: RatingHistoryItemData = {
            id: rating_category,
            rating: ratingCategorySortedIndex,
            expected_rating: expectedRatingCategorySortedIndex,
            label: cycle.name,
            index,
            rawData: datum,
          }

          return [...ratedData, itemData]
        }, [])
        .slice(-HISTORY_RECORDS_COUNT, historyData.length),
    [historyData, ratingOptions],
  )

  return useMemo<Props>(
    () => ({
      dataKeys,
      data,
      yAxisTicks: ratingOptions,
      isLoading,
    }),
    [dataKeys, data, ratingOptions, isLoading],
  )
}
