import React, { useMemo, useState } from 'react'
import { ReviewInterface, ReviewScorecardInterface } from '@src/interfaces/performance'
import { NoPersonalGoalsBanner } from '../NoPersonalGoalsBanner'
import { Box, TabBar, VStack } from '@revolut/ui-kit'
import { TeamGoalsCardTable } from '../TeamGoalsCardTable'
import { EmployeeGoalsCardTable } from '../EmployeeGoalsCardTable'
import { GradesMapInterface } from '@src/utils/grades'
import { CollapsableGoalTree } from '@src/features/Goals/components/CollapsableGoalTree'
import { GoalFilterNames, useGetOrganisationalUnit } from '@src/api/goals'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { useGoalsVisibilityBySummary } from '@src/features/Goals/useGoalsVisibilityBySummary'
import { toIdAndName } from '@src/utils/toIdAndName'
import TableLoader from '@src/components/TableV2/TableLoader'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { selectorKeys } from '@src/constants/api'
import { IdAndName, Statuses } from '@src/interfaces'

interface Props {
  setGoalsStats: React.Dispatch<React.SetStateAction<number | undefined>>
  gradesMap: GradesMapInterface
  values: ReviewScorecardInterface | ReviewInterface
  team: IdAndName | null | undefined
}

export const ScorecardGoals = ({ setGoalsStats, gradesMap, values, team }: Props) => {
  const [goalTab, setGoalTab] = useState<'performance' | 'organisational' | null>(
    'performance',
  )
  const { data: goalContentTypes } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )

  const employeeContentTypeId = useMemo(() => {
    return goalContentTypes?.find(type => type.model === 'employees')?.id
  }, [goalContentTypes])

  const cycleId = values.cycle
    ? cycleModel.getCycleIdWithAssociatedGoals(values.cycle)
    : undefined

  const { data: orgUnitData } = useGetOrganisationalUnit({
    employeeId: values.reviewed_employee.id,
    cycleId,
  })

  const { isLoading, hasIndividualGoals, isIndividualContributor } =
    useGoalsVisibilityBySummary(
      {
        team: values.team,
        reviewed_employee_type: values.reviewed_employee_type?.id,
      },
      [
        {
          columnName: GoalFilterNames.ObjectId,
          filters: [toIdAndName(String(values.reviewed_employee.id))],
        },
        {
          columnName: GoalFilterNames.ContentTypeId,
          filters: [toIdAndName(String(employeeContentTypeId))],
        },
        {
          columnName: GoalFilterNames.ApprovalStatus,
          nonResettable: true,
          filters: [
            toIdAndName(Statuses.pending),
            toIdAndName(Statuses.approved),
            toIdAndName(Statuses.requires_changes),
          ],
        },
        {
          columnName: GoalFilterNames.CycleId,
          filters: cycleId ? [toIdAndName(String(cycleId))] : [],
        },
      ],
      { enabled: !!employeeContentTypeId },
    )

  return (
    <>
      {isIndividualContributor && (
        <VStack gap="s-16">
          <NoPersonalGoalsBanner team={team || undefined} />
          <TeamGoalsCardTable
            setGoalsStat={setGoalsStats}
            gradesMap={gradesMap}
            cycle={values.cycle}
            team={team || undefined}
          />
        </VStack>
      )}
      {!isIndividualContributor && (
        <VStack gap="s-16">
          {!hasIndividualGoals && !isLoading && (
            <NoPersonalGoalsBanner team={team || undefined} />
          )}
          <TabBar variant="segmented fit" value={goalTab} onChange={setGoalTab}>
            <TabBar.Item to="performance">Performance goals</TabBar.Item>
            <TabBar.Item to="organisational">Organisational goals</TabBar.Item>
          </TabBar>
          {goalTab === 'performance' && (
            <>
              {isLoading && (
                <Box opacity={0.5}>
                  <TableLoader rowHeight="small" loading />
                </Box>
              )}
              {hasIndividualGoals && !isLoading && (
                <EmployeeGoalsCardTable
                  setGoalsStat={setGoalsStats}
                  gradesMap={gradesMap}
                  cycle={values.cycle}
                  employeeId={values.reviewed_employee.id}
                />
              )}
              {!hasIndividualGoals && !isLoading && (
                <TeamGoalsCardTable
                  setGoalsStat={setGoalsStats}
                  gradesMap={gradesMap}
                  cycle={values.cycle}
                  team={team || undefined}
                />
              )}
            </>
          )}
          {goalTab === 'organisational' && (
            <CollapsableGoalTree
              units={orgUnitData}
              cycleId={cycleId ? String(cycleId) : undefined}
            />
          )}
        </VStack>
      )}
    </>
  )
}
