import {
  ActionButton,
  Box,
  Details,
  Text,
  Token,
  useToggle,
  VStack,
} from '@revolut/ui-kit'
import { getProbationTemplates } from '@src/api/probationTemplate'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'

import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { CollapsibleRadioSwitch } from '@src/pages/Settings/common/CollapsibleRadioSwitch'
import { Managers } from '@src/pages/Settings/Performance/Managers'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import {
  probationTemplateActionColumn,
  probationTemplateCountryWithNameColumn,
  probationTemplateDurationColumn,
  probationTemplateEmployeesColumn,
  probationTemplateEntityNameColumn,
  probationTemplateNoticePeriodColumn,
  probationTemplatePolicyColumn,
  probationTemplateStatusColumn,
} from '@src/features/Probation/Templates/columns'
import { EditProbationTemplateSidebar } from '@src/pages/People/PeopleSubTabs/ProbationEmployees/EditProbationTemplateSidebar'
import { CreateProbationTemplateSidebar } from '@src/pages/People/PeopleSubTabs/ProbationEmployees/CreateProbationTemplateSidebar'

const getRows = ({
  onEdit,
}: {
  onEdit: (id: number) => void
}): RowInterface<ProbationTemplateInterface> => ({
  cells: [
    {
      ...probationTemplateCountryWithNameColumn,
      width: 200,
    },
    { ...probationTemplateEmployeesColumn, width: 150 },
    { ...probationTemplateEntityNameColumn, width: 120 },
    { ...probationTemplateDurationColumn, width: 120 },
    { ...probationTemplateNoticePeriodColumn, width: 120 },
    { ...probationTemplateStatusColumn, width: 80 },
    { ...probationTemplatePolicyColumn, width: 70 },
    { ...probationTemplateActionColumn({ onEdit }), width: 50 },
  ],
})

export const ProbationSettings = () => {
  const [isCreateTemplateSidebarOpen, toggleCreateTemplateSidebar] = useToggle()
  const { values, submit } = useLapeContext<PerformanceSettingsInterface>()
  const [disableQuery, setDisableQuery] = useState(!values.enable_probation)
  const table = useTable<ProbationTemplateInterface>(
    { getItems: getProbationTemplates },
    undefined,
    undefined,
    { disableQuery, disable: disableQuery },
  )

  const [editingTemplateId, setEditingTemplateId] = useState<number>()

  const row = useMemo(
    () => getRows({ onEdit: setEditingTemplateId }),
    [setEditingTemplateId],
  )

  const handleProbationSwitch = async (e: ChangeEvent<HTMLInputElement>) => {
    values.enable_probation = e.target.checked
    if (!e.target.checked) {
      values.enforce_probation_committee_review = false
    }
    await submit()
    setDisableQuery(!values.enable_probation)
  }
  return (
    <>
      <CollapsibleRadioSwitch
        itemTypeProps={{
          title: 'Enable probation',
          description: 'Allows newly onboarded employees to go trough a probation period',
        }}
        name="enable_probation"
        onChange={handleProbationSwitch}
      >
        <Box>
          <Managers type="probation" />

          <Details style={{ paddingBottom: 0, paddingTop: Token.space.s32 }}>
            <Details.Title pl="s-16">
              <VStack>
                <Text variant="primary" color={Token.color.foreground}>
                  Define probation templates
                </Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  New probation cycles can be started based on pre-defined templates.
                </Text>
              </VStack>
            </Details.Title>

            <Details.Content pr="s-16">
              <ActionButton
                useIcon="Plus"
                onClick={() => {
                  setEditingTemplateId(undefined)
                  toggleCreateTemplateSidebar.on()
                }}
              >
                Add new template
              </ActionButton>
            </Details.Content>
            <Details.Note pb={0}>
              <AdjustableTable<ProbationTemplateInterface>
                name={TableNames.ProbationTemplates}
                row={row}
                {...table}
                emptyState={
                  <EmptyTableRaw title="Probation templates will appear here." />
                }
              />
            </Details.Note>
          </Details>
        </Box>
      </CollapsibleRadioSwitch>
      {isCreateTemplateSidebarOpen && (
        <CreateProbationTemplateSidebar
          onClose={toggleCreateTemplateSidebar.off}
          onAfterSubmit={() => {
            table.refresh()
            toggleCreateTemplateSidebar.off()
          }}
        />
      )}
      {editingTemplateId && (
        <EditProbationTemplateSidebar
          templateId={editingTemplateId}
          onClose={() => {
            setEditingTemplateId(undefined)
          }}
          onAfterSubmit={() => {
            table.refresh()
            setEditingTemplateId(undefined)
          }}
        />
      )}
    </>
  )
}
