import React from 'react'

import Loader from '@components/CommonSC/Loader'
import { useGetCompany } from '@src/api/company'
import { getScopeFilters } from '@src/features/Engagement/helpers/getScopeFilters'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResults/Published'

export const Engagement = () => {
  const { data, isLoading } = useGetCompany()

  if (isLoading) {
    return <Loader />
  }
  return (
    <SurveyResultsPublished
      isCompany
      entityId={data?.id}
      scopeFilters={getScopeFilters(data, 'company')}
    />
  )
}
