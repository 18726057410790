import React, { useState } from 'react'
import { Button, ButtonVariant } from '@revolut/ui-kit'
import { PlanType } from '@src/pages/Forms/Plans/types'
import { AvailableSubscriptionPlanInterfaceV2 } from '@src/interfaces/plans'
import {
  getMerchantApiConfig,
  createMerchantApiOrder,
  useRequestManualPlan,
  changeMerchantApiPaymentMethod,
} from '@src/api/plans'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useSubscriptionPlanContextV2 } from '@src/pages/Forms/Plans/SubscriptionPlanProvider'

interface SelectPlanButtonProps {
  plan: AvailableSubscriptionPlanInterfaceV2
  isSelectedPlan: boolean
  planType: PlanType
  variant?: ButtonVariant
  noPaymentMethod: boolean
}

export const SelectPlanButton = ({
  plan,
  isSelectedPlan,
  planType,
  variant,
  noPaymentMethod,
}: SelectPlanButtonProps) => {
  const showStatusPopup = useShowStatusPopup()
  const { mutateAsync: requestManualPlan, isLoading: applyForAnnualPlanPending } =
    useRequestManualPlan()
  const [createOrderPending, setCreateOrderPending] = useState(false)
  const { selectedPlan, setSelectedPlan, setOrderAndConfig } =
    useSubscriptionPlanContextV2()

  const planSelect = () => {
    if (!createOrderPending) {
      setCreateOrderPending(true)

      Promise.all([getMerchantApiConfig(), createMerchantApiOrder(plan.id)])
        .then(([configResponse, orderResponse]) => {
          setOrderAndConfig({
            order: orderResponse.data,
            config: configResponse.data,
            type: 'subscribe',
          })
          setSelectedPlan(plan)
          navigateTo(ROUTES.PLANS.CHECKOUT)
        })
        .catch(() => {
          setCreateOrderPending(false)
        })
    }
  }

  const applyForAnnualPlan = async () => {
    try {
      await requestManualPlan({
        custom_plan_requested_bundle_features: plan.bundle_features,
      })
      showStatusPopup({
        title: 'We’ll contact you soon',
        description: "We'll be in touch shortly to discuss a plan offer for you",
        status: 'pending',
      })
    } catch (error) {
      showStatusPopup({
        title: 'Apply for annual plan failed',
        description: getMessageFromApiError(error),
        status: 'error',
      })
    }
  }

  const addPaymentMethod = () => {
    if (selectedPlan && !createOrderPending) {
      setCreateOrderPending(true)
      Promise.all([getMerchantApiConfig(), changeMerchantApiPaymentMethod()])
        .then(([configResponse, orderResponse]) => {
          setOrderAndConfig({
            order: orderResponse.data,
            config: configResponse.data,
            type: 'change-payment-method',
          })
          navigateTo(ROUTES.PLANS.CHECKOUT)
        })
        .catch(() => {
          setCreateOrderPending(false)
        })
    }
  }

  const handleButtonClick = () => {
    if (planType === 'monthly' && noPaymentMethod && isSelectedPlan) {
      addPaymentMethod()
      return
    }

    if (planType === 'monthly') {
      planSelect()
      return
    }

    if (planType === 'annual') {
      applyForAnnualPlan()
    }
  }
  const isSelectedMonthlyPlan = isSelectedPlan && planType === 'monthly'

  const getButtonText = () => {
    if (isSelectedMonthlyPlan) {
      return 'Your current plan'
    }
    if (planType === 'annual') {
      return 'Contact us'
    }
    if (!selectedPlan) {
      return 'Get started'
    }
    if (Number(selectedPlan.cycle_fee) >= Number(plan.cycle_fee)) {
      return 'Switch'
    }
    return 'Upgrade'
  }

  return (
    <Button
      useIcon={isSelectedMonthlyPlan ? 'CheckSuccess' : undefined}
      variant={variant || isSelectedMonthlyPlan ? 'primary' : 'secondary'}
      onClick={handleButtonClick}
      pending={applyForAnnualPlanPending || createOrderPending}
    >
      {getButtonText()}
    </Button>
  )
}
