import { IconName } from '@revolut/ui-kit'
import { ItemType } from '@src/interfaces/deliverables'

export const epicTypeToIcon: Record<ItemType, IconName | undefined> = {
  [ItemType.Clickup]: 'ClickUp|image',
  [ItemType.Manual]: undefined,
  [ItemType.Jira]: 'Jira|image',
  [ItemType.Linear]: undefined,
  [ItemType.Notion]: undefined,
}
