import React from 'react'
import { FormattedMessage } from 'react-intl'
import pluralize from 'pluralize'
import { Color, HStack, Spacer, Text, Token, VStack } from '@revolut/ui-kit'
import {
  getComparableMetricProps,
  MetricDetailsVariants,
  thresholdPercentage,
} from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/constants'
import { Capitalize } from '@components/CommonSC/General'
import { formatNumericValue } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/formatters'
import { formatPercentage } from '@src/utils/format'

interface Props {
  value: number
  average: number
  metricName: string
  orgUnitName: string
  employeeName?: string
  title: React.ReactNode
  description?: React.ReactNode
  summary?: React.ReactNode
  variant?: MetricDetailsVariants
  inverted?: boolean
}

export const MetricDetails = ({
  value,
  average,
  employeeName,
  metricName,
  orgUnitName,
  title,
  description,
  summary,
  variant = MetricDetailsVariants.Default,
  inverted = false,
}: Props) => {
  const [dividend, divisor] = inverted ? [average, value] : [value, average]
  const diffPercentage = divisor > 0 ? 1 - Number((dividend / divisor).toFixed(2)) : 0
  const employeeNameWithFallback = employeeName ?? 'Employee'

  const metricProps = getComparableMetricProps(dividend, divisor)

  return (
    <VStack space="s-8" p="s-8">
      <Text fontWeight={500}>{title}</Text>
      {description && <Text variant="body2">{description}</Text>}
      <VStack space="s-4">
        <HStack align="center">
          <Text color={Token.color.greyTone50}>
            <FormattedMessage
              id="performance.metrics.details.employee.name.title"
              values={{ employeeName: employeeNameWithFallback }}
              defaultMessage="{employeeName}:"
            />
          </Text>
          <Spacer />
          <Text color={metricProps?.color}>
            <DetailsContent value={value} name={metricName} variant={variant} />
          </Text>
        </HStack>
        <HStack align="center">
          <Text color={Token.color.greyTone50}>
            <Capitalize>
              <FormattedMessage
                id="performance.metrics.details.org.unit.avg.title"
                values={{ orgUnitName }}
                defaultMessage="{orgUnitName} avg:"
              />
            </Capitalize>
          </Text>
          <Spacer />
          <DetailsContent value={average} name={metricName} variant={variant} />
        </HStack>
      </VStack>
      {Math.abs(diffPercentage) > thresholdPercentage && (
        <DifferenceContent
          variant={diffPercentage > 0 ? 'negative' : 'positive'}
          percentage={diffPercentage}
          employeeName={employeeNameWithFallback}
          orgUnitName={orgUnitName}
          color={metricProps?.color}
        />
      )}
      {summary && summary}
    </VStack>
  )
}

interface DetailsContentProps {
  value: number
  name: string
  variant: MetricDetailsVariants
}

const DetailsContent = ({ value, name, variant }: DetailsContentProps) => {
  const formattedValue = formatNumericValue(value, variant)

  if (variant !== MetricDetailsVariants.Default) {
    return <Text>{formattedValue}</Text>
  }

  return (
    <FormattedMessage
      id="performance.metrics.details.content"
      values={{ count: formattedValue, name: pluralize(name, value) }}
      defaultMessage="{count} {name}"
    />
  )
}

interface DifferenceContentProps {
  variant: 'positive' | 'negative'
  percentage: number
  employeeName: string
  orgUnitName: string
  color?: Color
}

const DifferenceContent = ({
  variant,
  percentage,
  employeeName,
  orgUnitName,
  color,
}: DifferenceContentProps) => {
  const diffFormatted = formatPercentage(Math.abs(percentage))

  if (variant === 'positive') {
    return (
      <Text>
        <FormattedMessage
          id="performance.metrics.highlights.stat.positive.difference"
          values={{
            name: employeeName,
            percentage: diffFormatted,
            orgUnitName,
            colored: chunks => <Text color={color}>{chunks}</Text>,
          }}
          defaultMessage="{name} performs <colored>{percentage} above the {orgUnitName} average</colored>"
        />
      </Text>
    )
  }

  return (
    <Text>
      <FormattedMessage
        id="performance.metrics.highlights.stat.negative.difference"
        values={{
          name: employeeName,
          percentage: diffFormatted,
          orgUnitName,
          colored: chunks => <Text color={color}>{chunks}</Text>,
        }}
        defaultMessage="{name} performs <colored>{percentage} below the {orgUnitName} average</colored>"
      />
    </Text>
  )
}
