import {
  getCategoryIconName,
  getCategoryName,
  getLink,
  getStatusIndicator,
  getStatusText,
  InsightsWidgetStatus,
} from './helpers'
import { Flex, HStack, Icon, Placeholder, Text, VStack, Widget } from '@revolut/ui-kit'
import React, { useState } from 'react'
import {
  EngagementInsightCategory,
  EngagementInsightsTarget,
} from '@src/interfaces/engagement'
import { InsightsItem } from './InsightsItem'
import { groupBy } from 'lodash'
import { InsightWithScore } from './hooks/useGetInsightsWithScore'
import { navigateTo } from '@src/actions/RouterActions'
import { InsightDetailsSideBar } from './InsightDetailsSideBar'

interface Props {
  insightsStatus: InsightsWidgetStatus
  insights: InsightWithScore[]
  insightType: EngagementInsightsTarget
  surveyId: number
  surveyRoundId: number
}

export const InsightsWidgetContent = ({
  insightsStatus,
  insights,
  insightType,
  surveyId,
  surveyRoundId,
}: Props) => {
  const [selectedInsight, setSelectedInsight] = useState<InsightWithScore>()
  const groupedInsights = groupBy(insights || [], insight => insight.category.id)
  const shouldRedirect = insightType === 'survey'
  const onInsightClick = (insight: InsightWithScore) => {
    if (shouldRedirect) {
      navigateTo(getLink({ insight, surveyId, surveyRoundId }))
      return
    }
    setSelectedInsight(insight)
  }

  const renderCategory = (category: EngagementInsightCategory) => {
    if (!groupedInsights[category]) {
      return null
    }
    return (
      <Widget p="s-16" width="100%">
        <HStack gap="s-12" align="center">
          <Icon name={getCategoryIconName(category)} />
          <Text variant="heading3">{getCategoryName(category)}</Text>
        </HStack>
        <VStack gap="s-12" mt="s-12">
          {groupedInsights[category].map((insight, i) => (
            <HStack gap="s-8" key={i}>
              <Icon name="Dot" size={12} mt="s-4" />
              <InsightsItem
                insight={insight}
                titleIconName={shouldRedirect ? 'LinkExternalSimple' : undefined}
                onClick={onInsightClick}
                insightType={insightType}
              />
            </HStack>
          ))}
        </VStack>
      </Widget>
    )
  }

  if (insightsStatus !== 'completed') {
    const { title, subtitle } = getStatusText(insightsStatus)
    return (
      <Placeholder>
        {getStatusIndicator(insightsStatus)}
        <Placeholder.Title>{title}</Placeholder.Title>
        <Placeholder.Description>{subtitle}</Placeholder.Description>
      </Placeholder>
    )
  }

  return (
    <Flex
      gap="s-16"
      alignItems={{ all: 'center', lg: 'stretch' }}
      flexDirection={{ all: 'column', lg: 'row' }}
    >
      {renderCategory('strength')}
      {renderCategory('area_of_development')}
      {!!selectedInsight && (
        <InsightDetailsSideBar
          insight={selectedInsight}
          onClose={() => setSelectedInsight(undefined)}
        />
      )}
    </Flex>
  )
}
