import React from 'react'
import { FormattedMessage } from 'react-intl'
import cloneDeep from 'lodash/cloneDeep'
import { connect } from 'lape'
import { Avatar, Box, Button, Item, useToggle, VStack, Widget } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'
import { useGetPerformanceInsightsGroupEligibleEmployees } from '@src/features/AiInsights/Performance/api/insights'
import { PerformanceInsightsGroupFiltersSidebar } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Filters/FiltersSidebar'
import { ExternalSources } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/Extrernal/Sources'
import { InternalSources } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Metrics/Internal/Sources'
import { EligibilityDetails } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/Eligibility/EligibilityDetails'
import { initialFilters } from '@src/features/AiInsights/Performance/components/InsightsGroups/Forms/constants'

interface Props {
  title: React.ReactNode
  actions: (renderProps: {
    label?: React.ReactNode
    onSave: (formData: PerformanceInsightsGroupInterface) => void
  }) => React.ReactNode
}

export const Layout = connect(({ title, actions }: Props) => {
  const { values } = useLapeContext<PerformanceInsightsGroupInterface>()

  const {
    data: eligibility,
    isLoading: isEligibilityLoading,
    refetch: refetchEligibility,
  } = useGetPerformanceInsightsGroupEligibleEmployees(values.id)

  const [isFiltersSidebarOpen, filtersSidebarToggler] = useToggle()
  const [isInternalSourcesSidebarOpen, internalSourcesSidebarToggler] = useToggle()
  const [isExternalSourcesSidebarOpen, externalSourcesSidebarToggler] = useToggle()

  const handleOnSaveFilters = () => {
    refetchEligibility()
    filtersSidebarToggler.off()
  }

  const handleOnClearFilters = () => {
    values.filters = cloneDeep(initialFilters)
  }

  const isDirtyFilters = JSON.stringify(values.filters) !== JSON.stringify(initialFilters)

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={ROUTES.PERFORMANCE.INSIGHTS.INSIGHTS_GROUPS} />
      <PageBody>
        <VStack space="s-16">
          <Widget pb="s-16">
            <VStack>
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="InfoOutline" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <FormattedMessage
                      id="forms.performance.insights.group.general.title"
                      defaultMessage="General"
                    />
                  </Item.Title>
                  <Item.Description>
                    <FormattedMessage
                      id="forms.performance.insights.group.general.description"
                      defaultMessage="Details about this insight group"
                    />
                  </Item.Description>
                </Item.Content>
              </Item>
              <Box px="s-16">
                <LapeNewInput name="name" label="Name" required />
              </Box>
            </VStack>
          </Widget>
          <EligibilityDetails
            employeesCount={eligibility?.count ?? 0}
            insightsGroup={values}
            isLoading={isEligibilityLoading}
            onManageClick={filtersSidebarToggler.on}
          />
          <ExternalSources
            isSidebarOpen={isExternalSourcesSidebarOpen}
            onSidebarOpen={externalSourcesSidebarToggler.on}
            onSidebarClose={externalSourcesSidebarToggler.off}
            actions={actions({
              label: (
                <FormattedMessage
                  id="common.confirm.cta.label"
                  defaultMessage="Confirm"
                />
              ),
              onSave: externalSourcesSidebarToggler.off,
            })}
          />
          <InternalSources
            isSidebarOpen={isInternalSourcesSidebarOpen}
            onSidebarOpen={internalSourcesSidebarToggler.on}
            onSidebarClose={internalSourcesSidebarToggler.off}
            actions={actions({
              label: (
                <FormattedMessage
                  id="common.confirm.cta.label"
                  defaultMessage="Confirm"
                />
              ),
              onSave: internalSourcesSidebarToggler.off,
            })}
          />
        </VStack>
        <PageActions>{actions({ onSave: handleOnSaveFilters })}</PageActions>
      </PageBody>
      <PerformanceInsightsGroupFiltersSidebar
        isOpen={isFiltersSidebarOpen}
        onClose={filtersSidebarToggler.off}
        actions={
          <>
            {isDirtyFilters && (
              <Button variant="secondary" onClick={handleOnClearFilters}>
                <FormattedMessage id="common.cta.labels.clear" defaultMessage="Reset" />
              </Button>
            )}
            {actions({
              label: (
                <FormattedMessage
                  id="forms.actions.save.changes.cta.label"
                  defaultMessage="Save changes"
                />
              ),
              onSave: handleOnSaveFilters,
            })}
          </>
        }
      />
    </PageWrapper>
  )
})
