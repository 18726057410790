import React from 'react'
import { FormattedMessage, FormattedPlural } from 'react-intl'
import {
  Box,
  chain,
  Copyable,
  Flex,
  HStack,
  Spacer,
  Subheader,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { MeetingsSummaryInterface } from '@src/interfaces/meetings'
import Loader from '@components/CommonSC/Loader'
import { SummaryDataPoint } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/Feedback/OneToOnes/Summary/DataPoint'
import { SummaryFormatted } from '@src/features/AiInsights/Performance/components/Summary/KeyInsights'
import { useMeetingsSummary } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/MeetingsSummary/hooks/useMeetingsSummary'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'

interface Props {
  summary: MeetingsSummaryInterface
  managerId: number
}

export const Summary = ({ summary, managerId }: Props) => {
  const { isActionItemsEnabled, isPerformanceRatingEnabled, gradeProps, isLoading } =
    useMeetingsSummary(summary, managerId)

  if (isLoading) {
    return (
      <Widget height={400}>
        <Loader />
      </Widget>
    )
  }

  return (
    <Widget p="s-16" data-testid="meetings_summary_details">
      <VStack space="s-8">
        <VStack>
          <Text variant="heading3" fontWeight={500}>
            {chain(
              <Text>{summary.cycle.name}</Text>,
              <FormattedMessage
                id="one-to-one.meetings.summary.header.title"
                defaultMessage="1:1 summary"
              />,
            )}
          </Text>
          <Text variant="body2" color={Token.color.greyTone50}>
            <FormattedMessage
              id="one-to-one.meetings.summary.header.description"
              defaultMessage="Updated daily until cycle ends"
            />
          </Text>
        </VStack>
        <Flex gap="s-8">
          {isPerformanceRatingEnabled && gradeProps.label && (
            <SummaryDataPoint
              label={
                <FormattedMessage
                  id="one-to-one.meetings.summary.stats.avg.score.label"
                  defaultMessage="Average score"
                />
              }
              value={<Text color={gradeProps.color}>{gradeProps.label}</Text>}
              metric={
                <FormattedPlural
                  value={summary.performance_grade_count}
                  one={
                    <FormattedMessage
                      id="one-to-one.meetings.summary.stats.avg.score.one.description"
                      values={{ count: summary.performance_grade_count }}
                      defaultMessage="based on {count} review"
                    />
                  }
                  other={
                    <FormattedMessage
                      id="one-to-one.meetings.summary.stats.avg.score.other.description"
                      values={{ count: summary.performance_grade_count }}
                      defaultMessage="based on {count} reviews"
                    />
                  }
                />
              }
              flex={1}
            />
          )}
          <SummaryDataPoint
            label={
              <FormattedMessage
                id="one-to-one.meetings.summary.stats.meetings.count.label"
                defaultMessage="Meetings"
              />
            }
            value={<Text>{summary.meetings_count}</Text>}
            metric={
              <FormattedMessage
                id="one-to-one.meetings.summary.stats.meetings.count.description"
                defaultMessage="this cycle"
              />
            }
            flex={1}
          />
          {isActionItemsEnabled && (
            <SummaryDataPoint
              label={
                <FormattedMessage
                  id="one-to-one.meetings.summary.stats.actions.count.label"
                  defaultMessage="Actions"
                />
              }
              value={<Text>{summary.actions_count}</Text>}
              metric={
                <FormattedMessage
                  id="one-to-one.meetings.summary.stats.actions.count.description"
                  defaultMessage="recorded"
                />
              }
              flex={1}
            />
          )}
        </Flex>
      </VStack>
      <Subheader>
        <Subheader.Title>
          <Text variant="emphasis1" color={Token.color.foreground}>
            <FormattedMessage
              id="one-to-one.meetings.summary.notes.title"
              defaultMessage="Notes"
            />
          </Text>
        </Subheader.Title>
      </Subheader>
      {summary.summary && (
        <VStack space="s-8" p="s-16" radius="r16" bg={Token.color.widgetBackground}>
          <HStack align="center">
            <HStack space="s-4" align="center" width="100%">
              <Text variant="body1">
                <FormattedMessage id="common.summary.title" defaultMessage="Summary" />
              </Text>
              <AiIcon />
            </HStack>
            <Spacer />
            <Box>
              <Copyable
                value={summary.summary}
                labelButtonCopy="Copy"
                onCopy={() => {
                  pushNotification({
                    value: 'Summary is copied to clipboard',
                    duration: SUCCESS_DEFAULT_DURATION,
                    type: NotificationTypes.success,
                  })
                }}
                style={{ color: Token.color.greyTone20 }}
              />
            </Box>
          </HStack>
          <SummaryFormatted summary={summary.summary} variant="list" />
        </VStack>
      )}
    </Widget>
  )
}
