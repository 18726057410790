import React, { useEffect } from 'react'
import capitalize from 'lodash/capitalize'
import { Input, InputGroup, Separator } from '@revolut/ui-kit'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useGetProbationTemplates } from '@src/api/probationTemplate'
import { useGetOrganisationSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import {
  ContractorInterface,
  EmployeeContractType,
  EmployeeInterface,
  EmployeeType,
  ProbationTemplateOptionInterface,
} from '@src/interfaces/employees'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useSubsenioritySelector } from '@src/hooks/useSubsenioritySelector'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { ContractTypeOption } from '@src/features/Contracts/ContractTypeOption'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeSenioritySelector from '@src/features/SenioritySelector/LapeSenioritySelector'
import { renderTemplatesSelectorOption } from '@src/pages/Forms/ProbationTemplate/common'
import { PermissionTypes } from '@src/store/auth/types'
import { updateProbationEndDate } from '@src/utils/employees'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { getRoleSpecialisationTitle } from '@src/features/Roles/helpers'

export interface Props {
  data: EmployeeInterface
  enableJobTitle: boolean
  type: EmployeeType
}

export const WorkSection = ({ data, enableJobTitle, type }: Props) => {
  const isCommercial = useIsCommercial()
  const specialisationsEnabled = useIsSpecialisationsEnabled()

  const { initialValues, values } = useLapeContext<
    EmployeeInterface | ContractorInterface
  >()

  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const subsenioritySelector = useSubsenioritySelector<
    EmployeeInterface | ContractorInterface
  >(values, initialValues)

  const { data: templates } = useGetProbationTemplates({
    seniority_id: values.seniority?.id,
    contract_type: values.contract_type?.id,
    employee_type: type,
    specialisation_id: values.specialisation?.id,
    team_id: values.team?.id,
    location_id: values.location?.id,
    entity_id: values.entity?.id,
  })

  const hasEditablePositionDetails = data.field_options.permissions?.includes(
    PermissionTypes.HasEditablePositionDetails,
  )

  useEffect(() => {
    if (!values.probation_template && templates) {
      values.probation_template = templates?.find(option => option.is_eligible)
    }
  }, [templates])

  return (
    <>
      <NewStepperTitle title="Work" />
      <InputGroup>
        <LapeRadioSelectInput<EmployeeContractType>
          name="contract_type"
          label="Contract type"
          selector={selectorKeys.contract_types}
        >
          {option => <ContractTypeOption option={option} />}
        </LapeRadioSelectInput>
        {type && (
          <Input
            description="This is determined by the contract type. Internal employees have access to our systems whilst external employees cannot access internal systems, unless specified otherwise."
            disabled
            label="Employee type"
            value={capitalize(type)}
          />
        )}
        <Separator />
        <LapeRadioSelectInput
          name="specialisation"
          label={getRoleSpecialisationTitle(specialisationsEnabled)}
          selector={selectorKeys.specialisations}
          clearable
        />
        {type === 'internal' && (
          <>
            <LapeSenioritySelector
              clearable
              name="seniority"
              label="Seniority"
              specialisationId={values.specialisation?.id || null}
              required
            />
            {!!organisationSettings?.enable_multiple_levels_per_seniority && (
              <LapeRadioSelectInput
                name="specialisation_seniority_sublevel"
                label="Seniority Level"
                selector={subsenioritySelector}
              />
            )}
            {enableJobTitle &&
              (isCommercial ? (
                <LapeNewInput
                  name="job_title"
                  label="Job title"
                  description="Enter a job title"
                />
              ) : (
                <LapeRadioSelectInput<{ id: string; name?: string }>
                  name="job_title"
                  label="Job title"
                  selector={selectorKeys.all_job_titles}
                  value={
                    'job_title' in values
                      ? {
                          id: values.job_title,
                        }
                      : undefined
                  }
                  onChange={selector => {
                    if (selector?.name && 'job_title' in values) {
                      values.job_title = selector.name
                    }
                  }}
                  clearable={false}
                />
              ))}
          </>
        )}
        <Separator />
        {hasEditablePositionDetails && (
          <>
            <LapeRadioSelectInput
              name="location"
              label="Location"
              selector={selectorKeys.location}
            />
            <LapeRadioSelectInput
              name="entity"
              label="Entity"
              selector={selectorKeys.entity}
            />
          </>
        )}
        <LapeDatePickerInput
          label="Start date"
          message="According to the contract. This is provisional until the employee starts."
          name="joining_date_time"
          onAfterChange={value => {
            if (value != null && values.probation_template?.amount_of_months) {
              updateProbationEndDate(
                value,
                values.probation_template.amount_of_months,
                values,
              )
            }
          }}
          required
        />
        {performanceSettings?.enable_probation && (
          <>
            <LapeRadioSelectInput
              clearable
              disableOptionRule={option => !option.value.is_eligible}
              label="Select probation template"
              name="probation_template"
              onAfterChange={(option: ProbationTemplateOptionInterface | null) => {
                if (option && values.joining_date_time && option.amount_of_months) {
                  updateProbationEndDate(
                    values.joining_date_time,
                    option.amount_of_months,
                    values,
                  )
                }
              }}
              options={templates?.map(item => ({
                label: item.name,
                value: item,
              }))}
            >
              {renderTemplatesSelectorOption}
            </LapeRadioSelectInput>
            <LapeDatePickerInput
              disabled={Boolean(values.probation_template)}
              disabledDays={{
                before: new Date(values.joining_date_time),
              }}
              label="Probation end date"
              message="Mandatory for internal employees only, according to the contract and provisional until the employee starts. Not applicable for external employees."
              name="end_of_probation_date_time"
              required
            />
          </>
        )}
      </InputGroup>
    </>
  )
}
