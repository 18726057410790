import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, chain, TabBar, Text, Token, VStack } from '@revolut/ui-kit'

import {
  engagementAnswersTableRequests,
  useGetEngagementSurvey,
} from '@src/api/engagement'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import TableLoader from '@src/components/TableV2/TableLoader'
import { useTable } from '@src/components/TableV2/hooks'
import { isEmptyAnswerTextColName } from '@src/constants/columns/engagementResults'
import { ROUTES } from '@src/constants/routes'
import { useGetSurveyResultsItemDetails } from '@src/features/Engagement/api/analytics'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { InsightsWidget } from '@src/pages/Performance/Engagement/components/SurveyResults/InsightsWidget'
import { useQuery } from '@src/utils/queryParamsHooks'
import { toIdAndName } from '@src/utils/toIdAndName'
import { useSurveyRoundSelector } from '../../Rounds/Selector/hooks/useSurveyRoundSelector'
import { ResultsWidget } from '../ResultsWidget'
import { useHeatmapFilters } from '../ResultsWidget/hooks/useHeatmapFilters'
import { Comments } from './Comments'
import { PieChartWidget } from './PieChartWidget'
import { TimeSeriesChartWidget } from './TimeSeriesChartWidget'
import {
  PageQueryParams,
  RouteParams,
  useDetailsCommentsFilterParamsFromQuery,
  useDetailsFilterParamsFromQuery,
} from './common'

export const EngagementSurveyResultsItemDetails = () => {
  const filtersParams = useDetailsFilterParamsFromQuery()
  const params = useParams<RouteParams>()
  const { query } = useQuery<PageQueryParams>()

  const [subtab, setSubtab] = useState<'overview' | 'comments'>('overview')

  const isQuestionDetailsPage = params.itemType === 'question'

  const { data: itemDetailsData, isLoading: isLoadingItemDetailsData } =
    useGetSurveyResultsItemDetails(
      params.id,
      params.itemType,
      params.itemId,
      filtersParams,
    )

  const timeSeriesContainerHeight = 320
  const timeSeriesYPaddingPx = 16
  const timeSeriesTitleHeight = 34
  const containerHeight =
    timeSeriesContainerHeight + timeSeriesTitleHeight + timeSeriesYPaddingPx * 2

  const commentsTableInitialFilters = useDetailsCommentsFilterParamsFromQuery()
  const commentsTable = useTable(
    engagementAnswersTableRequests,
    [
      ...commentsTableInitialFilters,
      ...(params.itemId
        ? [
            {
              columnName: isQuestionDetailsPage ? 'question__id' : 'driver__id',
              filters: [toIdAndName(params.itemId)],
              nonResettable: true,
            },
          ]
        : []),
      {
        columnName: isEmptyAnswerTextColName,
        filters: [toIdAndName('false')],
        nonResettable: true,
      },
    ],
    undefined,
  )

  const surveyId = params.id ? Number(params.id) : undefined
  const roundId = query.survey_round_id ? Number(query.survey_round_id) : undefined

  const {
    data: survey,
    refetch: refetchSurvey,
    isLoading: isLoadingSurvey,
  } = useGetEngagementSurvey(surveyId)

  const timelineFilter = useSurveyRoundSelector({
    forcedSurveyOptionId: surveyId,
    forcedRoundOptionId: roundId,
    enabled: Boolean(survey?.id && !isLoadingSurvey),
  })

  const heatmapFilters = useHeatmapFilters(surveyId)
  const showInsightsWidget =
    filtersParams.survey_round_id && subtab === 'overview' && !isQuestionDetailsPage

  return (
    <PageWrapper>
      <PageHeader
        title={
          itemDetailsData ? (
            <PageHeader.Title
              title={itemDetailsData.name}
              useIcon={isQuestionDetailsPage ? 'Chat' : 'Folder'}
            />
          ) : undefined
        }
        backUrl={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD}
        isLoading={isLoadingItemDetailsData}
      />

      <PageBody maxWidth={Token.breakpoint.xxl}>
        <TwoColumnsLayout
          leftMinWidth={550}
          left={
            <Box height={containerHeight}>
              <PieChartWidget
                data={itemDetailsData}
                isLoading={isLoadingItemDetailsData}
                containerHeight={containerHeight}
                commentsCount={commentsTable.count}
                isCommentsLoading={commentsTable.loading}
              />
            </Box>
          }
          rightMinWidth={400}
          right={
            <TimeSeriesChartWidget
              yPadding={timeSeriesYPaddingPx}
              titleHeight={timeSeriesTitleHeight}
              containerHeight={timeSeriesContainerHeight}
            />
          }
        />
        <VStack mt="s-24" space="s-24">
          {params.itemType === 'category' && (
            <TabBar
              value={subtab}
              onChange={newSubtab => setSubtab(newSubtab || 'overview')}
            >
              <TabBar.Item to="overview">Overview</TabBar.Item>
              <TabBar.Item to="comments">
                {chain(<Text>Comments</Text>, commentsTable.count)}
              </TabBar.Item>
            </TabBar>
          )}
          {showInsightsWidget && (
            <InsightsWidget
              surveyRoundId={Number(filtersParams.survey_round_id)}
              surveyId={Number(params.id)}
              insightType="driver"
              categoryId={Number(params.itemId)}
              datesRange={{
                dateFrom: timelineFilter.dateFrom,
                dateTo: timelineFilter.dateTo,
              }}
            />
          )}
          {params.itemType === 'question' && <Comments table={commentsTable} />}
          {params.itemType === 'category' && subtab === 'overview' && (
            <>
              {survey ? (
                <ResultsWidget
                  itemsToAnalyse="questions"
                  survey={survey}
                  refetchSurvey={refetchSurvey}
                  timelineFilter={timelineFilter}
                  heatmapFilters={heatmapFilters}
                  showItemTypeSelector={false}
                  initialFilters={
                    params.itemId
                      ? [{ columnName: 'driver', filters: [toIdAndName(params.itemId)] }]
                      : undefined
                  }
                />
              ) : (
                <TableLoader rowHeight="large" />
              )}
            </>
          )}
          {params.itemType === 'category' && subtab === 'comments' && (
            <Comments table={commentsTable} withTitle={false} />
          )}
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}
