import React from 'react'
import { useParams } from 'react-router-dom'

import { StatNavigation } from '@src/components/StatNavigation/StatNavigation'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { OrderingDataType } from '@src/interfaces/ordering'
import { useGetResourcesStats } from '@src/pages/Forms/DepartmentForm/Resources/api'

export const getDepartmentResourcesAnalyticsInitialFilter = (id: number) => [
  {
    filters: [
      {
        name: OrderingDataType.Department,
        id: OrderingDataType.Department,
      },
    ],
    columnName: 'parent_type',
    nonResettable: true,
  },
  {
    filters: [{ name: '', id }],
    columnName: 'parent_id',
    nonResettable: true,
  },
]

export const DepartmentResourcesStatsNavigation = () => {
  const params = useParams<{ id?: string }>()

  return (
    <StatNavigation
      api={useGetResourcesStats}
      config={[
        {
          key: 'total_count',
          label: 'Total',
          to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RESOURCES.TOTAL, params),
        },
        {
          key: 'analytics_count',
          label: 'Analytics',
          to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RESOURCES.ANALYTICS, params),
        },
      ]}
    />
  )
}
