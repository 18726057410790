import React, { useEffect, useMemo, useState } from 'react'
import SideBar from '@components/SideBar/SideBar'
import FiltersSidebarItem, {
  FiltersSidebarItemInterface,
} from '@components/FiltersSidebar/FiltersSidebarItem'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'
import { Button, VStack, Side } from '@revolut/ui-kit'

type Props = {
  items: FiltersSidebarItemInterface[]
  filters: FilterByInterface[]
  onFilter: (
    filters: FilterByInterface[],
    resetDefaultFilters?: boolean,
    forceResetOnFilter?: boolean,
  ) => void
  open: boolean
  useLayout?: boolean
  onClose: () => void
}

interface ContentProps extends Omit<Props, 'open'> {}

export const getAppliedFiltersCount = (
  allFilters: FilterByInterface[],
  items: FiltersSidebarItemInterface[],
) => {
  const fields = new Set()
  items.forEach(item => {
    fields.add(item.field)
  })

  return allFilters.reduce(
    (count, filter) =>
      filter.filters.length && fields.has(filter.columnName) ? count + 1 : count,
    0,
  )
}

const FilterSidebarContent = ({ items, filters, onFilter, onClose }: ContentProps) => {
  const [appliedFilters, setAppliedFilters] = useState<Map<string, FilterOption[]>>(
    new Map(),
  )

  useEffect(() => {
    const updatedFilters = new Map()
    const fields = new Set()
    items.forEach(item => {
      fields.add(item.field)
    })
    filters?.forEach(item => {
      if (item.filters?.length && fields.has(item.columnName)) {
        updatedFilters.set(item.columnName, item.filters)
      }
    })
    setAppliedFilters(updatedFilters)
  }, [filters])

  const appliedFiltersCount = useMemo(() => {
    let count = 0
    for (let f of appliedFilters.values()) {
      if (f.length) {
        count += 1
      }
    }

    return count
  }, [appliedFilters])

  const onApplyFilters = () => {
    let normalizedFilters: FilterByInterface[] = []

    for (let [columnName, val] of appliedFilters.entries()) {
      normalizedFilters.push({
        columnName,
        filters: val,
      })
    }

    onFilter(normalizedFilters)
    onClose()
  }

  const onResetFilters = () => {
    setAppliedFilters(new Map())
    onFilter([], true, true)
    onClose()
  }

  return (
    <>
      <VStack gap="s-8">
        {items.map(item => (
          <FiltersSidebarItem
            key={item.field}
            label={item.label}
            icon={item.icon}
            selector={item.selector}
            value={appliedFilters.get(item.field) || []}
            onChange={value => {
              const updatedFilters = new Map(appliedFilters)
              updatedFilters.set(item.field, value)
              setAppliedFilters(updatedFilters)
            }}
            filterType={item.filterType}
            renderOption={item.renderOption}
            numberRangeFilterUnitLabel={item.numberRangeFilterUnitLabel}
            showInPopup={item.showInPopup}
          />
        ))}
      </VStack>
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onResetFilters}>
          Reset filters
        </Button>
        <Button elevated onClick={onApplyFilters}>
          Apply filters ({appliedFiltersCount})
        </Button>
      </Side.Actions>
    </>
  )
}

const FiltersSidebar = ({
  open,
  onClose,
  items,
  filters,
  useLayout = false,
  onFilter,
}: Props) => {
  return (
    <SideBar title="Filters" isOpen={open} onClose={onClose} useLayout={useLayout}>
      <ErrorBoundary>
        <FilterSidebarContent
          items={items}
          filters={filters}
          onFilter={onFilter}
          onClose={onClose}
        />
      </ErrorBoundary>
    </SideBar>
  )
}

export default FiltersSidebar
