import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Avatar, AvatarSize, HStack, Spacer, Text, VStack } from '@revolut/ui-kit'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { getInitials } from '@src/utils/employees'
import { PerformanceRating } from '@src/interfaces/performance'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'

interface Props extends TalentSkillRatingInterface {}

export const RatingAssessors = ({
  cycle,
  rating,
  lm_rating,
  fm_rating,
  self_rating,
  employee,
  line_manager,
  functional_manager,
}: Props) => {
  const hasAssessorsDetails = !!lm_rating || !!fm_rating || !!self_rating

  return (
    <VStack space="s-8">
      {rating && (
        <HStack align="center" width="100%">
          <Text fontSize={14} fontWeight={500}>
            <FormattedMessage
              id="performance.analytics.skills.rating.cycle.name"
              values={{
                name: cycle?.name || 'Current cycle',
              }}
              defaultMessage="{name}"
            />
          </Text>
          <Spacer />
          <Text fontSize={12} fontWeight={600}>
            {PerformanceRatingTitle[rating]}
          </Text>
        </HStack>
      )}
      {hasAssessorsDetails && (
        <VStack space="s-4">
          {lm_rating && line_manager && (
            <RatingAssessor rating={lm_rating} relation="LM" employee={line_manager} />
          )}
          {fm_rating && functional_manager && (
            <RatingAssessor
              rating={fm_rating}
              relation="FM"
              employee={functional_manager}
            />
          )}
          {self_rating && (
            <RatingAssessor rating={self_rating} relation="Self" employee={employee} />
          )}
        </VStack>
      )}
    </VStack>
  )
}

interface AssessorProps {
  rating: PerformanceRating
  relation: string
  employee: Pick<EmployeeOptionInterface, 'id' | 'full_name' | 'status' | 'avatar'>
}

const RatingAssessor = ({ rating, relation, employee }: AssessorProps) => {
  return (
    <HStack align="center" space="s-4" width="100%">
      {employee.full_name && (
        <Avatar image={employee.avatar} uuid={String(employee.id)} size={AvatarSize.S_20}>
          <Text>{!employee.avatar && getInitials(employee.full_name)}</Text>
        </Avatar>
      )}
      <Text fontSize={12}>
        <FormattedMessage
          id="performance.analytics.skills.rating.assesor.name"
          values={{
            name: employee.full_name,
            relation,
          }}
          defaultMessage="{name} ({relation})"
        />
      </Text>
      <Spacer />
      <Text fontSize={12}>{PerformanceRatingTitle[rating]}</Text>
    </HStack>
  )
}
