import React, { useEffect, useState } from 'react'
import { InputGroup, Subheader } from '@revolut/ui-kit'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { useGetOrganisationSettings } from '@src/api/settings'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface } from '@src/interfaces/roles'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { selectorKeys } from '@src/constants/api'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { OptionInterface } from '@src/interfaces/selectors'
import { locationsRequests } from '@src/api/locations'
import { rolesRequests } from '@src/api/roles'
import NewStepperSectionCustomFields from '@src/components/Stepper/NewStepperSectionCustomFields'
import { SectionOptions } from '@src/interfaces/customFields'

export const GeneralInfoRoleFormContent = () => {
  const { data: settings } = useGetOrganisationSettings()
  const { values } = useLapeContext<RoleInterface>()
  const canAdd = useHasGlobalPermission(PermissionTypes.AddRoles)

  const user = useSelector(selectUser)
  const [fetchingLocations, setFetchingLocations] = useState(false)
  const [locations, setLocations] = useState<OptionInterface[]>([])

  useEffect(() => {
    if (!values.role_manager && user) {
      values.role_manager = { id: user.id, full_name: user.full_name }
    }

    if (!values.id && !values.goals?.length) {
      values.goals = []
    }

    if (!values.function) {
      setFunction()
    }
  }, [])

  useEffect(() => {
    if (settings?.enable_location_limitations) {
      fetchLocations()
    }
  }, [settings?.enable_location_limitations])

  const fetchLocations = async () => {
    try {
      setFetchingLocations(true)
      const result = await locationsRequests.getItems({})
      if (result?.data?.results) {
        setLocations(
          result?.data?.results.map(location => ({
            name: location.location_name,
            id: location.id,
          })),
        )
      }
    } finally {
      setFetchingLocations(false)
    }
  }

  const setFunction = async () => {
    if (user.position) {
      const result = await rolesRequests.getItem(user.position.id)
      if (result.data) {
        values.function = {
          name: result?.data?.function?.name,
          id: result?.data?.function?.id,
          function_type: result?.data?.function?.function_type,
        }
      }
    }
  }

  const canChange = !values.id
    ? canAdd
    : Boolean(values.field_options?.actions?.includes?.(EntityPermissions.Change))

  return (
    <NewStepperSectionCustomFields sectionId={SectionOptions.Roles} roleId={values.id}>
      <Subheader>Role details</Subheader>
      <InputGroup>
        <LapeNewInput label="Role name" name="name" required />
        <LapeRadioSelectInput
          name="function"
          label="Function"
          disabled={!canChange}
          selector={selectorKeys.functions}
        />
        <LapeRadioSelectInput
          name="role_manager"
          label="Role Owner"
          disabled={!canChange}
          selector={selectorKeys.employee}
        />
        {settings?.enable_location_limitations ? (
          <>
            <LapeNewMultiSelect
              name="locations"
              placeholder="Location"
              options={locations?.map(location => ({
                label: location.name,
                value: location,
              }))}
              disabled={fetchingLocations || !canChange}
            />
          </>
        ) : null}
      </InputGroup>
      <Subheader>Role description</Subheader>
      <InputGroup>
        <LapeNewTextArea
          name="role_description"
          label="Role mission"
          disabled={!canChange}
          rows={3}
        />
        <LapeMultiInput title="Enter role goals" name="role_goals" />
      </InputGroup>
    </NewStepperSectionCustomFields>
  )
}
