import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Color, Placeholder, VStack } from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { SkillRatingsDistributionInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import {
  BAR_ITEM_HEIGHT,
  Row,
} from '@src/features/ReviewCycle/Analytics/Skills/Charts/RatingDistributionGridBarChart/Row'
import { Legend } from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/Legend'
import { GridBarChartSkeleton } from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/BarChart'

interface Props {
  data: SkillRatingsDistributionInterface[]
  dataKeys: BaseOption<string>[]
  dataColors: Color[]
  isLoading: boolean
  containerHeight?: number
  onRowClick: (id: number) => void
}

export const SkillRatingsDistributionBarChart = ({
  data,
  dataKeys,
  dataColors,
  isLoading,
  containerHeight,
  onRowClick,
}: Props) => {
  const [prevRowsCount, setPrevRowsCount] = useState<number>(data.length)

  useEffect(() => {
    if (isLoading) {
      return
    }

    setPrevRowsCount(data.length)
  }, [data.length, isLoading])

  if (isLoading) {
    return (
      <GridBarChartSkeleton
        chartHeight={containerHeight}
        rowHeight={BAR_ITEM_HEIGHT}
        rowsCount={prevRowsCount > 0 ? prevRowsCount : undefined}
      />
    )
  }

  if (!data.length) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: `https://assets.revolut.com/assets/3d-images-v2/3D299.png`,
            '2x': `https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png`,
            '3x': `https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png`,
          }}
        />
        <Placeholder.Title>
          <FormattedMessage
            id="performance.analytics.skills.ratings.distribution.chart.empty.data.title"
            defaultMessage="There is no skills ratings distribution data available"
          />
        </Placeholder.Title>
      </Placeholder>
    )
  }

  return (
    <VStack space="s-16" width="100%">
      <Legend dataKeys={dataKeys} colors={dataColors} />
      {data.map(datum => {
        return (
          <Row
            key={datum.skill.id}
            data={datum}
            dataKeys={dataKeys}
            colors={dataColors}
            maxCount={Math.max(...data.map(({ ratings_total }) => ratings_total))}
            onRowClick={onRowClick}
          />
        )
      })}
    </VStack>
  )
}
