import React from 'react'
import { AvatarSkeleton, HStack, Spacer, Text, TextSkeleton } from '@revolut/ui-kit'

interface Props {
  title: React.ReactNode
  avatar: React.ReactNode
  side?: React.ReactNode
}

export const SourceItem = ({ title, avatar, side }: Props) => (
  <HStack space="s-16" align="center">
    {avatar}
    <Text fontWeight={500}>{title}</Text>
    <Spacer />
    {side && side}
  </HStack>
)

export const SourceItemSkeleton = () => (
  <HStack space="s-16" px="s-16" pt="s-16" align="center">
    <AvatarSkeleton />
    <TextSkeleton width={100} />
    <Spacer />
    <TextSkeleton width={100} />
  </HStack>
)
