import React from 'react'
import { Button, Side, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import {
  specialisationsRequestsNew,
  specialisationsRequestsV2,
} from '@src/api/specialisations'
import SideBar from '@src/components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useMountSpecialisationForm } from '@src/pages/Forms/SpecialisationForm/General/useMountSpecialisationForm'
import { ParentRoleField } from '@src/pages/Forms/SpecialisationForm/General/ParentRoleField'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import SenioritiesRange from '@src/pages/Forms/RoleForm/CompetencyMatrix/SenioritiesRange'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useSeniorityRange } from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/useSeniorityRange'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'

interface CreateNewSpecialisationProps {
  onClose: VoidFunction
  onSubmit: (specialisation: SpecialisationInterface) => void
}

const CreateNewSpecialisation = ({ onClose, onSubmit }: CreateNewSpecialisationProps) => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const { seniorities, onSeniorityRangeIncreased, onSeniorityRangeDecreased } =
    useSeniorityRange({
      onFetchRoleSuccess: role => {
        values.seniority_max = role.seniority_max
        values.seniority_min = role.seniority_min
      },
    })
  useMountSpecialisationForm()
  return (
    <>
      <Widget p="s-16">
        <VStack gap="s-12">
          <Text variant="emphasis2" color={Token.color.greyTone50}>
            Specialisation details
          </Text>
          <VStack gap="s-16">
            <LapeNewInput label="Specialisation name" name="name" required />
            <ParentRoleField />
            <SenioritiesRange
              mb="s-0"
              seniorities={seniorities}
              seniorityMaxValue={values.seniority_max}
              seniorityMinValue={values.seniority_min}
              onChangeMax={val => {
                values.seniority_max = val
                onSeniorityRangeIncreased(val)
              }}
              onChangeMin={val => {
                values.seniority_min = val
                onSeniorityRangeDecreased(val)
              }}
            />
            <LapeRadioSelectInput
              name="owner"
              label="Specialisation Owner"
              selector={selectorKeys.employee}
              optional
              clearable
            />
          </VStack>
        </VStack>
      </Widget>
      <Side.Actions horizontal>
        <Button onClick={onClose}>Cancel</Button>
        <NewSaveButtonWithPopup<SpecialisationInterface>
          hideWhenNoChanges={false}
          noPopup
          useValidator
          onAfterSubmit={res => {
            onSubmit(res)
            onClose()
          }}
        >
          Submit
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
}

export const CreateNewSpecialisationSidebar = ({
  onClose,
  onSubmit,
}: CreateNewSpecialisationProps) => {
  const competencyMatrixV2 = useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2)

  return (
    <SideBar isOpen title="Create Specialisation" onClose={onClose}>
      <Form
        api={competencyMatrixV2 ? specialisationsRequestsV2 : specialisationsRequestsNew}
        disableLocalStorageCaching
      >
        <CreateNewSpecialisation onClose={onClose} onSubmit={onSubmit} />
      </Form>
    </SideBar>
  )
}
