import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useParams } from 'react-router-dom'
import { Flex } from '@revolut/ui-kit'

import { PageHeader } from '@components/Page/Header/PageHeader'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'
import { useEmployeeRecordToPrimaryContractEnabled } from '@src/features/EmployeeProfile/hooks/featureFlags'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { DynamicGroupIDs } from '@src/interfaces/customFields'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'

import {
  canViewContracts,
  canViewDiversity,
  canViewInbox,
  canViewKeyPerson,
  canViewPersonal,
  canViewRightToWork,
  canViewTermination,
  hasOffboardingV2Enabled,
} from '../Preview/ProfileSummary/common'
import EmployeeAbout from './About'
import ActionItems from './ActionItems'
import EmployeeBio from './Bio'
import ChangePassword from './ChangePassword/ChangePassword'
import EmployeeContactInfo from './ContactInfo'
import { ContractsTable } from './ContractsTable'
import EmployeeCorrespondenceAddress from './CorrespondenceAddress'
import EmployeeDiversity from './Diversity'
import EmployeeEmergencyContacts from './EmergencyContacts'
import EmployeeIdentification from './Identification'
import { KeyPersonResponsibilities } from './KeyPersonResponsibilities'
import { Changelog } from './KeyPersonResponsibilities/Changelog'
import EmployeeLaptopDelivery from './LaptopDelivery'
import { NameAndWorkDetails } from './NameAndWorkDetails'
import Notes from './Notes'
import Organisation from './Organisation'
import EmployeePip from './Pip'
import Position from './Position'
import { RightToWork as EmployeeRightToWork } from './RightToWork'
import EmployeeTermination, { TerminationProps } from './Termination/General'
import LegacyEmployeeTermination from './Termination/LegacyGeneral'
import { WorkAndOrgDetails } from './WorkAndOrgDetails'

export interface EmployeeGeneralProps extends TerminationProps {
  data: EmployeeInterface
  dynamicGroups: DynamicGroupIDs
  refreshData: () => void
}

export const General = (props: EmployeeGeneralProps) => {
  const { data } = props
  const params = useParams<{ id: string }>()

  const user = useSelector(selectUser)
  const isCommercial = useIsCommercial()
  const employeeRecordToPrimaryContractEnabled =
    useEmployeeRecordToPrimaryContractEnabled()
  const isThisUser = data?.id && data.id === user?.id

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, params)

  const routes = [
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.NAME,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NAME, params),
      title: employeeRecordToPrimaryContractEnabled ? 'Work' : 'Name',
      component: NameAndWorkDetails,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION, params),
      title: 'Position',
      component: Position,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION, params),
      title: 'Organisation',
      component: Organisation,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.COMPENSATION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.COMPENSATION, params),
      title: 'Compensation',
      component: WorkAndOrgDetails,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES, params),
      title: 'Notes',
      component: Notes,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.BIO,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.BIO, params),
      title: 'Bio',
      component: EmployeeBio,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO, params),
      title: 'Contact info',
      component: EmployeeContactInfo,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS, params),
      title: 'Emergency contact',
      component: EmployeeEmergencyContacts,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY, params),
      title: 'Laptop delivery',
      component: EmployeeLaptopDelivery,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION, params),
      title: 'Identification details',
      component: EmployeeIdentification,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS, params),
      title: 'Correspondence address',
      component: EmployeeCorrespondenceAddress,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT, params),
      title: 'About me',
      component: EmployeeAbout,
      visibilityCheck: canViewPersonal(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT, params),
      title: 'Contracts',
      component: ContractsTable,
      visibilityCheck: canViewContracts(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK, params),
      title: 'Right To Work',
      component: EmployeeRightToWork,
      visibilityCheck: canViewRightToWork(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITIES,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITIES, params),
      title: 'Responsibilities',
      component: KeyPersonResponsibilities,
      visibilityCheck: canViewKeyPerson(data, !isCommercial),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITY_CHANGELOG,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITY_CHANGELOG,
        params,
      ),
      title: 'Responsibilities',
      component: Changelog,
      visibilityCheck: canViewKeyPerson(data, !isCommercial),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY, params),
      title: 'Diversity',
      component: EmployeeDiversity,
      visibilityCheck: canViewDiversity(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION, params),
      title: 'Employee termination',
      component: LegacyEmployeeTermination,
      visibilityCheck: canViewTermination(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.CHANGE_PASSWORD,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CHANGE_PASSWORD, params),
      title: 'Change password',
      component: ChangePassword,
      visibilityCheck: isThisUser,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2, params),
      title: 'Employee termination',
      component: EmployeeTermination,
      visibilityCheck: canViewTermination(data) && hasOffboardingV2Enabled(data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.PIP,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.PIP, params),
      title: 'Start PIP',
      component: EmployeePip,
      visibilityCheck: true,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.GENERAL.ACTION_ITEMS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ACTION_ITEMS, params),
      title: 'Action items',
      component: ActionItems,
      visibilityCheck: canViewInbox(data),
    },
  ]

  const filteredRoutes = routes
    .filter(route => (route.visibilityCheck !== undefined ? route.visibilityCheck : true))
    .map(({ visibilityCheck, ...tab }) => tab)

  return (
    <Flex flexDirection="column" flex={1} minHeight={0}>
      <Switch>
        {filteredRoutes.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <PageHeader
              title={tab.title}
              subtitle={
                <UserWithAvatar {...data} status={data.status.id as IdStatuses} asText />
              }
              backUrl={backUrl}
            />

            <tab.component {...props} />
          </Route>
        ))}
        <InternalRedirect to={backUrl} />
      </Switch>
    </Flex>
  )
}
