import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { useFormObserver } from '../FormObserverProvider'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { ReviewCyclesInterface, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { omit, pick } from 'lodash'
import { DeepPartial } from 'redux'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { getMetricRelevantFormData } from '../../../helpers'
import { PerformanceSelector } from '@src/interfaces/performance'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

export const useAddMetric = ({
  reviewCycles,
  values,
}: {
  values: GoalsInterface & { kpis: DeepPartial<GoalsInterface['kpis']> }
  reviewCycles: ReviewCyclesInterface[] | undefined
}) => {
  const isCommercial = useIsCommercial()
  const { getFormById } = useFormObserver()
  const { getEntityProps } = useOrgEntity()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle

  const base = {
    ...getEntityProps(),
    ...(values.content_type?.model === 'employees'
      ? { is_employee: true, employee: values.content_object }
      : {}),
    tempId: Date.now(), // need a number value before metrics are submitted and have actual id. Used for targeting the form for copying data
    is_company: values.is_company,
    goal: { id: values.id },
    owner: values.owner,
  }

  const getEmptyMetric = (
    initReviewCycle?: ReviewCyclesInterface,
    initEmployeeCycle?: PerformanceSelector,
  ) => {
    return {
      ...base,
      targets: [
        initEmployeeCycle
          ? { employee_cycle: initEmployeeCycle }
          : { review_cycle: initReviewCycle },
      ],
    }
  }

  const getNextMetric = ({ copyFromId }: { copyFromId?: number }) => {
    // when multiple goals enabled we have option to duplicate goal in the same cycle
    // need to grab correct data for it
    let formID = values.kpis.at(-1)?.id || values.kpis.at(-1)?.tempId
    const targetForm = getFormById(formID)
    let copyValues: GoalKpiDetails | KpiInterface | undefined =
      targetForm?.().form.values || values.kpis.at(-1)

    if (copyFromId) {
      const metric = values.kpis.find(
        kpi => kpi.id === copyFromId || kpi.tempId === copyFromId,
      )
      if (metric) {
        copyValues = metric
      }
    }

    const targetsData = copyValues?.targets?.at(0)

    const notSelectedCycles = reviewCycles
      ?.filter(
        cycle =>
          cycle.status !== ReviewCycleStatus.closed &&
          !values.kpis.some(kpi => kpi.targets.at(0)?.review_cycle?.id === cycle.id),
      )
      .sort((a, b) => Number(b.offset) - Number(a.offset))

    const nextMetricCycle = copyFromId
      ? targetsData?.review_cycle
      : notSelectedCycles?.find(
          cycle =>
            Number(cycle.offset) <
            Number(copyValues?.targets?.at(0)?.review_cycle?.offset),
        ) ||
        notSelectedCycles?.at(0) ||
        isMultipleGoalsTargetsEnabled
      ? reviewCycles?.filter(cycle => cycle.status !== ReviewCycleStatus.closed).at(0)
      : undefined

    const copyied = copyValues
      ? omit(
          copyValues,
          'id',
          'targets',
          'rejection_reason',
          'target_epics',
          isCommercial && copyValues?.update_type === UpdateTypes.sql
            ? 'sql_query_db'
            : 'connection',
        )
      : {}

    const targetEpics = copyValues?.target_epics?.at(0)
      ? [
          {
            ...pick(copyValues.target_epics.at(0), 'epics'),
            review_cycle: nextMetricCycle,
          },
        ]
      : undefined

    const nextMetric: DeepPartial<GoalKpiDetails> = {
      ...base,
      ...copyied,
      tempId: Date.now(), // need to override the current tempID
      targets: [
        {
          ...pick(targetsData, 'kpi_goal'),
          initial_value: targetsData?.target,
          target: undefined,
          review_cycle: nextMetricCycle,
        },
      ],
      target_epics: targetEpics,
    }
    return getMetricRelevantFormData(nextMetric)
  }

  return { getNextMetric, getEmptyMetric }
}
