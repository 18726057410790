import React from 'react'
import { DataPoint, Text } from '@revolut/ui-kit'
import { metricWidgetStylesProps } from '@src/features/AiInsights/Performance/components/Metrics/common/styles'

interface Props {
  label: React.ReactNode
  value: React.ReactNode
}

export const MetricHighlight = ({ label, value }: Props) => (
  <DataPoint p="s-16" width="100%" {...metricWidgetStylesProps}>
    <DataPoint.Label textAlign="center">
      <Text fontSize={16}>{label}</Text>
    </DataPoint.Label>
    <DataPoint.Value textAlign="center">{value}</DataPoint.Value>
  </DataPoint>
)
