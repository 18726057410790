import React from 'react'
import {
  Avatar,
  Carousel,
  Ellipsis,
  LineClamp,
  StatusWidget,
  Tag,
  Text,
  Tile,
  TileSkeleton,
  Token,
  Widget,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { getEmployeeRewardsBenefits } from '@src/api/rewards'
import { ROUTES } from '@src/constants/routes'
import { getBenefitStatusColor } from '@src/constants/columns/rewards'
import { useTable } from '@components/TableV2/hooks'
import { pathToUrl } from '@src/utils/router'
import { getBenefitIcon, getBenefitStatusText } from './helpers'
import { ErrorWidget } from './ErrorWidget'
import { getMessageFromApiError } from '@src/store/notifications/actions'

type Props = {
  id: number
}

const TILE_SIZE = 180
const TILE_SIZE_PROPS = { width: TILE_SIZE, height: TILE_SIZE }

export const BenefitsWidget = ({ id }: Props) => {
  const table = useTable(
    { getItems: getEmployeeRewardsBenefits(id) },
    undefined,
    undefined,
    { disableQuery: true },
  )

  if (!table.data.length) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          src="https://assets.revolut.com/assets/3d-images-v2/3D171.png"
          srcSet="https://assets.revolut.com/assets/3d-images-v2/3D171@2x.png 2x, https://assets.revolut.com/assets/3d-images-v2/3D171@3x.png 3x"
        />
        <StatusWidget.Title>You don't have any benefits to enrol yet</StatusWidget.Title>
        <StatusWidget.Description>
          <Text>
            If you believe you should have benefits here, you should reach out to your HR
            and ask them to enable it.
          </Text>
        </StatusWidget.Description>
      </StatusWidget>
    )
  }

  return (
    <Widget p="s-16">
      {(() => {
        if (table.loading) {
          return (
            <Carousel>
              <Carousel.Item {...TILE_SIZE_PROPS}>
                <TileSkeleton variant="small" />
              </Carousel.Item>
              <Carousel.Item {...TILE_SIZE_PROPS}>
                <TileSkeleton variant="small" />
              </Carousel.Item>
              <Carousel.Item {...TILE_SIZE_PROPS}>
                <TileSkeleton variant="small" />
              </Carousel.Item>
            </Carousel>
          )
        }

        if (table.fetchError) {
          return (
            <ErrorWidget
              title={getMessageFromApiError(table.fetchError) || 'Unknown error'}
            />
          )
        }

        return (
          <>
            <Text mb="s-16" variant="emphasis2" use="p">
              Benefits
            </Text>
            <Carousel>
              {table.data.map(benefit => {
                const navigateToBenefit = () => {
                  navigateTo(
                    pathToUrl(
                      benefit.status.id === 'pending_employee'
                        ? ROUTES.FORMS.EMPLOYEE_BENEFIT.SUMMARY
                        : ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW,
                      { employeeId: id, id: benefit.template_id },
                    ),
                  )
                }

                return (
                  <Carousel.Item key={benefit.id} {...TILE_SIZE_PROPS}>
                    <Tile
                      bg={Token.color.greyTone2}
                      color={Token.color.foreground}
                      onClick={navigateToBenefit}
                      variant="small"
                    >
                      <Tile.Avatar>
                        <Avatar useIcon="Present" />
                      </Tile.Avatar>
                      <Tile.Action>
                        <Tag
                          color={getBenefitStatusColor(benefit.status.id)}
                          onClick={navigateToBenefit}
                          useIcon={getBenefitIcon(benefit.status.id)}
                          variant="status"
                        >
                          {getBenefitStatusText(benefit.status)}
                        </Tag>
                      </Tile.Action>
                      <Tile.Title>
                        <LineClamp max={2} tooltip="auto">
                          {benefit.benefit_name}
                        </LineClamp>
                      </Tile.Title>
                      <Tile.Content>
                        <Ellipsis maxWidth={140} tooltip="auto">
                          <Text color={Token.color.greyTone50} variant="body2">
                            {benefit.category.name}
                          </Text>
                        </Ellipsis>
                      </Tile.Content>
                    </Tile>
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </>
        )
      })()}
    </Widget>
  )
}
