import React from 'react'
import { Color, IconName, Tag, TagProps, Token } from '@revolut/ui-kit'
import { isNumber } from 'lodash'

export type CandidateMatch =
  | 'brilliant'
  | 'good'
  | 'ok'
  | 'poor'
  | 'no_match'
  | 'not_available'

interface CandidateMatchTagConfig {
  iconName?: IconName
  text: string
  color: Color
  bg: Color
}

const candidateMatchConfigs: Record<CandidateMatch, CandidateMatchTagConfig | null> = {
  brilliant: {
    iconName: 'DoubleThumbs',
    text: 'Briliant',
    color: Token.color.success,
    bg: Token.color.success_10,
  },
  good: {
    iconName: 'SocialLike',
    text: 'Good',
    color: Token.color.accentNeutral,
    bg: Token.color.actionBackgroundNeutral_20,
  },
  ok: {
    iconName: 'SocialLike',
    text: 'Ok',
    color: Token.color.accentNeutral,
    bg: Token.color.actionBackgroundNeutral_20,
  },
  poor: {
    iconName: 'SocialDislike',
    text: 'Poor',
    color: Token.color.greyTone50,
    bg: Token.color.greyTone8,
  },
  no_match: {
    iconName: 'SocialDislike',
    text: 'No match',
    color: Token.color.greyTone50,
    bg: Token.color.greyTone8,
  },
  not_available: {
    text: 'N/A',
    color: Token.color.greyTone50,
    bg: Token.color.greyTone8,
  },
}

const getMatchType = (score: number | null | undefined): CandidateMatch => {
  if (!isNumber(score)) {
    return 'not_available'
  }

  if (score >= 0.9) {
    return 'brilliant'
  }

  if (score >= 0.75) {
    return 'good'
  }

  if (score >= 0.5) {
    return 'ok'
  }

  if (score >= 0.2) {
    return 'poor'
  }

  return 'no_match'
}

interface Props extends TagProps {
  score: number | null | undefined
}

export const CandidateMatchTag = React.forwardRef<HTMLElement, Props>(
  ({ score, ...props }, ref) => {
    const type = getMatchType(score)
    const config = candidateMatchConfigs[type]

    if (!config) {
      return null
    }

    return (
      <Tag
        variant="status"
        useIcon={config?.iconName}
        bg={config.bg}
        color={config.color}
        ref={ref}
        {...props}
      >
        {config.text}
      </Tag>
    )
  },
)
