import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { Country, PerformanceGradeOption } from '@src/interfaces/selectors'
import {
  PerformanceReviewAnalyticsGroupTypes,
  PerformanceReviewAnalyticsInterface,
} from '@src/interfaces/performance'
import { getCountryFlagImageByCode } from '@src/utils/getCountryFlagImageByCode'
import { Row } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/Row'
import { GridBarChartSkeleton } from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/BarChart'
import { Legend } from '@src/features/ReviewCycle/Analytics/Charts/components/GridBarChart/Legend'
import { DISTRIBUTION_CHART_COLORS } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/constants'

interface Props {
  data: PerformanceReviewAnalyticsInterface[]
  dataKeys: PerformanceGradeOption[]
  countries: Country[]
  isLoading: boolean
}

export const GradeDistributionBarChart = ({
  data,
  dataKeys,
  countries,
  isLoading,
}: Props) => {
  if (isLoading) {
    return <GridBarChartSkeleton />
  }

  return (
    <VStack space="s-16" width="100%">
      {data.map(datum => {
        const labelImage =
          datum.group.type === PerformanceReviewAnalyticsGroupTypes.Country
            ? getCountryFlagImageByCode(
                countries.find(({ id }) => id === datum.group.id)?.country_code,
              )
            : undefined

        return (
          <Row
            key={datum.group.id}
            data={datum}
            dataKeys={dataKeys}
            colors={DISTRIBUTION_CHART_COLORS}
            labelImage={labelImage}
          />
        )
      })}
      <Legend dataKeys={dataKeys} colors={DISTRIBUTION_CHART_COLORS} />
    </VStack>
  )
}
