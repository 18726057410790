import React, { useCallback } from 'react'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { structureLevelSelectedUnitsSelector, structureLevelTabRequests } from '../api'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import {
  Action,
  ActionButton,
  Box,
  Cell,
  chain,
  HStack,
  Icon,
  InputGroup,
  Subheader,
  Token,
  VStack,
} from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  OrganisationStructureLevelTab,
  OrganisationStructureLevelTabSection,
} from '../types'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { TabIconPicker } from '../components/TabIconPicker'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { move } from '@src/utils/move'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'

type OrganisationStructureLevelTabSectionEdit = Omit<
  OrganisationStructureLevelTab,
  'sections'
> & {
  sections?: Partial<
    Omit<OrganisationStructureLevelTabSection, 'resources'> & {
      resources: { id?: string; name: string }[]
    }
  >[]
}

const MAX_SECTIONS_COUNT = 6

const StructureTabForm = () => {
  const params = useParams<{ levelId?: string; id?: string }>()

  const { values } = useLapeContext<OrganisationStructureLevelTabSectionEdit>()

  const unitsSelector = useCallback(async () => {
    const response = await structureLevelSelectedUnitsSelector(Number(params.levelId))
    return response.data.results || []
  }, [params.levelId])

  const backUrl = (() => {
    if (values.level.level_type === 'department') {
      return ROUTES.SETTINGS.STRUCTURES.TEAMS.DEPARTMENTS
    }
    return ROUTES.SETTINGS.STRUCTURES.ALL
  })()

  return (
    <PageWrapper>
      <PageHeader title="Edit tab" backUrl={backUrl} />

      <PageBody>
        <Subheader>
          <Subheader.Title>General details</Subheader.Title>
        </Subheader>

        <InputGroup>
          <LapeNewInput name="name" label="Tab name" required />

          <TabIconPicker
            onChange={icon => {
              values.icon = icon
            }}
            initialValue={values.icon}
          />

          <Cell>
            <InputGroup>
              <Subheader variant="nested" style={{ padding: 0 }}>
                <Subheader.Title>Enabled for departments</Subheader.Title>
              </Subheader>
              <VStack space="s-20">
                <LapeNewRadioButtons
                  name="state"
                  options={[
                    {
                      label: 'Enabled for all',
                      value: { id: 'enabled' },
                    },
                    {
                      label: 'Disabled for all',
                      value: { id: 'disabled' },
                    },
                    {
                      label: 'Enabled for selected departments',
                      value: { id: 'selected' },
                    },
                  ]}
                />
              </VStack>
            </InputGroup>
          </Cell>

          {values.state?.id === 'selected' ? (
            <LapeNewMultiSelect
              placeholder="Selected units"
              name="selected_units"
              required
              selector={unitsSelector}
            />
          ) : null}
        </InputGroup>

        {values.has_sections && (
          <Box mt="s-16">
            <Subheader>
              <Subheader.Title>
                {chain('Sections', values.sections?.length)}
              </Subheader.Title>
            </Subheader>

            <Sections />
          </Box>
        )}
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          confirmationDialogue={{
            label: 'Confirm tab changes',
            yesMessage: 'Confirm',
            noMessage: 'Back',
            body: 'The changes will be applied immediately. Do you want to proceed?',
          }}
        >
          Update
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}

const Sections = () => {
  const { values } = useLapeContext<OrganisationStructureLevelTabSectionEdit>()

  const confirmationDialog = useConfirmationDialog()

  return (
    <VStack space="s-16">
      {values.sections?.map((section, sectionIndex, allSections) => (
        <Cell key={section.id} data-testid={`structure-section-${section.name || 'new'}`}>
          <Box flex="1">
            <Subheader style={{ padding: 0, paddingBottom: Token.space.s16 }}>
              <Subheader.Title>{section.name}</Subheader.Title>
              <Subheader.Side>
                <HStack space="s-16">
                  {allSections.length - 1 !== sectionIndex && (
                    <Action
                      onClick={() => {
                        if (values.sections) {
                          values.sections = move(
                            values.sections,
                            sectionIndex,
                            sectionIndex + 1,
                          )
                        }
                      }}
                      useIcon="ArrowDown"
                    >
                      Move down
                    </Action>
                  )}
                  {sectionIndex > 0 && (
                    <Action
                      onClick={() => {
                        if (values.sections) {
                          values.sections = move(
                            values.sections,
                            sectionIndex,
                            sectionIndex - 1,
                          )
                        }
                      }}
                      useIcon="ArrowUp"
                    >
                      Move up
                    </Action>
                  )}
                  {section.required ? (
                    <Icon name="LockClosed" size={18} />
                  ) : (
                    <Action
                      onClick={() => {
                        confirmationDialog.show({
                          label: 'Delete section',
                          body: 'Deleting the section won’t remove the linked resources, but they will only be visible in the Total view.',
                          noMessage: 'Back',
                          yesMessage: 'Delete',
                          onConfirm: () => {
                            values.sections = values.sections?.filter(
                              (_, sIndex) => sectionIndex !== sIndex,
                            )
                            confirmationDialog.hide()
                          },
                          onReject: () => {
                            confirmationDialog.hide()
                          },
                        })
                      }}
                      useIcon="Delete"
                      color={Token.color.red}
                    >
                      Delete
                    </Action>
                  )}
                </HStack>
              </Subheader.Side>
            </Subheader>

            <InputGroup>
              <LapeNewInput
                name={`sections.${sectionIndex}.name`}
                label="Name"
                required
              />

              <Subheader variant="nested" style={{ padding: 0 }}>
                <Subheader.Title>Mandatory resources</Subheader.Title>
              </Subheader>

              <LapeMultiInput
                name={`sections.${sectionIndex}.resources`}
                valueFieldName="name"
                label="Resource name"
                required
              />
            </InputGroup>
          </Box>
        </Cell>
      ))}

      {values.sections && values.sections.length < MAX_SECTIONS_COUNT ? (
        <ActionButton
          useIcon="Plus"
          onClick={() => {
            values.sections?.push({
              name: '',
              resources: [{ name: '' }],
            })
          }}
        >
          Add new section
        </ActionButton>
      ) : null}
    </VStack>
  )
}

export const StructureTab = connect(() => {
  return (
    <Form api={structureLevelTabRequests}>
      <StructureTabForm />
    </Form>
  )
})
