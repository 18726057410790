import React, { useEffect } from 'react'
import { RoleInterface } from '@src/interfaces/roles'

import { SeniorityInterface } from '@src/interfaces/seniority'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Token, VStack } from '@revolut/ui-kit'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'

import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import { API } from '@src/constants/api'

import { useNextRoleStepButtonLink } from '@src/pages/Forms/RoleForm/hooks'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

import { PageActions } from '@src/components/Page/PageActions'
import { RoleCompetencyMatrixWidget } from '@src/features/Roles/components/RoleCompetencyMatrixWidget'
import { AllowedSenioritiesWidget } from '@src/features/Roles/components/AllowedSenioritesWidget'

const CURRENT_STEP = 'competency_matrix'

const CompetencyMatrix = ({
  matrixValidated,
  seniorities,
}: {
  matrixValidated: boolean
  seniorities: SeniorityInterface[]
}) => {
  const { values } = useLapeContext<RoleInterface>()

  const nextLink = useNextRoleStepButtonLink(CURRENT_STEP)

  useEffect(() => {
    if (values.status === 'draft') {
      workspaceLocalStorage.removeItem(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {}))
    }
  }, [])

  return (
    <>
      <VStack gap="s-16">
        <AllowedSenioritiesWidget />
        <RoleCompetencyMatrixWidget seniorities={seniorities} />

        <PageActions maxWidthMd={Token.breakpoint.xxl}>
          <>
            {values.status === Statuses.draft && (
              <RoleSaveDraftButton
                title="role"
                pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
                pathAfterSave={ROUTES.FORMS.ROLE.COMPETENCY_MATRIX}
                isNew
                notification={{
                  updateMsg: 'Role draft successfully updated.',
                  createMsg: 'Role draft successfully created.',
                }}
              />
            )}
            <ContinueRoleButton
              api={API.ROLES}
              type="role"
              step={CURRENT_STEP}
              to={nextLink}
              disabled={!matrixValidated}
            />
          </>
        </PageActions>
      </VStack>
    </>
  )
}

export default connect(CompetencyMatrix)
