import { FieldOptions, IdAndName, Statuses } from './index'
import { OptionInterface } from '@src/interfaces/selectors'
import { RiskScores } from '@src/interfaces/risk'
import { EmployeeOptionInterface, IdStatuses } from './employees'
import { CulturePercentile } from './culture'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { CommunicationGroupInterface } from './communicationGroup'
import { OrgUnitGoalsStats, GoalWeightMode } from './goals'
import { IconName } from '@revolut/ui-kit'

export enum TeamFunction {
  product = 'product',
  service = 'service',
}

export interface TeamInterface {
  id: number
  name: string
  icon: IconName | null
  avatar: string | null
  nips?: number
  team_owner?: {
    id: number
    full_name: string
    display_name: string
    name: string
    line_manager: {
      id: number
      full_name: string
      status: IdStatuses
    }
    quality_control: {
      id: number
      full_name: string
      status: IdStatuses
    }
    status: IdStatuses
  }
  contact: string
  kpi_champions?: EmployeeOptionInterface[]
  department?: {
    id: number
    name: string
    owner: {
      id: number
      name: string
      status: string
    }
    remit: string
    reports_to: {
      id: number
      full_name: string
      status: string
    }
    quality_control: {
      id: number
      full_name: string
      status: string
    }
  }
  reports_to: {
    id: number
    full_name: string
    status: string
  }
  quality_control: {
    id: number
    full_name: string
    status: string
  }
  team_function: TeamFunction
  requisition_count: number
  requisition_remaining_headcount: number
  performance: number
  roadmap_progress_percent: number
  roadmap_updated_date_time: string
  kpi_performance_percent: number
  kpi_weight_mode: {
    id: string
    name: string
  }
  kris_above_level_1: number
  kris_above_level_2: number
  kris_above_level_3: number
  business_debt_percent: number
  business_debt_target: number
  business_debt_extra_allowance: number
  business_debt_average_value: number
  business_debt_currency: string
  headcount: number
  risk_score: RiskScores
  risk_composite_index?: OptionInterface
  risk_score_reason: string
  mission: null
  department_id?: number
  audits_critical: number
  audits_trivial: number
  budget_currency: string
  total_current_spent: number
  audits_recommended: number
  audits_exceptional: number
  project: string | null
  jira_projects: string[] | null
  jql_query: string | null
  nps: number
  culture_enps: number
  culture_values: number
  culture_compliance: number
  culture_manager_enps: number
  culture_job_satisfaction: number
  culture_manager_enps_percentile: CulturePercentile
  culture_job_satisfaction_percentile: CulturePercentile
  culture_compliance_percentile: CulturePercentile
  culture_enps_percentile: CulturePercentile
  culture_values_percentile: CulturePercentile
  status: Statuses
  approval_status: ApprovalStatuses
  cx_complaints_last_30_days: number
  cx_csat_detractors_last_30_days: number
  cx_csat_detractors_rated_1: number
  cx_csat_detractors_rated_2: number
  cx_csat_detractors_rated_3: number
  cx_support_load_last_30_days: number
  cx_trustpilot_last_30_days: number
  time_off_request_pending_count: number | null
  time_off_request_in_progress_count: number | null
  time_off_request_upcoming_count: number | null
  field_options: FieldOptions
  communication_group: CommunicationGroupInterface | null
  linked_communication_groups: CommunicationGroupInterface[] | null
  average_score?: number | null
  dashboard_count: number
  goal_weight_mode?: IdAndName<GoalWeightMode, string>
  goals_progress_percent?: number
  is_committee?: boolean
  service_desk_url?: string
  leadership_positions_count?: number
  unassigned_leadership_positions_count?: number
  settings: {
    id: string | number
    enable_slack_user_groups: boolean
  }
  readonly kpi_champions_playbook: string | null
  qa_champions: EmployeeOptionInterface[]
  qa_champions_playbook: string | null
}

export interface TeamNPS {
  id: number
  comment: string
  creation_date_time: string
  language: string
  link?: string
  score: number
  team: TeamInterface
}

export interface TeamNPSStats {
  id: number
  nps: number
  nps_company: number
  nps_department: number
  nps_last_month: number
  nps_vs_last_month: number
  team: TeamInterface
}

export interface CreateTeamSimpleInterface {
  name: string
  team_owner: IdAndName
  department?: IdAndName
}

export interface TeamsStatsInterface {
  teams_count: number
  departments_count: number
}

export interface TeamGoalsStats extends OrgUnitGoalsStats {
  department: IdAndName | null
}
