import { FieldOptions, IdAndName, IdAndOptionalName, Statuses } from './index'
import { Stats } from './data'
import { EmployeeInterface, EmployeeOptionInterface, NameIdInterface } from './employees'
import { KPITypes } from '../constants/table'
import { PerformanceChartSingleData, TargetInterface } from '@src/interfaces/chart'
import { EntityTypes } from '@src/constants/api'
import { PerformanceRating, Ratings } from '@src/interfaces/performance'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { IssueTypes } from './deliverables'
import { LinearTaskType, NotionTaskType } from './roadmaps'

export interface KpiReviewCycle {
  id: number | string
  name: string
  category: ReviewCycleCategory
  start_date_time?: string
  end_date_time?: string
  status?: ReviewCyclesInterface['status']
  offset: number
}

export interface KpiReviewEmployeeCycle {
  id: number | string
  name: string
  category: ReviewCycleCategory
}

export interface KpiTargets {
  id?: number
  kpi?: { id: number }
  status?: Statuses
  review_cycle?: KpiReviewCycle
  employee_cycle?: KpiReviewEmployeeCycle
  initial_value: number | null
  target: number | null
  progress?: number
  current_value?: number
  kpi_goal: KpiGoals
  weight?: number
  calibrated_progress?: number
  calibration_comment?: string
  parent_kpi: null | KpiInterface
  is_top_down?: boolean
  is_probation?: boolean
  owner?: EmployeeOptionInterface & {
    team: NameIdInterface
  }
  rejection_reason?: string
}

export interface RelevantKpiInterface {
  name: string
  performance: number
  updated_date_time?: string
  children: KpiInterface[]
}

export interface EpicProgress {
  todo: number
  progress: number
  done: number
}

export interface EpicOption extends EpicProgress {
  id: number
  key: string
  name: string
  owner: NameIdInterface
  url: string
  review_cycle?: KpiReviewCycle
  issue_type?: IssueTypes | LinearTaskType | NotionTaskType
  jira_status: string
  due_date?: string
  unitName?: string
  type?: NameIdInterface
}

export interface EpicTaskOption
  extends Pick<EpicOption, 'key' | 'url' | 'name' | 'type'> {}

export interface KpiTargetEpics {
  id: number
  kpi?: { id: number }
  status: Statuses
  review_cycle?: KpiReviewCycle
  employee_cycle?: KpiReviewEmployeeCycle
  issue_type?: IssueTypes
  epics: EpicOption[]
  calibrated_progress?: number
  calibration_comment?: string
  parent_kpi: null | KpiInterface
  is_top_down: boolean
  is_probation?: boolean
  owner?: EmployeeOptionInterface & {
    team: NameIdInterface
  }
  rejection_reason?: string
}

export interface KPIEpicsTargetRow extends EpicProgress {
  review_cycle?: KpiReviewCycle
  jira_status: string
  status: Statuses
  children: EpicOption[]
  calibrated_progress?: number
  calibration_comment?: string
  uniqueKey: string
  parent_kpi: null | KpiInterface
  is_top_down: boolean
  owner?: EmployeeOptionInterface & {
    team: NameIdInterface
  }
}

export enum UpdateTypes {
  sql = 'sql',
  roadmap = 'roadmap',
  manual = 'manual',
  looker = 'looker',
  clickup = 'roadmap_clickup',
  tableau = 'tableau',
  salesforce = 'salesforce',
  linear = 'roadmap_linear',
  notion = 'roadmap_notion',
}

export enum KpiSqlLabel {
  General = 'general',
  Finance = 'finance',
  RoadmapSql = 'roadmap_sql',
  Sql_tbd = 'sql_tbd',
}

export enum KPIPerformanceTypes {
  business = 'business',
  mandatory = 'mandatory',
}

export enum KpiGoals {
  increase = 'increase',
  decrease = 'decrease',
  keep_target = 'keep_target',
  keep_target_down = 'keep_target_down',
  keep_target_up = 'keep_target_up',
}

export type KpiRelatedDashboardsInterface = Pick<
  GenericAnalyticsDashboardInterface,
  'id' | 'name'
>

export interface KpiToDoItem {
  name: string
  is_completed?: boolean
  id?: number
}

export interface SalesforceSearchInterface {
  id: string
  name: string
  url: string
}

export interface KpiInterface {
  id: number
  team: null | NameIdInterface
  department: null | NameIdInterface
  name: string
  full_name: string
  target: number
  extra?: {
    last_progress_update: string
    query_time_seconds: number
    result: any[]
    error?: string
  }
  company_goal?: NameIdInterface
  function: null | NameIdInterface
  role: null | NameIdInterface
  initial_value: number
  description: string
  target_1Y: number
  weight?: number
  enforce_weight: boolean
  performance: number
  current_progress: number
  calibrated_progress?: number
  calibration_comment?: string
  has_cascaded_children?: boolean
  target_date_time: string
  updated_date_time: string
  sql_query: string | null
  sql_query_db?: {
    id: string
    name: string
  }
  connection?: {
    id: string
    name: string
  }
  counted_sql_column?: string
  counted_sql_date_column?: string
  progress_history: { [key: string]: number }
  initial_date_time: string
  status: Statuses
  can_approve?: boolean
  target_status: Statuses
  parent: null | KpiInterface
  creation_date_time: string
  created_by: NameIdInterface
  owner: EmployeeOptionInterface & {
    team: NameIdInterface
  }
  entity_name?: string
  targets: KpiTargets[]
  target_epics: KpiTargetEpics[]
  future_targets: KpiTargets[] | KpiTargetEpics[]
  type: KPITypes
  kpi_type: {
    id: KPITypes
    name: string
  }
  kpi_performance_type: {
    id: KPIPerformanceTypes
    name: string
  }
  kpi_category: {
    id: string
    name: string
  }
  is_global: boolean
  is_company: boolean
  is_employee: boolean
  is_inherited?: boolean | null
  is_assigned_from_relevant?: boolean
  is_new: boolean
  unit: string
  update_type?: UpdateTypes
  means_of_measure?: IdAndName<'value' | 'todo'>
  todo_items?: KpiToDoItem[]
  approver: {
    name: string
    id: number
  }
  metabase_url?: string
  stats: Stats
  quality_score: number
  quality_check?: {
    id: number
    modified_on: string
    checks: QualityCheckInterface[]
  }
  can_run_query: boolean
  field_options: FieldOptions
  rejection_reason?: string
  rating?: PerformanceRating
  ratings?: Ratings[]
  calibration_factor?: {
    id: string
    name: string
    highlight: string
  }
  kpi_level?: {
    id: KPITypes
    name: string
  }
  kpi_goal?: KpiGoals
  look_url?: string | null
  tableau_view_url?: string
  salesforce_report?: { url: string; name: string; id: string }
  related_dashboards: KpiRelatedDashboardsInterface[]
  goal?: IdAndOptionalName
}

export interface QualityCheckInterface {
  key: string
  name: string
  status: boolean
  reason: string
}

export enum KPIWeightModes {
  auto = 'automatic',
  manual = 'manual',
}

export interface KpiWeightsInterface {
  entity_id: number
  entity_type: EntityTypes
  kpi_weight_mode: { id: KPIWeightModes }
  review_cycle?: KpiReviewCycle
  employee_cycle?: KpiReviewEmployeeCycle
  kpis: {
    enforce_weight: boolean
    id: number
    name: string
    weight: number | string
  }[]
}

export interface KpiEntityWeightMode {
  id: number
  review_cycle: ReviewCyclesInterface
  weight_mode: KPIWeightModes
}

export interface RunQueryResponse {
  message?: any
  error?: any
  progress_history?: PerformanceChartSingleData[]
  query_time_seconds?: number
  targets?: TargetInterface[]
  results?: any[]
}

export interface RunQueryStatusResponse {
  result: {
    message?: any
    error?: any
    progress_history?: PerformanceChartSingleData[]
    query_time_seconds?: number
    targets?: TargetInterface[]
    results?: any[]
  }
  status: 'SUCCESS' | 'PENDING'
}

export interface KpiBulkAssign {
  employee: {
    id: number
  }
  kpis: KpiInterface[]
}

export interface CalibrationFactor {
  id: string
  name: string
  highlight: string
}

export interface KPIFormLocationState {
  initialValues: { owner: KpiInterface['owner'] | EmployeeInterface } & Omit<
    Partial<KpiInterface>,
    'owner'
  >
  backUrl?: string
  probationId?: string
}
