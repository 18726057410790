import React, { useState } from 'react'

import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import {
  DepartmentSettingsFields,
  DepartmentSettingsSaveButton,
} from '@src/pages/Settings/Organisation/Departments/common'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { organisationSettings } from '@src/api/settings'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { Box, InputGroup } from '@revolut/ui-kit'
import { TabsItem } from './Structure/components/TabsItem'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { StructureTabsSidebar } from './Structure/components/TabsSidebar'
import { OrganisationStructureTab } from './types'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const StructuresDepartmentsSettingsForm = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const [openedSidebar, setOpenedSidebar] = useState<'tabs' | 'fields' | null>(null)

  const onChangeSidebarTabs = (tabs: OrganisationStructureTab[]) => {
    if (values.department_level) {
      values.department_level.tabs = tabs
    }
  }

  return (
    <>
      <PageWrapper>
        <PageHeader
          title="Departments settings"
          subtitle="Manage department functionality and approval settings"
          backUrl={ROUTES.SETTINGS.STRUCTURES.TEAMS.ALL}
        />

        <PageBody>
          <Box>
            <NewStepperTitle title="General settings" noAutoStep />
            <DepartmentSettingsFields />

            <NewStepperTitle
              title="Default fields and layout"
              subtitle="Choose default fields and tabs for departments. These settings could be overridden for each department."
              noAutoStep
            />
            <InputGroup>
              <TabsItem
                title="Default layout"
                description="Configure which tabs will be available for each department"
                onOpen={() => setOpenedSidebar('tabs')}
                selectedTabs={values.department_level?.tabs.filter(
                  tab => tab.state?.id === 'enabled',
                )}
              />
            </InputGroup>
          </Box>
        </PageBody>

        <PageActions>
          <DepartmentSettingsSaveButton />
        </PageActions>
      </PageWrapper>

      <StructureTabsSidebar
        isOpen={openedSidebar === 'tabs'}
        onChange={onChangeSidebarTabs}
        onClose={() => setOpenedSidebar(null)}
        onTabClick={id => {
          if (values.department_level?.id) {
            navigateTo(
              pathToUrl(ROUTES.SETTINGS.STRUCTURE_TAB, {
                id,
                levelId: values.department_level.id,
              }),
            )
          }
        }}
        tabs={values.department_level?.tabs}
        title="Default layout"
        subtitle="Configure which tabs will be available for each department"
      />
    </>
  )
}

export const StructuresDepartmentsSettings = connect(() => {
  return (
    <Form api={organisationSettings} forceParams={{ id: '1' }}>
      <StructuresDepartmentsSettingsForm />
    </Form>
  )
})
