import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { pathToUrl } from '@src/utils/router'
import { selectorKeys } from '@src/constants/api'
import { dashboardTypeRouteMap } from '@src/pages/Forms/DataAnalyticsDashboardForm/constants'
import Table from '@src/components/TableV2/Table'
import { DASHBOARD_DEFAULT_ICON } from '@src/constants/common'
import { Icon } from '@revolut/ui-kit'

export const departmentResourcesAnalyticsDashboardNameColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.dashboard_names,
    dynamicHyperlinks: data =>
      pathToUrl(dashboardTypeRouteMap[data.dashboard_type], { id: data.id }),
    title: 'Name',
    insert: ({ data, url }) => (
      <Table.EntityCell
        useIcon={data.icon || DASHBOARD_DEFAULT_ICON}
        avatar={data.avatar}
        url={url}
      >
        {data.name}
      </Table.EntityCell>
    ),
  }

export const departmentResourcesAnalyticsDashboardOwnerColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'owner.id',
    dataPoint: 'owner.name',
    sortKey: 'owner__full_name',
    filterKey: 'owner__id',
    selectorsKey: selectorKeys.dashboard_owner_employees,
    title: 'Owner',
    insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
  }

export const departmentResourcesAnalyticsDashboardDescriptionColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.text,
    idPoint: 'description',
    dataPoint: 'description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Description',
  }

export const departmentResourcesAnalyticsDashboardSourceColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'dashboard_type',
    dataPoint: 'dashboard_type',
    sortKey: null,
    filterKey: 'dashboard_type',
    selectorsKey: selectorKeys.dashboard_types,
    title: 'Source',
    insert: ({ data }) => {
      if (data.dashboard_type === 'looker') {
        return (
          <Icon src="https://assets.revolut.com/assets/apps/Looker.png" variant="image" />
        )
      }

      if (data.dashboard_type === 'tableau') {
        return (
          <Icon
            src="https://assets.revolut.com/assets/apps/Tableau.webp"
            variant="image"
          />
        )
      }

      return <Icon name="LogoRevolut" />
    },
  }
