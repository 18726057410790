import React from 'react'
import {
  Box,
  HStack,
  Icon,
  Spacer,
  Token,
  Tooltip,
  useTooltip,
  Widget,
  Text,
} from '@revolut/ui-kit'
import { EngagementInsightsTarget } from '@src/interfaces/engagement'
import { DatesRange } from '../../Rounds/Selector/types'
import { getInsightsWidgetStatus } from './helpers'
import { ApiQueryParams } from '@src/api/types'
import { ScopeFilterKey } from '@src/features/Engagement/api/analytics/interfaces'
import { InsightsWidgetContent } from './InsightsWidgetContent'
import { useCheckOrGenerateInsights } from './hooks/useCheckOrGenerateInsights'
import { useGetInsightsWithScore } from './hooks/useGetInsightsWithScore'

interface Props {
  surveyRoundId: number
  surveyId: number
  insightType: EngagementInsightsTarget
  categoryId?: number
  datesRange?: DatesRange
  scopeParams?: ApiQueryParams<ScopeFilterKey>
}

export const InsightsWidget = ({
  surveyRoundId,
  insightType,
  categoryId,
  surveyId,
  datesRange,
  scopeParams,
}: Props) => {
  const { insightsStatus, error: generateInsightsError } = useCheckOrGenerateInsights({
    roundId: surveyRoundId,
    scopeParams,
  })
  const {
    insights,
    isLoading: isInsightsLoading,
    insightsError,
  } = useGetInsightsWithScore({
    roundId: surveyRoundId,
    insightType,
    itemId: insightType === 'driver' && categoryId ? categoryId : surveyId,
    surveyId,
    datesRange,
    insightsGenerated: insightsStatus === 'success',
    scopeParams,
  })
  const tooltip = useTooltip()

  const commonInsightsStatus = getInsightsWidgetStatus({
    status: insightsStatus,
    haveInsights: !!insights?.length,
    error: !!insightsError || !!generateInsightsError,
    isInsightsLoading,
  })

  return (
    <Widget p="s-20">
      <HStack align="center">
        <Spacer />
        <HStack
          align="center"
          gap="s-6"
          color={Token.color.greyTone50}
          style={{ cursor: 'help' }}
          {...tooltip.getAnchorProps()}
        >
          <Icon name="Cleaning" size={20} color={Token.color.greyTone50} />
          <Text variant="caption">How we measure</Text>
          <Icon name="InfoOutline" size={16} color={Token.color.greyTone50} />
        </HStack>
        <Tooltip {...tooltip.getTargetProps()}>
          We use open comments and question responses to generate these insights
        </Tooltip>
      </HStack>
      <Box mt="s-20">
        <InsightsWidgetContent
          insights={insights}
          insightType={insightType}
          insightsStatus={commonInsightsStatus}
          surveyRoundId={surveyRoundId}
          surveyId={surveyId}
        />
      </Box>
    </Widget>
  )
}
