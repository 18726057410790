import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Details,
  DetailsSkeleton,
  HStack,
  Subheader,
  SubheaderSkeleton,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { BaseOption } from '@src/interfaces/selectors'
import { JiraEpicInterface } from '@src/interfaces/roadmaps'
import {
  BitbucketNumericMetrics,
  JiraMetrics as JiraMetricsType,
  ExternalMetricSources,
  IndividualBitbucketMetricsInterface,
  IndividualInterviewsMetricsInterface,
  IndividualJiraMetricsInterface,
  InternalMetricSources,
  OrgUnitBitbucketMetricsInterface,
  OrgUnitInterviewsMetricsInterface,
  OrgUnitJiraMetricsInterface,
  InterviewNumericMetrics,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import {
  PerformanceInsightsGroupInterface,
  PerformanceInsightsInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { insightsGroupModel } from '@src/features/AiInsights/Performance/models/insightsGroupModel'
import { useTableReturnType } from '@components/TableV2/hooks'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { OrgUnitSelector } from '@src/features/AiInsights/Performance/components/PerformanceOutput/OrgUnitSelector'
import { JiraMetrics } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/JiraMetrics'
import { BitbucketMetrics } from '@src/features/AiInsights/Performance/components/Metrics/External/Bitbucket/BitbucketMetrics'
import { InterviewMetrics } from '@src/features/AiInsights/Performance/components/Metrics/Internal/Interviews/InterviewMetrics'
import { KeyInsights } from '@src/features/AiInsights/Performance/components/Summary/KeyInsights'
import { SourceSkeleton } from '@src/features/AiInsights/Performance/components/Sources/common/Source'

interface Props {
  employeeId: number
  selectedOrgUnit: BaseOption<string>
  orgUnits: BaseOption<string>[]
  aiInsights: PerformanceInsightsInterface | null
  insightsGroup: PerformanceInsightsGroupInterface | null
  employeeJiraMetrics: IndividualJiraMetricsInterface | null
  employeeBitbucketMetrics: IndividualBitbucketMetricsInterface | null
  employeeInterviewsMetrics: IndividualInterviewsMetricsInterface | null
  orgUnitJiraMetrics: OrgUnitJiraMetricsInterface | null
  orgUnitBitbucketMetrics: OrgUnitBitbucketMetricsInterface | null
  orgUnitInterviewsMetrics: OrgUnitInterviewsMetricsInterface | null
  jiraEpicsTable: useTableReturnType<JiraEpicInterface, undefined>
  isLoading: boolean
  isJiraMetricsLoading: boolean
  isBitbucketMetricsLoading: boolean
  isInterviewsMetricsLoading: boolean
  isJiraMetricsVisible: boolean
  isBitbucketMetricsVisible: boolean
  isInterviewsMetricsVisible: boolean
  onSelectOrgUnit: (orgUnit: BaseOption<string>) => void
}

export const PerformanceOutput = ({
  employeeId,
  aiInsights,
  insightsGroup,
  selectedOrgUnit,
  orgUnits,
  employeeJiraMetrics,
  employeeBitbucketMetrics,
  employeeInterviewsMetrics,
  orgUnitJiraMetrics,
  orgUnitBitbucketMetrics,
  orgUnitInterviewsMetrics,
  jiraEpicsTable,
  isLoading,
  isJiraMetricsLoading,
  isBitbucketMetricsLoading,
  isInterviewsMetricsLoading,
  isJiraMetricsVisible,
  isBitbucketMetricsVisible,
  isInterviewsMetricsVisible,
  onSelectOrgUnit,
}: Props) => {
  if (isLoading) {
    return <Skeleton />
  }

  if (insightsGroup === null) {
    return null
  }

  const bitbucketInsightsSource = insightsGroupModel.getSource<
    ExternalMetricSources.Bitbucket,
    BitbucketNumericMetrics
  >(insightsGroup, ExternalMetricSources.Bitbucket)
  const jiraInsightsSource = insightsGroupModel.getSource<
    ExternalMetricSources.Jira,
    JiraMetricsType
  >(insightsGroup, ExternalMetricSources.Jira)
  const interviewsSource = insightsGroupModel.getSource<
    InternalMetricSources.Interviews,
    InterviewNumericMetrics
  >(insightsGroup, InternalMetricSources.Interviews)

  return (
    <VStack space="s-4">
      <VStack>
        <Subheader>
          <Subheader.Title>
            <HStack space="s-4" align="center">
              <AiIcon />
              <FormattedMessage
                id="performance.ai.insights.metrics.subheader.title"
                defaultMessage="Insights"
              />
            </HStack>
          </Subheader.Title>
        </Subheader>
        <Widget px="s-16" py="s-4">
          <Details>
            <Details.Title>
              <FormattedMessage
                id="performance.metrics.output.title"
                defaultMessage="Performance output"
              />
            </Details.Title>
            {selectedOrgUnit && (
              <Details.Content>
                <OrgUnitSelector
                  value={selectedOrgUnit}
                  options={orgUnits}
                  onSelect={onSelectOrgUnit}
                />
              </Details.Content>
            )}
          </Details>
        </Widget>
        {aiInsights && !!aiInsights.data && (
          <Widget p="s-16" mt="s-16">
            <KeyInsights variant="list" summary={aiInsights.data} />
          </Widget>
        )}
      </VStack>
      <VStack>
        <Subheader>
          <Subheader.Title>
            <Text variant="emphasis1" fontWeight={500} color={Token.color.foreground}>
              <FormattedMessage
                id="performance.metrics.sources.title"
                defaultMessage="Sources"
              />
            </Text>
          </Subheader.Title>
        </Subheader>
        <VStack space="s-16">
          {insightsGroup.sources.map(({ name }) => {
            if (
              name === ExternalMetricSources.Jira &&
              isJiraMetricsVisible &&
              jiraInsightsSource
            ) {
              return (
                <Widget p="s-16" key={name}>
                  <JiraMetrics
                    employeeId={employeeId}
                    orgUnit={selectedOrgUnit}
                    insightsSource={jiraInsightsSource}
                    employeeMetrics={employeeJiraMetrics}
                    orgUnitMetrics={orgUnitJiraMetrics}
                    jiraEpicsTable={jiraEpicsTable}
                    isLoading={isJiraMetricsLoading}
                  />
                </Widget>
              )
            }

            if (
              name === ExternalMetricSources.Bitbucket &&
              isBitbucketMetricsVisible &&
              bitbucketInsightsSource
            ) {
              return (
                <Widget p="s-16" key={name}>
                  <BitbucketMetrics
                    orgUnit={selectedOrgUnit}
                    insightsSource={bitbucketInsightsSource}
                    employeeMetrics={employeeBitbucketMetrics}
                    orgUnitMetrics={orgUnitBitbucketMetrics}
                    isLoading={isBitbucketMetricsLoading}
                  />
                </Widget>
              )
            }

            if (
              name === InternalMetricSources.Interviews &&
              isInterviewsMetricsVisible &&
              interviewsSource
            ) {
              return (
                <Widget p="s-16" key={name}>
                  <InterviewMetrics
                    orgUnit={selectedOrgUnit}
                    insightsSource={interviewsSource}
                    employeeMetrics={employeeInterviewsMetrics}
                    orgUnitMetrics={orgUnitInterviewsMetrics}
                    isLoading={isInterviewsMetricsLoading}
                  />
                </Widget>
              )
            }

            return <React.Fragment key={name} />
          })}
        </VStack>
      </VStack>
    </VStack>
  )
}

const Skeleton = () => (
  <VStack space="s-16">
    <SubheaderSkeleton />
    <Widget p="s-16">
      <DetailsSkeleton />
    </Widget>
    <SubheaderSkeleton />
    <Widget>
      <SourceSkeleton />
    </Widget>
    <SubheaderSkeleton />
    <Widget>
      <SourceSkeleton />
    </Widget>
  </VStack>
)
