import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  ProbationTemplateInterface,
  ProbationTemplateRulesInterface,
} from '@src/interfaces/probationTemplate'
import { selectorKeys } from '@src/constants/api'
import Table from '@src/components/TableV2/Table'
import { Text, TextButton, Token } from '@revolut/ui-kit'
import pluralize from 'pluralize'
import { templateRuleKeyToLabel } from './constants'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'

const templateFilterKeys = {
  countryId: 'country__id',
  entityId: 'entity__id',
  status: 'status',
} as const

const templateOrderKeys = {
  countryName: 'country__name',
  name: 'name',
  entityName: 'entity_name',
  status: 'status',
} as const

export const probationTemplateCountryWithNameColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'country-name',
    dataPoint: 'country-name',
    sortKey: templateOrderKeys.countryName,
    filterKey: templateFilterKeys.countryId,
    selectorsKey: selectorKeys.countries,
    title: 'Country templates',
    insert: ({ data }) => (
      <Table.CountryCell
        country={data.country || { name: 'Global', id: -1, country_code: undefined }}
        suffix={<Text color={Token.color.greyTone50}>{data.name}</Text>}
      />
    ),
  }

export const probationTemplateEmployeesColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employees',
    dataPoint: 'employees',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employees',
    insert: ({ data }) => (
      <Table.ItemCell useIcon="People">
        {Object.keys(data.rules).length
          ? Object.keys(data.rules).reduce((acc, key, i) => {
              acc += templateRuleKeyToLabel[key as keyof ProbationTemplateRulesInterface]
              if (i < Object.keys(data.rules).length - 1) {
                acc += ', '
              }
              return acc
            }, '')
          : 'All'}
      </Table.ItemCell>
    ),
  }

export const probationTemplateEntityNameColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'entity',
    dataPoint: 'entity',
    sortKey: templateOrderKeys.entityName,
    filterKey: templateFilterKeys.entityId,
    selectorsKey: selectorKeys.entity,
    title: 'Entity',
    insert: ({ data }) => <Table.ItemCell>{data.entity?.name || '-'}</Table.ItemCell>,
  }

export const probationTemplateDurationColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'duration',
    dataPoint: 'duration',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Duration',
    insert: ({ data }) => (
      <Table.ItemCell>
        {
          data.duration_time_unit?.name && data.duration
            ? pluralize(data.duration_time_unit.name, data.duration, true)
            : `${data.amount_of_months} months` /* support legacy */
        }
      </Table.ItemCell>
    ),
  }

export const probationTemplateNoticePeriodColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'notice-period',
    dataPoint: 'notice-period',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period',
    insert: ({ data }) => (
      <Table.ItemCell>
        {data.notice_period_time_unit?.name && data.notice_period
          ? pluralize(data.notice_period_time_unit.name, data.notice_period, true)
          : '-'}
      </Table.ItemCell>
    ),
  }

export const probationTemplatesNumberOfCheckpointsColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'checkpoints',
    dataPoint: 'num_checkpoints',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'checkpoints',
  }

export const probationTemplateFinalDecisionColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'final-decision',
    dataPoint: 'final-decision',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Final decision',
    insert: ({ data }) => {
      const decisionTimelineItem = data.timelines.find(
        timeline => timeline.timeline_type === 'decision',
      )
      return (
        <Table.ItemCell>
          {decisionTimelineItem
            ? pluralize('Week', decisionTimelineItem.weeks_due_date, true)
            : '-'}
        </Table.ItemCell>
      )
    },
  }

export const probationTemplateGoalSettingColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'goal-setting',
    dataPoint: 'goal-setting',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Goal setting',
    insert: ({ data }) => {
      const goalSettingTimelineItem = data.timelines.find(
        timeline => timeline.timeline_type === 'goals',
      )
      return (
        <Table.ItemCell>
          {goalSettingTimelineItem
            ? pluralize('Week', goalSettingTimelineItem.weeks_due_date, true)
            : '-'}
        </Table.ItemCell>
      )
    },
  }

export const probationTemplateStatusColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status',
    dataPoint: 'status',
    sortKey: templateOrderKeys.status,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => (
      <Table.StatusCell
        status={data.status}
        variant={data.status === 'enabled' ? 'success' : 'neutral'}
      />
    ),
  }

export const probationTemplatePolicyColumn: ColumnInterface<ProbationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'policy-link',
    dataPoint: 'policy-link',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Policy',
    insert: ({ data }) => (
      <Table.ItemCell>
        {data.policy_document_link ? (
          <OpenInNewTab
            link={data.policy_document_link}
            color={Token.color.foreground}
            iconPosition="end"
            label="Link"
          />
        ) : (
          '-'
        )}
      </Table.ItemCell>
    ),
  }

export const probationTemplateActionColumn = ({
  onEdit,
}: {
  onEdit: (templateId: number) => void
}): ColumnInterface<ProbationTemplateInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  textAlign: 'right',
  title: 'Action',
  insert: ({ data }) => (
    <Table.ActionsCell>
      <TextButton onClick={() => onEdit(data.id)}>Edit</TextButton>
    </Table.ActionsCell>
  ),
})
