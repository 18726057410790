import React from 'react'
import { HStack, Icon, Text, Token } from '@revolut/ui-kit'

import { TableCellInputType } from '@components/Inputs/TableCellInput/TableCellInput'
import Table from '@src/components/TableV2/Table'
import { CustomFieldTypes } from '@src/constants/customFields'
import {
  DatePickerInputCell,
  GenericEditableTableOnChange,
  MultiSelectInputCell,
  MultiSelectInputCellProps,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { IdAndName } from '@src/interfaces'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import { toIdAndName } from '@src/utils/toIdAndName'
import { selectorKeys } from '../api'

type EditableEmployeeColumn<T = EmployeesSimpleInterface> = (
  onChange: GenericEditableTableOnChange,
) => ColumnInterface<ImportInterface<T>>

type DirectlyEditableEmployeeColumn<T = EmployeesSimpleInterface> = (
  onChange: GenericEditableTableOnChange,
  isDirectEdit: boolean,
) => ColumnInterface<ImportInterface<T>>

type EditableEmployeeWithCreateNewColumn<T = EmployeesSimpleInterface> = (
  onChange: GenericEditableTableOnChange,
  onCreate: (onChangeAction: (newEntity: IdAndName) => void) => void,
) => ColumnInterface<ImportInterface<T>>

type DirectlyEditableEmployeeWithCreateNewColumn<T = EmployeesSimpleInterface> = (
  onChange: GenericEditableTableOnChange,
  onCreate: (onChangeAction: (newEntity: IdAndName) => void) => void,
  isDirectEdit: boolean,
) => ColumnInterface<ImportInterface<T>>

export const employeesFirstNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'First name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="first_name" />,
})

export const employeesMiddleNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'middle_name',
  dataPoint: 'middle_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Middle name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="middle_name" />,
})

export const employeesLastNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="last_name" />,
})

export const employeesPreferredNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'preferred_name',
  dataPoint: 'preferred_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Preferred name',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="preferred_name" />
  ),
})

export const employeesEmailColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: 'email',
  filterKey: 'id',
  selectorsKey: selectorKeys.employee,
  title: 'Work email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

const AccessGroupMultiSelect = ({
  data,
  onChange,
}: Pick<MultiSelectInputCellProps<EmployeesSimpleInterface>, 'data' | 'onChange'>) => {
  const accessGroups = data.data.access_groups || []
  const label = accessGroups.map(group => group.group_name).join(', ')
  const value = accessGroups.map(group => ({
    id: group.group_id,
    name: group.group_name,
  }))
  /** When value changes in the table, without triggering `onValueChange`, the multi select does not register that, need to rerender */
  const renderKey = value.map(i => i.id).join(',')

  const onValueChange: GenericEditableTableOnChange = (rowId, val, field) => {
    if (Array.isArray(val)) {
      onChange(
        rowId,
        val.map(group => ({ group_id: group.id, group_name: group.name })),
        field,
      )
    }
  }

  return (
    <MultiSelectInputCell
      data={data}
      value={value}
      onChange={onValueChange}
      field="access_groups"
      fieldName="access groups"
      selector={selectorKeys.groups}
      label={label}
      key={renderKey}
    />
  )
}

export const employeesAccessGroupColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_groups',
  dataPoint: 'access_groups',
  sortKey: 'access_groups',
  filterKey: 'access_groups__id',
  selectorsKey: selectorKeys.groups,
  title: 'Access groups',
  insert: ({ data }) => <AccessGroupMultiSelect data={data} onChange={onChange} />,
})

export const employeesAccessLevelColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_level',
  dataPoint: 'access_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Access group',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="access_level"
      fieldName="access group"
      selector={selectorKeys.groups}
      selectorField="name"
    />
  ),
})

export const employeesNameColumn: EditableEmployeeColumn = () => ({
  type: CellTypes.insert,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'id',
  selectorsKey: selectorKeys.employee,
  title: 'Name',
  insert: ({ data }) => {
    if (data.data.full_name) {
      return (
        <Table.EmployeeCell
          employee={{ id: data.data.id, full_name: data.data.full_name }}
        />
      )
    }
    return (
      <HStack align="center" space="s-8">
        <Icon name="ExclamationTriangle" size={15} color={Token.color.danger} />
        <Text>Employee does not exist</Text>
      </HStack>
    )
  },
})

export const employeesTeamColumn: DirectlyEditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  isDirectEdit,
) => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
      selectorField={isDirectEdit ? undefined : 'name'}
      onCreateNewClick={onCreate}
    />
  ),
})

export const employeesDepartmentColumn: EditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
) => ({
  type: CellTypes.insert,
  idPoint: 'department',
  dataPoint: 'department',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.department,
  title: 'Department',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="department"
      selector={selectorKeys.department}
      selectorField="name"
      onCreateNewClick={onCreate}
    />
  ),
})

export const employeesEntityColumn: DirectlyEditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  isDirectEdit,
) => ({
  type: CellTypes.insert,
  idPoint: 'entity',
  dataPoint: 'entity',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.entity,
  title: 'Entity',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="entity"
      selector={selectorKeys.entity}
      selectorField={isDirectEdit ? undefined : 'name'}
      onCreateNewClick={onCreate}
    />
  ),
})

export const employeesRoleColumn: DirectlyEditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  isDirectEdit,
) => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.specialisations}
      selectorField={isDirectEdit ? undefined : 'name'}
      fieldName="role"
      onCreateNewClick={onCreate}
    />
  ),
})

export const employeesSeniorityColumn: DirectlyEditableEmployeeColumn = (
  onChange,
  isDirectEdit,
) => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="seniority"
      selector={selectorKeys.seniority}
      selectorField={isDirectEdit ? undefined : 'name'}
    />
  ),
})

export const employeesLocationColumn: DirectlyEditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  isDirectEdit,
) => ({
  type: CellTypes.insert,
  idPoint: 'location',
  dataPoint: 'location',
  sortKey: null,
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="location"
      selector={selectorKeys.location}
      selectorField={isDirectEdit ? undefined : 'name'}
      onCreateNewClick={onCreate}
    />
  ),
})

export const employeesCountryColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'country',
  dataPoint: 'country',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.countries,
  title: 'Country',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="country"
      selector={selectorKeys.countries}
      selectorField="name"
    />
  ),
})

const getStringFromMultiSelectValue = (value: IdAndName<string>[]) =>
  value.map(valueItem => valueItem.name).join('; ')

const getMultiSelectProps = (str: string | null) => {
  const strValues = str?.split(';') || []
  const value = strValues.map(strValue => toIdAndName(strValue.trim()))

  return {
    value,
    label: getStringFromMultiSelectValue(value),
  }
}

export const employeesLanguagesColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'languages',
  dataPoint: 'languages',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.language,
  title: 'Languages',
  insert: ({ data }) => {
    const { value, label } = getMultiSelectProps(data.data.languages)

    return (
      <MultiSelectInputCell
        data={data}
        value={value}
        onChange={(rowId, val, field) => {
          if (Array.isArray(val)) {
            onChange(rowId, getStringFromMultiSelectValue(val), field)
          }
        }}
        field="languages"
        fieldName="languages"
        selector={selectorKeys.language}
        label={label}
        key={label}
      />
    )
  },
})

export const employeesNationalitiesColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'nationalities',
  dataPoint: 'nationalities',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.countries,
  title: 'Nationalities',
  insert: ({ data }) => {
    const { value, label } = getMultiSelectProps(data.data.nationalities)

    return (
      <MultiSelectInputCell
        data={data}
        value={value}
        onChange={(rowId, val, field) => {
          if (Array.isArray(val)) {
            onChange(rowId, getStringFromMultiSelectValue(val), field)
          }
        }}
        field="nationalities"
        fieldName="nationalities"
        selector={selectorKeys.countries}
        label={label}
        key={label}
      />
    )
  },
})

export const employeesLineManagerColumn: DirectlyEditableEmployeeColumn = (
  onChange,
  isDirectEdit,
) => ({
  type: CellTypes.insert,
  idPoint: 'line_manager',
  dataPoint: 'line_manager',
  sortKey: null,
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Line manager',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="line_manager"
      fieldName="manager"
      selector={selectorKeys.all_employees_avatar_email}
      selectorField={isDirectEdit ? undefined : 'email'}
    />
  ),
})

export const employeesFunctionalManagerColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'functional_manager',
  dataPoint: 'functional_manager',
  sortKey: null,
  filterKey: 'functional_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Functional manager',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="functional_manager"
      fieldName="functional manager"
      selector={selectorKeys.all_employees_avatar_email}
      selectorField="name"
    />
  ),
})

export const employeesJobTitleColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'job_title',
  dataPoint: 'job_title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job title',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="job_title" />,
})

export const employeesStartDateColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
  insert: ({ data }) => (
    <DatePickerInputCell field="start_date" data={data} onChange={onChange} />
  ),
})

export const employeesEndDateColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'end_date',
  dataPoint: 'end_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'End date',
  insert: ({ data }) => (
    <DatePickerInputCell field="end_date" data={data} onChange={onChange} />
  ),
})

export const employeesStatusColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  title: 'Status',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="status"
      selector={selectorKeys.employee_status_pending}
      selectorField="name"
    />
  ),
})

export const employeesContractTypeColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'contract_type',
  dataPoint: 'contract_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.contract_types,
  title: 'Contract type',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="contract_type"
      selector={selectorKeys.contract_types}
      selectorField="name"
    />
  ),
})

export const employeesLegalSexColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'legal_sex',
  dataPoint: 'legal_sex',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.legal_sexes,
  title: 'Legal sex',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="legal_sex"
      selector={selectorKeys.legal_sexes}
      selectorField="name"
    />
  ),
})

export const employeesMaritalStatusColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'marital_status',
  dataPoint: 'marital_status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.marital_statuses,
  title: 'Marital status',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="marital_status"
      selector={selectorKeys.marital_statuses}
      selectorField="name"
    />
  ),
})

export const employeesBirthDateColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'birth_date',
  dataPoint: 'birth_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Birth date',
  insert: ({ data }) => (
    <DatePickerInputCell field="birth_date" data={data} onChange={onChange} />
  ),
})

export const employeesPersonalEmailColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'personal_email',
  dataPoint: 'personal_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Personal email',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="personal_email" />
  ),
})

export const employeesPhoneCountryCodeColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'phone_country_code',
  dataPoint: 'phone_country_code',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Phone country code',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="phone_country_code" />
  ),
})

export const employeesPhoneNumberShortColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'phone_number_short',
  dataPoint: 'phone_number_short',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Phone number short',
  insert: ({ data }) => (
    <TextCell
      data={data}
      onChange={onChange}
      field="phone_number_short"
      type={TableCellInputType.int}
    />
  ),
})

export const employeesAddressLine1Column: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'address_line_1',
  dataPoint: 'address_line_1',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Address line 1',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="address_line_1" />
  ),
})

export const employeesAddressLine2Column: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'address_line_2',
  dataPoint: 'address_line_2',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Address line 2',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="address_line_2" />
  ),
})

export const employeesAddressLine3Column: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'address_line_3',
  dataPoint: 'address_line_3',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Address line 3',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="address_line_3" />
  ),
})

export const employeesBankNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bank_name',
  dataPoint: 'bank_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bank name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="bank_name" />,
})

export const employeesBankAccountNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'account_name',
  dataPoint: 'account_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bank account name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="account_name" />,
})

export const employeesBankAccountCurrencyColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bank_currency',
  dataPoint: 'bank_currency',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.currencies,
  title: 'Bank account currency',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="bank_currency"
      selector={selectorKeys.currencies}
      selectorField="iso_code"
    />
  ),
})

export const employeesEmergencyContactEmailColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'emergency_contact_email',
  dataPoint: 'emergency_contact_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Emergency contact email',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="emergency_contact_email" />
  ),
})

export const employeesEmergencyContactFullNameColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_full_name',
    dataPoint: 'emergency_contact_full_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact full name',
    insert: ({ data }) => (
      <TextCell data={data} onChange={onChange} field="emergency_contact_full_name" />
    ),
  })

export const employeesEmergencyContactRelationshipColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_relationship',
    dataPoint: 'emergency_contact_relationship',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact relationship',
    insert: ({ data }) => (
      <TextCell data={data} onChange={onChange} field="emergency_contact_relationship" />
    ),
  })

export const employeesEmergencyContactMobilePhoneColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_mobile_phone',
    dataPoint: 'emergency_contact_mobile_phone',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact mobile phone',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        field="emergency_contact_mobile_phone"
        type={TableCellInputType.int}
      />
    ),
  })

export const employeesEmergencyContactPhoneCountryCodeColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_phone_country_code',
    dataPoint: 'emergency_contact_phone_country_code',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact phone country code',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        field="emergency_contact_phone_country_code"
      />
    ),
  })

export const employeesChangesEffectiveDateColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'changes_effective_date',
  dataPoint: 'changes_effective_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Changes effective date',
  insert: ({ data }) => (
    <DatePickerInputCell data={data} field="changes_effective_date" onChange={onChange} />
  ),
})

export const employeesEmergencyContactPhoneNumberColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_phone_number',
    dataPoint: 'emergency_contact_phone_number',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact phone number',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        field="emergency_contact_phone_number"
        type={TableCellInputType.int}
      />
    ),
  })

export const employeesContractTermColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'contract_term',
  dataPoint: 'contract_term',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Contract term',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="contract_term"
      fieldName="contract term"
      onChange={onChange}
      selector={selectorKeys.contract_terms}
      selectorField="name"
    />
  ),
})

export const employeesPayGroupColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'pay_group',
  dataPoint: 'pay_group',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Pay group',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="pay_group"
      fieldName="pay group"
      onChange={onChange}
      selector={selectorKeys.active_pay_groups}
      selectorField="name"
    />
  ),
})

export const employeesSalaryCurrencyColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'salary_currency',
  dataPoint: 'salary_currency',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary currency',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="salary_currency"
      fieldName="salary currency"
      onChange={onChange}
      selector={selectorKeys.currencies}
      selectorField="iso_code"
    />
  ),
})

export const employeesSalaryAmountColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'salary_amount',
  dataPoint: 'salary_amount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary amount',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="salary_amount"
      onChange={onChange}
      type={TableCellInputType.positiveInt}
    />
  ),
})

export const employeesSalaryTimeUnitColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'salary_time_unit',
  dataPoint: 'salary_time_unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary time unit',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="salary_time_unit"
      fieldName="salary time unit"
      selector={selectorKeys.contract_salary_time_units}
      selectorField="name"
    />
  ),
})

export const employeesSalaryPaymentFrequencyColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'salary_payment_frequency',
    dataPoint: 'salary_payment_frequency',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Salary payment frequency',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="salary_payment_frequency"
        fieldName="salary payment frequency"
        selector={selectorKeys.contract_salary_payment_frequencies}
        selectorField="name"
      />
    ),
  })

export const employeesJoiningBonusColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'sign_on_bonus_type',
  dataPoint: 'sign_on_bonus_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Joining bonus',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="sign_on_bonus_type"
      fieldName="joining bonus"
      onChange={onChange}
      selector={selectorKeys.contract_sign_on_bonus_types}
      selectorField="name"
    />
  ),
})

export const employeesJoiningBonusCurrencyColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'sign_on_bonus_currency',
  dataPoint: 'sign_on_bonus_currency',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Joining bonus currency',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="sign_on_bonus_currency"
      fieldName="joining bonus currency"
      onChange={onChange}
      selector={selectorKeys.currencies}
      selectorField="iso_code"
    />
  ),
})

export const employeesJoiningBonusAmountColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'sign_on_bonus_amount',
  dataPoint: 'sign_on_bonus_amount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Joining bonus amount',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="sign_on_bonus_amount"
      onChange={onChange}
      type={TableCellInputType.positiveInt}
    />
  ),
})

export const employeesWeeklyWorkingHoursColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'weekly_working_hours',
  dataPoint: 'weekly_working_hours',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Weekly working hours',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="weekly_working_hours"
      onChange={onChange}
      type={TableCellInputType.positiveInt}
    />
  ),
})

export const employeesFullTimeEquivalentColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'full_time_equivalent',
  dataPoint: 'full_time_equivalent',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Full time equivalent',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="full_time_equivalent"
      onChange={onChange}
      type={TableCellInputType.positiveInt}
    />
  ),
})

export const employeesNoticePeriodDuringProbationColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'notice_period_during_probation',
    dataPoint: 'notice_period_during_probation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period during probation',
    insert: ({ data }) => (
      <TextCell
        data={data}
        field="notice_period_during_probation"
        onChange={onChange}
        type={TableCellInputType.positiveInt}
      />
    ),
  })

export const employeesNoticePeriodDuringProbationUnitColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'notice_period_during_probation_unit',
    dataPoint: 'notice_period_during_probation_unit',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period during probation unit',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        field="notice_period_during_probation_unit"
        fieldName="notice period during probation unit"
        onChange={onChange}
        selector={selectorKeys.contract_notice_period_time_units}
        selectorField="name"
      />
    ),
  })

export const employeesNoticePeriodAfterProbationColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'notice_period_after_probation',
    dataPoint: 'notice_period_after_probation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period after probation',
    insert: ({ data }) => (
      <TextCell
        data={data}
        field="notice_period_after_probation"
        onChange={onChange}
        type={TableCellInputType.positiveInt}
      />
    ),
  })

export const employeesNoticePeriodAfterProbationUnitColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'notice_period_after_probation_unit',
    dataPoint: 'notice_period_after_probation_unit',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notice period after probation unit',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        field="notice_period_after_probation_unit"
        fieldName="notice period after probation unit"
        onChange={onChange}
        selector={selectorKeys.contract_notice_period_time_units}
        selectorField="name"
      />
    ),
  })

export const employeesWorkModalityColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'work_modality',
  dataPoint: 'work_modality',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Work modality',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="work_modality"
      fieldName="work modality"
      onChange={onChange}
      selector={selectorKeys.contract_work_modalities}
      selectorField="name"
    />
  ),
})

export const employeesLinkToJustificationColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'link_to_justification',
  dataPoint: 'link_to_justification',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Link to justification',
  insert: ({ data }) => (
    <TextCell data={data} field="link_to_justification" onChange={onChange} />
  ),
})

export const employeesCompensationBackdateCorrectionColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'compensation_backdate_correction_type',
    dataPoint: 'compensation_backdate_correction_type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Compensation backdate correction',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        field="compensation_backdate_correction_type"
        fieldName="compensation backdate correction"
        onChange={onChange}
        selector={selectorKeys.compensation_backdate_correction_types}
        selectorField="name"
      />
    ),
  })

export const createCustomFieldColumn = (
  customField: CustomFieldsForSectionInterface,
): EditableEmployeeColumn => {
  return onChange => ({
    type: CellTypes.insert,
    idPoint: customField.field_name,
    dataPoint: customField.field_name,
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: customField.name,
    insert: ({ data }) => {
      const inputTypeId = customField.input_type.id

      if ([CustomFieldTypes.Text, CustomFieldTypes.Number].includes(inputTypeId)) {
        return (
          <TextCell
            data={data}
            onChange={onChange}
            field={customField.field_name}
            type={
              inputTypeId === CustomFieldTypes.Number ? TableCellInputType.int : undefined
            }
          />
        )
      }
      if (inputTypeId === CustomFieldTypes.Date) {
        return (
          <DatePickerInputCell
            data={data}
            onChange={onChange}
            field={customField.field_name}
          />
        )
      }
      if (
        [CustomFieldTypes.Dropdown, CustomFieldTypes.MultipleSelector].includes(
          inputTypeId,
        )
      ) {
        const fieldOptions = customField.validation_checks?.validation_choices || []
        const formattedOptions = fieldOptions.map(option => ({
          id: option.id,
          name: option.name,
          value: option,
        }))
        const commonProps = {
          data,
          onChange,
          selector: selectorKeys.none,
          options: formattedOptions,
          field: customField.field_name,
          fieldName: customField.name,
        }
        return inputTypeId === CustomFieldTypes.Dropdown ? (
          <RadioSelectInputCell selectorField="name" {...commonProps} />
        ) : inputTypeId === CustomFieldTypes.MultipleSelector ? (
          <MultiSelectInputCell {...commonProps} />
        ) : null
      }
      return null
    },
  })
}
