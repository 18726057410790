import { useState } from 'react'
import { QueryObserverResult } from 'react-query'

import { RightToWorkInterface } from '@src/interfaces/employees'
import { useGetEmployeeRightsToWork } from '@src/api/employees'
import isAfter from 'date-fns/isAfter'

export const useEmployeeRightToWorkWidget = (employeeId: number | string | undefined) => {
  const [loadData, setLoadData] = useState(false)
  const {
    data: rightsToWorkData,
    refetch,
    isRefetching,
    status,
  } = useGetEmployeeRightsToWork(loadData ? employeeId : undefined, [
    { columnName: 'status', filters: [{ id: 'complete', name: 'complete' }] },
  ])

  const rightToWorkItems = rightsToWorkData?.results || []
  const rightToWorkItemsWithExpiryDate = rightToWorkItems
    .filter(rightToWork => Boolean(rightToWork.document_expiry_date))
    .sort((prev, next) => {
      return isAfter(
        new Date(prev.document_expiry_date),
        new Date(next.document_expiry_date),
      )
        ? -1
        : 1
    })
  const rightToWorkItemsWithoutExpiryDate = rightToWorkItems.filter(
    rightToWork => !rightToWork.document_expiry_date,
  )
  const previewRightToWorkItem =
    rightToWorkItemsWithoutExpiryDate[0] || rightToWorkItemsWithExpiryDate[0]

  return {
    data: previewRightToWorkItem,
    handler: {
      load: () => setLoadData(true),
      refetch: async () => {
        const res = await refetch()
        return {
          ...res,
          data: rightsToWorkData?.results?.[rightsToWorkData?.results.length - 1],
        } as QueryObserverResult<RightToWorkInterface, Error>
      },
      isRefetching,
      status,
      enabled: loadData,
    },
  }
}
