import React, { useEffect, useState } from 'react'

import {
  CompetencyMatrixInterface,
  RoleInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { API } from '@src/constants/api'
import { SeniorityInterface } from '@src/interfaces/seniority'
import Loader from '@components/CommonSC/Loader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box, Token } from '@revolut/ui-kit'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'

import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import { FeatureFlags } from '@src/store/auth/types'

import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { useNextSpecialisationStepButtonLink } from '@src/pages/Forms/SpecialisationForm/hooks'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { useSeniorityRange } from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/useSeniorityRange'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { SeniorityLevelsWidget } from '@src/features/Specialisations/components/SeniorityLevelsWidget'
import { CultureFitSkillsWidget } from '@src/features/Specialisations/components/CultureFitSkillsWidget'
import { CompetencyMatrixWidget } from '@src/features/Specialisations/components/CompetencyMatrixWidget'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { useWeightsErrors } from '@src/features/Specialisations/hooks/useWeightsErrors'
import { AllowedSenioritiesWidget } from '@src/features/Specialisations/components/AllowedSenioritesWidget'
import { checkCanEdit } from '@src/features/Specialisations/helpers/checkCanEdit'

const CURRENT_STEP = 'competency_matrix'

const CompetencyMatrix = ({ matrixValidated }: { matrixValidated: boolean }) => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const [role, setRole] = useState<RoleInterface>()
  const [filteredSeniorities, setFilteredSeniorities] = useState<SeniorityInterface[]>([])
  const [defaultCultureSkills, setDefaultCultureSkills] = useState<
    CompetencyMatrixInterface[]
  >(values.culture_competency_matrix || [])

  const { seniorities, loading } = useSeniorityRange({
    onFetchRoleSuccess: fetchedRole => {
      setDefaultCultureSkills(fetchedRole.culture_competency_matrix)
      setRole(fetchedRole)
    },
  })

  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const competencyMatrixV2 = useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2)
  const weightErrors = useWeightsErrors()

  const canEdit = checkCanEdit(values)

  const canEditWeights = Boolean(values.id && values.performance_weights)

  useEffect(() => {
    if (values.status === 'draft') {
      workspaceLocalStorage.removeItem(
        pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {}),
      )
    }
  }, [])

  useEffect(() => {
    setFilteredSeniorities(seniorities)
  }, [seniorities])

  const weightsError =
    canEditWeights &&
    ((typeof weightErrors.totalWeight === 'number' && weightErrors.totalWeight !== 100) ||
      weightErrors.skillWithNoWeight)

  if (!values.functional_competency_matrix && !competencyMatrixV2) {
    values.functional_competency_matrix = []
  }

  if (loading) {
    return (
      <Box pt="s-8">
        <Loader size="medium" />
      </Box>
    )
  }

  return (
    <>
      <PageBody gap="s-16" maxWidthMd={Token.breakpoint.xxl}>
        <AllowedSenioritiesWidget />
        <CompetencyMatrixWidget role={role} filteredSeniorities={filteredSeniorities} />
        <CultureFitSkillsWidget role={role} defaultCultureSkills={defaultCultureSkills} />
        <SeniorityLevelsWidget role={role} />
      </PageBody>
      {canEdit && (
        <PageActions maxWidthMd={Token.breakpoint.xxl}>
          {values.status === Statuses.draft && (
            <RoleSaveDraftButton
              title={specialisationsEnabled ? 'specialisation' : 'role'}
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
              pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX}
              isNew
              notification={{
                path: ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX,
                updateMsg: specialisationsEnabled
                  ? 'Specialisation draft successfully updated'
                  : 'Role draft successfully updated',
                createMsg: specialisationsEnabled
                  ? 'Specialisation draft successfully created'
                  : 'Role draft successfully created',
              }}
            />
          )}
          <ContinueRoleButton
            api={API.SPECIALISATIONS}
            step={CURRENT_STEP}
            type="specialisation"
            to={nextLink}
            disabled={weightsError || !matrixValidated}
          />
        </PageActions>
      )}
    </>
  )
}

export default connect(CompetencyMatrix)
