import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, List, Text, Token, VStack } from '@revolut/ui-kit'
import { BULLET_POINT_REG_EXP } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/BulletPointTextArea'

const BULLET_POINT_SOL_REG_EXP = new RegExp(`^${BULLET_POINT_REG_EXP.source}`)
const BOLD_TOKENS_GROUPS_REG_EXP = /(\*\*)([\s\S]+?)(\*\*)/g

interface Props extends SummaryProps {
  details?: React.ReactNode
}

export const KeyInsights = ({ summary, details, variant }: Props) => (
  <VStack space="s-12">
    <SummaryFormatted summary={summary} variant={variant} />
    {details}
    <Flex justifyContent="start">
      <Text fontSize={12} color={Token.color.greyTone50}>
        <FormattedMessage
          id="performance.ai.insights.warning.description"
          defaultMessage="AI-generated content may include errors. Verify critical details."
        />
      </Text>
    </Flex>
  </VStack>
)

type SummaryVariant = 'default' | 'list'

interface SummaryProps {
  summary: string
  variant?: SummaryVariant
}

export const SummaryFormatted = ({ summary, variant = 'default' }: SummaryProps) => {
  if (variant === 'list') {
    return <ListFormatted summary={summary} />
  }

  return <LineFormatted summary={summary} />
}

const ListFormatted = ({ summary }: Pick<Props, 'summary'>) => (
  <List variant="compact" pl="s-8" color={Token.color.greyTone50}>
    {summary.split('\n').map((line, idx) => (
      <List.Item key={idx} useIcon="Dot">
        <FormattedMessage
          id="performance.ai.insights.summary"
          values={{
            b: chunks => (
              <Text fontWeight={700} color={Token.color.foreground}>
                {chunks}
              </Text>
            ),
          }}
          defaultMessage={line
            .replace(BULLET_POINT_SOL_REG_EXP, '')
            .replace(BOLD_TOKENS_GROUPS_REG_EXP, '<b>$2</b>')}
        />
      </List.Item>
    ))}
  </List>
)

const LineFormatted = ({ summary }: Pick<Props, 'summary'>) => (
  <Text fontSize={14} color={Token.color.greyTone50}>
    <FormattedMessage
      id="performance.ai.insights.summary"
      values={{
        b: chunks => (
          <Text fontWeight={700} color={Token.color.foreground}>
            {chunks}
          </Text>
        ),
      }}
      defaultMessage={summary.replace(BOLD_TOKENS_GROUPS_REG_EXP, '<b>$2</b>')}
    />
  </Text>
)
