import React from 'react'
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  Rectangle,
  ReferenceLine,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Relative, Token } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { ChartEmptyResults } from '@src/pages/Forms/QueryForm/components/Charts/ChartEmptyResults'
import { ResponsiveContainer } from '@src/pages/Forms/QueryForm/components/Charts/ResponsiveContainer'
import { DefaultLegend } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultLegend'
import {
  getActiveShapeColor,
  getMainShapeColor,
} from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import { ChartTooltip } from '@src/pages/Forms/QueryForm/components/Charts/components/ChartTooltip'
import {
  RatingHistoryDataKey,
  RatingHistoryItemData,
} from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/ChartData'
import {
  EXPECTED_RATING_COLOR,
  RATING_COLOR,
} from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/hooks/useChartData'
import { Tooltip } from '@src/features/AiInsights/Performance/components/Metrics/Internal/Analytics/Skills/RatingHistoryChart/Tooltip'

export const CHART_HEIGHT = 200
const LEGEND_PADDING_TOP = '16px'
const X_AXIS_TICK_MARGIN = 8
const Y_AXIS_TICK_MARGIN = 12
const Y_AXIS_WIDTH = 80
const MAX_BAR_SIZE = 50
const BAR_RADIUS = 2

interface Props {
  dataKeys: RatingHistoryDataKey[]
  data: RatingHistoryItemData[]
  yAxisTicks: IdAndName<string>[]
  isLoading: boolean
}

export const RatingHistoryChart = ({ data, dataKeys, yAxisTicks, isLoading }: Props) => {
  const [ratingDataKey, expectationDataKey] = dataKeys

  const tickFormatter = (value: number): string => yAxisTicks[value].name

  if (isLoading) {
    return <ChartSkeleton height={CHART_HEIGHT} />
  }

  if (!data.length) {
    return <ChartEmptyResults height={CHART_HEIGHT} />
  }

  return (
    <Relative width="100%" height="100%">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data} layout="horizontal">
          <XAxis
            xAxisId="composed"
            dataKey="label"
            axisLine={false}
            tickLine={false}
            tickMargin={X_AXIS_TICK_MARGIN}
          />
          <XAxis
            type="number"
            xAxisId="rline"
            domain={[0, 1]}
            dataKey="index"
            axisLine={false}
            tickLine={false}
            tick={false}
            height={0}
          />
          <YAxis
            type="number"
            domain={[0, yAxisTicks.length - 1]}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            tickCount={yAxisTicks.length}
            tick={{ color: Token.color.greyTone50, fontSize: 12 }}
            tickFormatter={tickFormatter}
            tickSize={0}
            tickMargin={Y_AXIS_TICK_MARGIN}
            width={Y_AXIS_WIDTH}
            orientation="right"
          />
          <Bar
            xAxisId="composed"
            dataKey={ratingDataKey.value}
            fill={getMainShapeColor(RATING_COLOR)}
            activeBar={<Rectangle fill={getActiveShapeColor(RATING_COLOR)} />}
            maxBarSize={MAX_BAR_SIZE}
            radius={BAR_RADIUS}
          />
          {data.length > 1 && (
            <Line
              type="step"
              xAxisId="composed"
              dataKey={expectationDataKey.value}
              dot={false}
              activeDot={false}
              stroke={EXPECTED_RATING_COLOR}
              strokeDasharray="5 3"
            />
          )}
          {data.length === 1 && (
            <ReferenceLine
              xAxisId="rline"
              segment={[
                { x: 0.4, y: data[0][expectationDataKey.value] },
                { x: 0.6, y: data[0][expectationDataKey.value] },
              ]}
              stroke={EXPECTED_RATING_COLOR}
              strokeDasharray="5 3"
            />
          )}
          <RechartsTooltip
            cursor={false}
            content={<ChartTooltip tooltip={Tooltip} dataKeys={[]} />}
          />
          <Legend
            content={props => (
              <DefaultLegend
                props={props}
                dataKeys={[ratingDataKey]}
                withProjection
                projectionProps={{
                  title: expectationDataKey.label,
                  color: EXPECTED_RATING_COLOR,
                  lineWidth: 13,
                  strokeDasharray: '5,3',
                }}
                justifyContent="center"
              />
            )}
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: LEGEND_PADDING_TOP,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Relative>
  )
}
