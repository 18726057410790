import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  chain,
  Ellipsis,
  Flex,
  Subheader,
  Text,
  Token,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  PerformanceReviewAnalyticsSortKeys,
  useGetPerformanceReviewAnalytics,
} from '@src/api/performance'
import { PerformanceReviewAnalyticsGroupTypes } from '@src/interfaces/performance'
import { SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import Loader from '@components/CommonSC/Loader'
import { GradeDistributionBarChart } from '@src/features/ReviewCycle/Analytics/Charts/GradeDistributionGridBarChart/BarChart'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { Country, PerformanceGradeOption } from '@src/interfaces/selectors'
import {
  groupOptions,
  GroupSelector,
} from '@src/features/ReviewCycle/Analytics/Overview/GradesDistribution/GroupSelector'
import {
  sortOptions,
  SortSelector,
} from '@src/features/ReviewCycle/Analytics/Overview/GradesDistribution/SortSelector'

interface Props {
  cycleId: string | number | undefined
  gradesOptions: PerformanceGradeOption[]
  isLoading: boolean
}

export const GradesDistribution = ({ cycleId, gradesOptions, isLoading }: Props) => {
  const [groupId, setGroupId] = useState<PerformanceReviewAnalyticsGroupTypes>(
    PerformanceReviewAnalyticsGroupTypes.Country,
  )
  const [sortId, setSortId] = useState(PerformanceReviewAnalyticsSortKeys.EmployeeCount)
  const [isAscendingSorting, sortDirectionToggler] = useToggle({ defaultState: true })

  const [sortBy, setSortBy] = useState<SortByInterface>({
    sortBy: sortId,
    direction: SORT_DIRECTION.ASC,
  })

  const { data: analyticsData = [], isLoading: isAnalyticsDataLoading } =
    useGetPerformanceReviewAnalytics(
      groupId,
      {
        cycle__id: cycleId,
      },
      [sortBy],
      { enabled: !!cycleId, refetchOnWindowFocus: false },
    )
  const { data: countriesOptions = [], isLoading: isCountriesOptionsLoading } =
    useGetSelectors<Country>(selectorKeys.countries)

  const groupOption = useMemo(
    () => groupOptions.find(({ id }) => id === groupId) ?? groupOptions[0],
    [groupId],
  )
  const sortOption = useMemo(
    () => sortOptions.find(({ id }) => id === sortId) ?? sortOptions[0],
    [sortId],
  )
  const sortKeys = useMemo(
    () =>
      groupId === PerformanceReviewAnalyticsGroupTypes.Seniority
        ? [
            PerformanceReviewAnalyticsSortKeys.EmployeeCount,
            PerformanceReviewAnalyticsSortKeys.Name,
            PerformanceReviewAnalyticsSortKeys.Seniority,
          ]
        : [
            PerformanceReviewAnalyticsSortKeys.EmployeeCount,
            PerformanceReviewAnalyticsSortKeys.Name,
          ],
    [groupId],
  )

  useEffect(() => {
    const sortDirection = isAscendingSorting ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC
    if (sortBy.sortBy === sortId && sortBy.direction === sortDirection) {
      return
    }

    setSortBy({
      sortBy: sortId,
      direction: sortDirection,
    })
  }, [sortId, isAscendingSorting])

  if (isLoading || isCountriesOptionsLoading) {
    return (
      <Widget height={300}>
        <Loader />
      </Widget>
    )
  }

  return (
    <Widget px="s-16" pb="s-16" data-testid="performance_analytics_grades_distribution">
      <VStack space="s-16">
        <Subheader>
          <Subheader.Title>
            <Flex justifyContent="center" alignItems="center" flexWrap="wrap" gap="s-2">
              {chain(
                <Ellipsis>
                  <Text
                    variant="emphasis1"
                    fontWeight={500}
                    color={Token.color.foreground}
                  >
                    <FormattedMessage
                      defaultMessage="Performance grades"
                      id="performance.grades.label"
                    />
                  </Text>
                </Ellipsis>,
                <GroupSelector value={groupOption} onSelect={id => setGroupId(id)} />,
              )}
            </Flex>
          </Subheader.Title>
          <Subheader.Side>
            <Flex justifyContent="center" alignItems="center" flexWrap="wrap" gap="s-2">
              {chain(
                <Ellipsis>
                  <Text
                    variant="emphasis1"
                    fontWeight={500}
                    color={Token.color.foreground}
                  >
                    <FormattedMessage
                      defaultMessage="Sort by"
                      id="common.sort.by.label"
                    />
                  </Text>
                </Ellipsis>,
                <SortSelector
                  value={sortOption}
                  sortKeys={sortKeys}
                  isAscending={isAscendingSorting}
                  onSelect={id => setSortId(id)}
                  onToggleSortDirection={sortDirectionToggler.switch}
                />,
              )}
            </Flex>
          </Subheader.Side>
        </Subheader>
        <GradeDistributionBarChart
          data={analyticsData}
          dataKeys={gradesOptions}
          countries={countriesOptions}
          isLoading={isAnalyticsDataLoading}
        />
      </VStack>
    </Widget>
  )
}
