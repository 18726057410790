import React, { ReactNode, useEffect, useRef, useState } from 'react'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewDataSectionInterface,
  ReviewDataValueBasedSectionInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card, CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  cultureOptions,
  getRoundedRating,
  onPrefillCultureValue,
  onPrefillWithGrade,
  shouldScrollToError,
  updateValueRating,
  usePrefillSkillsWithPreviousQuarter,
  DeliverableGradeOption,
  BehaviourGradeOption,
  CommonGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { getNormalizedCards } from '@src/utils/performance'
import { get, set } from 'lodash'
import {
  Box,
  StatusPopup,
  Token,
  useStatusPopup,
  useToggle,
  VStack,
} from '@revolut/ui-kit'
import { LoadingCard } from '@src/pages/Forms/EmployeePerformanceLayout/components/LoadingCard'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/CombinedHelp'
import { Skills } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Skills'
import {
  getSkillsRatingOptions,
  getValuesRatingOptions,
} from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'
import { getAllSkillsOverallRating } from '@src/api/performanceReview'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { useCanAccessAiInsights } from '@src/pages/Forms/EmployeePerformanceLayout/components/AiInsights/hooks/useAiInsightsAvailability'
import { PerformanceInsights } from '@src/features/AiInsights/Performance/components/SkillsOutput/PerformanceInsights'
import SideBar from '@components/SideBar/SideBar'
import {
  PerformanceOutput,
  SkillPerformanceOutputProps,
} from '@src/features/AiInsights/Performance/components/SkillsOutput/PerformanceOutput'

interface SkillsCardInterface extends CommonCardProps {
  skillsMissingJustification?: number[]
  renderTitleButton?: (field: string) => React.ReactNode
  missingDataContent?: ReactNode
  renderActions?: (
    section: ReviewDataSectionInterface | ReviewDataValueBasedSectionInterface | null,
  ) => React.ReactNode
}

export const SkillsCard = connect(
  ({
    onHelpClick,
    skillsMissingJustification,
    renderTitleButton,
    missingDataContent,
    renderActions,
  }: SkillsCardInterface) => {
    const { values, errors, initialValues } = useLapeContext<ReviewScorecardInterface>()
    const ref = useRef<HTMLDivElement>(null)
    const statusPopup = useStatusPopup()

    const [insightsSidebarOpen, insightsSidebarToggler] = useToggle()
    const [insightsProps, setInsightsProps] = useState<SkillPerformanceOutputProps>()

    const canViewSkills =
      !!values?.review_data?.functional_skills || !!values?.review_data?.manager_values

    useEffect(() => {
      const shouldScrollSkills = shouldScrollToError(
        errors,
        'review_data.functional_skills',
      )
      const shouldScrollValues = shouldScrollToError(errors, 'review_data.manager_values')
      if (shouldScrollSkills || shouldScrollValues) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data])

    const { prefillCompleted } = usePrefillSkillsWithPreviousQuarter(
      values,
      values?.review_data?.functional_skills?.cards,
    )

    const canAccessAiInsights = useCanAccessAiInsights(values)

    const calculateRating = async () => {
      // fetch data for overall rating
      const {
        data: { result },
      } = await getAllSkillsOverallRating({
        data: values.review_data.functional_skills?.cards,
        category: values.category,
        employeeId: values.reviewed_employee.id,
        scorecardId: values.id,
      })
      if (values.review_data.functional_skills) {
        values.review_data.functional_skills.rating = result
      }
    }

    useEffect(() => {
      if (
        initialValues &&
        !!initialValues.review_data?.functional_skills &&
        !initialValues.review_data.functional_skills.rating
      ) {
        calculateRating()
      }
    }, [initialValues])

    // pre-filling manager values, if needed
    useEffect(() => {
      if (values.category === ReviewCategory.Upwards) {
        return
      }
      if (
        values.review_data.manager_values?.cards?.[0].sections[0] &&
        // if first found section has value, then all cards were already pre-filled
        !values.review_data.manager_values.cards[0].sections[0].value
      ) {
        values.review_data.manager_values?.cards?.forEach((card, cardIndex) => {
          card.sections.forEach(section => {
            section.value =
              section.previous_values?.[0]?.value || AssessBehaviourButtonTypes.neutral
          })
          updateValueRating({
            values,
            path: `review_data.manager_values.cards.${cardIndex}`,
          })
        })
      }
    }, [values.review_data.manager_values?.cards])

    if (!canViewSkills && !missingDataContent) {
      return null
    }

    const hasManagerValues = !!values.review_data?.manager_values

    const managerValuesCards = values?.review_data?.manager_values?.cards || []
    const managerValuesFields =
      managerValuesCards.map((card, ind) => {
        const field = `review_data.manager_values.cards.${ind}`
        return {
          field,
          title: card.name,
          titleButton: renderTitleButton?.(field),
          grades: getValuesRatingOptions(
            cultureOptions,
            ind,
            values.review_data.manager_values?.cards,
          ) as CommonGradeOption[],
          cardIndex: ind,
          cardJustification: card.justification || null,
        }
      }) || []
    const functionalSkillsCards = getNormalizedCards(
      values.review_data?.functional_skills?.cards || [],
    )
    const functionalSkillsFields = functionalSkillsCards.map((card, ind) => {
      const field = `review_data.functional_skills.cards.${ind}`
      return {
        field,
        title: card.name,
        titleButton: renderTitleButton?.(field),
        grades: getSkillsRatingOptions(ind, values.review_data?.functional_skills?.cards),
        cardIndex: ind,
        cardJustification: card.justification || null,
      }
    })
    const skillsFields = hasManagerValues ? managerValuesFields : functionalSkillsFields

    const onSelectGrade = <T extends DeliverableGradeOption | BehaviourGradeOption>(
      grade: T,
      field: CardField,
    ) => {
      if (hasManagerValues) {
        onPrefillCultureValue(values, grade, field.field)
        updateValueRating({ values, path: field.field })
      } else {
        const currentRating = get(values, `${field.field}.rating`)
        set(values, `${field.field}.rating`, grade.key)
        try {
          onPrefillWithGrade(
            values,
            grade,
            PerformanceReviewTypes.skills,
            field.cardIndex,
          )
        } catch {
          set(values, `${field.field}.rating`, currentRating)
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Could not recalculate rating</StatusPopup.Title>
            </StatusPopup>,
          )
        }

        calculateRating()
      }
    }

    const onInsightsDetailsClick = (props: SkillPerformanceOutputProps) => {
      setInsightsProps(props)
      insightsSidebarToggler.on()
    }

    if (!prefillCompleted) {
      return <LoadingCard />
    }

    const isSelfReview = values.reviewer_relation === ReviewerRelation.Self
    const finalRating = !isSelfReview
      ? values.review_data.functional_skills?.rating
      : null

    return (
      <>
        <Card
          data={values}
          renderExpandedContent={expContentField => {
            const skillId: number | undefined = get(
              values,
              `review_data.functional_skills.cards.${expContentField.cardIndex}.skill_id`,
            )

            return (
              <VStack space="s-16">
                {canAccessAiInsights && values.cycle && skillId !== undefined && (
                  <Box radius="r16" backgroundColor={Token.color.widgetBackground}>
                    <PerformanceInsights
                      employeeId={values.reviewed_employee.id}
                      cycleId={Number(values.cycle.id)}
                      skillId={skillId}
                      onDetailsClick={onInsightsDetailsClick}
                    />
                  </Box>
                )}
                <Skills
                  selectedField={expContentField}
                  skillsMissingJustification={null}
                />
              </VStack>
            )
          }}
          renderExceedingContent={excContentField => {
            return (
              <Skills
                selectedField={excContentField}
                justificationOnly
                skillsMissingJustification={skillsMissingJustification || null}
              />
            )
          }}
          type={CardContentTypes.SKILLS}
          title={hasManagerValues ? 'Management behaviours' : 'Skills'}
          finalRating={finalRating ? PerformanceRatingTitle[finalRating] : undefined}
          icon="Palette"
          fields={skillsFields}
          isGradeSelectedRule={(field, grade) => {
            const ratingValue = get(values, field)?.rating
            if (!ratingValue) {
              return false
            }
            return hasManagerValues
              ? ratingValue === grade.key
              : getRoundedRating(ratingValue) === grade.key
          }}
          onSelectDeliverableGrade={onSelectGrade}
          onSelectBehaviourGrade={onSelectGrade}
          justification={
            values?.review_data?.manager_values?.skipped_section_justification ||
            values?.review_data?.functional_skills?.skipped_section_justification
          }
          headerRef={ref}
          onHelpClick={onHelpClick ? () => onHelpClick(HelpTabs.Skills) : undefined}
          additionalInfo={canViewSkills ? undefined : missingDataContent}
          actions={renderActions?.(
            hasManagerValues
              ? values.review_data.manager_values || null
              : values.review_data.functional_skills || null,
          )}
        />
        {insightsProps && (
          <SideBar
            isOpen={insightsSidebarOpen}
            title={insightsProps.skill.name}
            onClose={insightsSidebarToggler.off}
          >
            <PerformanceOutput {...insightsProps} />
          </SideBar>
        )}
      </>
    )
  },
)
