import { useMemo } from 'react'
import { IconName, Color } from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { UpdateTypes } from '@src/interfaces/kpis'

import { updateTypeOptionMap } from './updateTypeOptionMap'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { useGetRoadmapSettings } from '@src/api/settings'

export interface MetricTypeSelectorValue {
  name?: string
  description?: string
  image?: ImageProp
  icon?: IconName
  iconBackground?: Color
  connected?: boolean
  id?: UpdateTypes
}

interface Selector extends IdAndName<UpdateTypes> {
  value: MetricTypeSelectorValue
}

export const useMetricTypeSelector = (): {
  data: Selector[]
  refetch: ReturnType<typeof useGetSelectors>['refetch']
} => {
  // selector returns all connected types
  const {
    data: connectedSelectors,
    isLoading,
    refetch,
  } = useGetSelectors<IdAndName<UpdateTypes>>(selectorKeys.goal_target_types)

  const featureFlags = useSelector(selectFeatureFlags)

  const { data: roadmapsSettings, isLoading: isRoadmapsLoading } = useGetRoadmapSettings()

  const permissions = useSelector(selectPermissions)

  const parsed: Selector[] = useMemo(() => {
    const connectedMap = new Map()
    connectedSelectors?.forEach(selector => connectedMap.set(selector.id, selector))

    return (
      Object.keys(updateTypeOptionMap) as Array<keyof typeof updateTypeOptionMap>
    ).reduce((acc: Selector[], key) => {
      const requiredInregrationPermission =
        updateTypeOptionMap[key].requiredIntegrationPermission
      const requiredFeatureFlag = updateTypeOptionMap[key].featureFlag

      // show as connected
      if (connectedMap.get(key)) {
        // do not show JIRA if it's connected but disabled in the settings
        if (key === UpdateTypes.roadmap && !roadmapsSettings?.jira_epics_enabled) {
          return acc
        }
        // do not show ClickUp if it's connected but disabled in the settings
        if (key === UpdateTypes.clickup && !roadmapsSettings?.clickup_enabled) {
          return acc
        }

        if (key === UpdateTypes.linear && !roadmapsSettings?.linear_enabled) {
          return acc
        }

        if (key === UpdateTypes.notion && !roadmapsSettings?.notion_enabled) {
          return acc
        }

        acc.push({
          id: key,
          name: key,
          value: {
            ...updateTypeOptionMap[key],
            id: key,
            connected: true,
          },
        })
        // check if integration permission required and if user has them
      } else if (
        (requiredInregrationPermission
          ? permissions.includes(requiredInregrationPermission)
          : true) &&
        (requiredFeatureFlag ? featureFlags.includes(requiredFeatureFlag) : true)
      ) {
        acc.push({
          id: key,
          name: key,
          value: { ...updateTypeOptionMap[key], id: key, connected: false },
        })
      }

      return acc
    }, [])
  }, [connectedSelectors, roadmapsSettings?.jira_epics_enabled])

  return { data: isLoading || isRoadmapsLoading ? [] : parsed, refetch }
}
