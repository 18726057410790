import { FieldOptions, IdAndName } from './index'
import { Currency, OptionInterface } from '@src/interfaces/selectors'
import { PerformanceCycleInterface } from '@src/interfaces/performance'
import { SeniorityInterface, SenioritySublevelInterface } from '@src/interfaces/seniority'
import { CustomFieldsEntityInterface } from './customFields'
import { LaptopDeliveryOption, OnboardingProcessInterface } from './onboarding'
import { GoalWeightMode } from './goals'
import { ImportSessionStatsInterface } from '@src/interfaces/bulkDataImport'
import { OfficeSuiteProviderType } from '@src/interfaces/settings'
import { ProcessStages } from '@src/interfaces/onboarding'
import { EmployeeEventDetailsInterface } from './employeeEvents'
import { PerformanceInsightsGroupInterface } from '@src/features/AiInsights/Performance/interfaces/insights'

export enum IdStatuses {
  hired = 'hired',
  not_hired = 'not_hired',
  refused = 'offer_refused',
  active = 'active',
  inactive = 'inactive',
  failed = 'failed',
  completed = 'completed',
  pending = 'pending',
  onboarding = 'onboarding',
  terminated = 'terminated',
  cancelled = 'cancelled',
}

export interface NameIdInterface {
  id: number
  name: string
}

export interface EmployeeStatusInterface {
  id: IdStatuses
  name: string
}

export interface TerminationReasonTypeInterface {
  id: string
  name: string
}

export interface EmployeeOptionInterface {
  id: number
  name?: string
  full_name?: string
  first_name?: string
  display_name?: string
  status?: IdStatuses
  avatar?: AvatarType
  employee_type?: EmployeeType
  job_title?: string
  line_manager?: EmployeeOptionInterface
  seniority?: SeniorityInterface
  specialisation?: IdAndName
}

export interface LocationInterface {
  id: number
  location_name: string
  country_id?: string
  name: string
  currency_id: string | null
  hr_manager: EmployeeOptionInterface | null
  timezone?: {
    id: string
    name: string
    offset: string
  } | null
}

export enum Genders {
  male = 'male',
  female = 'female',
  other = 'other',
  prefer_not_to_say = 'prefer_not_to_say',
}

export interface EmployeeStats {
  pending_document_count: number
  culture_enps: number
  culture_manager_enps: number
  culture_values: number
  culture_compliance: number
  culture_job_satisfaction: number
  document_count: number
  upcoming_changes_count: number
  all_ownership_items_count: number
  direct_reports_count: number
  functional_reports_count: number
  jira_issues_count: number
  key_person_count: number
  owned_departments_count: number
  owned_teams_count: number
  owned_vendors_count: number
  owned_data_items_count: number
  kpi_performance_percent: number
  recruiter_requisition_count: number
  line_manager_requisition_count: number
  line_manager_requisition_headcount: number
  roadmap_progress_percent: number
  audits_critical: number
  audits_exceptional?: number
  audits_recommended?: number
  audits_trivial?: number
  kris_above_level_1: number
  kris_above_level_2: number
  kris_above_level_3: number
  direct_reports_nips: number
  pending_time_off_request_count: number
  dashboard_count: number
  recruiter_job_posting_count: number
  recruiter_interview_round_count: number
  hiring_manager_interview_round_count: number
  owner_offer_form_count: number
  goals_progress_percent?: number
}

export interface EmployeeGoal {
  id?: number
  name: string
  description?: string
  creation_date_time?: string
  update_date_time?: string
  deadline_date_time?: Date | null
  employee?: { id: string }
  review_cycle?: { id: string } | null
  category?: string
}

export interface PersonalInfoInterface {
  address_line_1: string
  address_line_2: string
  address_line_3: string
  birth_date: string
  city: string
  country: OptionInterface
  county: string
  emergency_contact_email: string
  emergency_contact_full_name: string
  emergency_contact_mobile_phone: string
  emergency_contact_relationship: string
  employee: EmployeeInterface
  field_options: FieldOptions
  gender: Genders
  id: number
  id_number: string
  languages: NameIdInterface[]
  marital_status: OptionInterface
  national_insurance_number: string
  nationality: NationalityInterface
  nationalities: NationalityInterface[]
  passport_number: string
  personal_email: string
  phone_number: string | undefined
  post_code: string
  visa_expiration_date: string
  visa_number: string
  custom_fields?: CustomFieldsEntityInterface
  laptop_delivery: LaptopDeliveryOption | null | undefined
  laptop_delivery_address_line_1: string | null
  laptop_delivery_address_line_2: string | null
  laptop_delivery_address_line_3: string | null
  laptop_delivery_city: string | null
  laptop_delivery_country: IdAndName | null
  laptop_delivery_county: string | null
  laptop_delivery_post_code: string | null
  onboarding_process: OnboardingProcessInterface
  current_onboarding_stage: IdAndName<ProcessStages>
}

export type EmployeeLaptopDelivery = Pick<
  PersonalInfoInterface,
  | 'laptop_delivery'
  | 'address_line_1'
  | 'address_line_2'
  | 'address_line_3'
  | 'post_code'
  | 'city'
  | 'county'
  | 'country'
  | 'laptop_delivery_address_line_1'
  | 'laptop_delivery_address_line_2'
  | 'laptop_delivery_address_line_3'
  | 'laptop_delivery_post_code'
  | 'laptop_delivery_city'
  | 'laptop_delivery_county'
  | 'laptop_delivery_country'
  | 'field_options'
>

export enum SalarySacrificeStatuses {
  Pending = 'pending',
  Active = 'active',
  Expired = 'expired',
}

export interface TerminationInterface {
  id?: number
  termination_date_time: string | null
  last_day_of_contract: string | null
  termination_type: TerminationReasonTypeInterface | null
  termination_reason: TerminationReasonTypeInterface | null
  additional_payment_amount?: number
  additional_payment_currency?: { id: number; name: string; iso_code: string }
  additional_payment_type?: OptionInterface
  employee?: EmployeeInterface
  lump_sum_payment_type?: OptionInterface
  lump_sum_salary_type?: OptionInterface
  lump_sum_time_unit?: OptionInterface
  lump_sum_time_units_number?: number
  pilon_salary_type?: OptionInterface
  pilon_time_unit?: OptionInterface
  pilon_time_units_number?: number
  termination_date_timezone?: OptionInterface
  uploaded_documents: OptionInterface[]
  field_options: FieldOptions
}

export interface PayrollInterface {
  id: number
  bank_name: string
  account_name: string
  bank_currency: { id: number; name: string }
  custom_fields?: CustomFieldsEntityInterface
  field_options: FieldOptions
}

export interface DiversityInterface {
  id: number
  gender: OptionInterface
  sexual_orientation: OptionInterface
  ethnicities: OptionInterface[]
  age_range: OptionInterface
  carer_types: OptionInterface[]
  disabilities: OptionInterface[]
  educational_background: OptionInterface
  religion: OptionInterface
  socioeconomic_background: OptionInterface & { subtitle: string }
  field_options: FieldOptions
}

// TODO: move to interfaces/contracts
export enum ContractTypes {
  FullTime = 'full_time',
  PartTime = 'part_time',
  ZeroHourContractor = 'zero_hour_contractor',
  Consultant = 'consultant',
  BoardAdvisor = 'board_advisor',
  NonExecutiveDirector = 'non_executive_director',
  Intern = 'intern',
  EmployeeThroughPEO = 'employee_through_peo',
  SelfEmployedContractor = 'self_employed_contractor',
  Contractor = 'contractor',
  AgencyWorker = 'agency_worker',
}

// TODO: move to interfaces/contracts
export enum ContractStatuses {
  Pending = 'pending',
  PendingApproval = 'pending_approval',
  PendingCompletion = 'pending_completion',
  Active = 'active',
  Inactive = 'inactive',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Terminated = 'terminated',
}

export interface Badge {
  id: number
  name: string
  url: string
}

export type EmployeeBadge = {
  id: number
  name: string
  employee_badge: Badge
}

export interface EmployeeProbationAdditionalSettings {
  id: number
  hr_manager: EmployeeOptionInterface
  category: string
  end_date_time: string
  name: string
  start_date_time: string
}

// TODO: move to interfaces/contracts
interface ContractInterface<T, L> {
  id: number
  contract_type: T
  contract_status: { id: ContractStatuses; name: string }
  start_date: string
  end_date: string
  company_entity: {
    description: string
    id: number
    name: string
  }
  location: L
  pay_group:
    | {
        company_entity: OptionInterface
        country: OptionInterface
        id: number
        name: string
      }
    | undefined
  salary_currency?: Currency | null
  salary_amount: string
  salary_time_unit: OptionInterface
  salary_payment_frequency: OptionInterface
  sign_on_bonus_type: IdAndName<'shares' | 'cash' | 'no_bonus'>
  sign_on_bonus_amount: number
  sign_on_bonus_currency: IdAndName<number> & { iso_code: string }
  specialisation: OptionInterface | null
  contract_term: OptionInterface
  position: OptionInterface
  seniority: SeniorityInterface | undefined
  specialisation_seniority_sublevel: SenioritySublevelInterface | undefined
  title?: string
  weekly_working_hours: string
  full_time_equivalent: string
  annual_salary: number
  notice_period_during_probation: number
  notice_period_during_probation_unit: IdAndName<string>
  notice_period_after_probation: number | null
  notice_period_after_probation_unit: IdAndName<string>
  inactivity_reason: OptionInterface
  inactivity_start_date: string
  inactivity_end_date: string
  is_primary_contract: boolean
  link_to_justification?: string
  uploaded_documents: IdAndName[]
  field_options: FieldOptions
  reason_for_change_required: boolean
  reason_for_change?: string
  warnings?: Record<string, string>
  is_expiring_soon?: boolean
  is_starting_soon_and_not_finalised?: boolean
}

export interface EmployeeContractsInterface
  extends ContractInterface<EmployeeContractType, LocationInterface> {
  employee: EmployeeOptionInterface
  owner?: EmployeeOptionInterface
}

export interface EmployeeContractsStatsInterface {
  total: number
  active: number
  pending: number
}

interface DocumentType {
  id: 'passport' | 'passport_and_visa' | 'national_id' | 'birth_certificate'
  name: string
}

export interface RightToWorkType {
  id: 'temporary_residence' | 'indefinite_residence' | 'citizen'
  name: string
}

export interface RightToWorkInterface {
  id: number
  document_type: DocumentType
  document_number: string
  document_expiry_date: string
  document_valid_from_date: string
  last_verified_on_date: string
  last_verified_by: EmployeeOptionInterface
  visa_number: string
  visa_expiry_date: string
  uploaded_documents: OptionInterface
  country: IdAndName & {
    country_code: string
  }
  citizenship_country: OptionInterface
  field_options: FieldOptions
  right_to_work_type: RightToWorkType
  status: {
    id: 'incomplete' | 'complete' | 'expired'
    name: string
  }
  temporary_residence_type: OptionInterface
  is_sponsored_by_company: boolean
}

export interface ContractorInterface {
  id: number
  status: IdAndName<IdStatuses>
  first_name: string
  last_name: string
  middle_name: null
  display_name: string
  email: string
  contract_type: {
    employee_type: 'external'
  } & OptionInterface
  employee_type: {
    id: 'external'
    name: string
  }
  specialisation: OptionInterface | null
  seniority?: SeniorityInterface
  team: {
    department_id: number
  } & NameIdInterface
  line_manager: EmployeeOptionInterface
  quality_control: EmployeeOptionInterface | null
  location: LocationInterface
  entity: NameIdInterface | null
  joining_date_time: string
  end_of_probation_date_time?: string
  termination?: {
    termination_date_time?: string | null
    termination_date_timezone?: OptionInterface
    termination_type?: TerminationReasonTypeInterface | null
  }
  info?: string | null
  field_options: FieldOptions
  probation_template?: ProbationTemplateOptionInterface
}

export interface ProbationTemplateOptionInterface {
  id: number
  name: string
  amount_of_months?: number
  is_eligible?: boolean
}

export type EmployeeType = 'internal' | 'external'

export type EmployeeContractType = {
  employee_type?: EmployeeType
  description?: string
} & OptionInterface

export interface EmployeeBasicInterface {
  id: number
  full_name: string
  display_name: string
  status: IdStatuses
  name: string
  avatar?: AvatarType
  employee_type: EmployeeType
}

export type AvatarType =
  | {
      image_72: string
      image_192: string
      default: string
    }
  | string
  | null

export interface EmployeeInterface {
  id: number
  user_id: number
  user_uuid?: string
  email: string
  full_name: string
  display_name: string
  answered_engagement_question_on?: string | null
  first_name: string
  effective_date_time?: string
  middle_name: string | null
  job_title: string
  last_name: string
  avatar: AvatarType
  employee_type: EmployeeType
  team:
    | (NameIdInterface & {
        department_id?: number
      })
    | null
  position: OptionInterface
  specialisation: OptionInterface | null
  seniority?: SeniorityInterface
  specialisation_seniority_sublevel?: SenioritySublevelInterface
  line_manager: EmployeeOptionInterface
  location: LocationInterface
  quality_control: EmployeeOptionInterface | null
  mentor: EmployeeOptionInterface | null
  joining_date_time: string
  status: { id: IdStatuses; name: string }
  contract_type: EmployeeContractType
  end_of_probation_date_time: string
  contract_expiration_date_time: string | null
  lifecycle_ticket_url?: string | null
  link_to_relocation_status: string | null
  field_options: FieldOptions
  extra: {
    hibob_id?: string
    probation_score?: number
    performance_score?: number
  } | null
  info?: string | null
  personal_info?: {
    id: number
    personal_email: string
    gender: Genders
    birth_date: string
    phone_number: string
    nationality: string
    languages: NameIdInterface[]
    passport_number: string
    id_number: string
    national_insurance_number: string
    post_code: string
    city: string
    country: string
    address: string
    emergency_contact_full_name: string
    emergency_contact_email: string
    emergency_contact_mobile_phone: string
    emergency_contact_relationship: string
  }
  kpi_weight_mode: {
    id: string
    name: string
  }
  event?: EmployeeEventDetailsInterface
  performance_cycles?: PerformanceCycleInterface[]
  entity: NameIdInterface | null
  has_termination?: boolean
  should_check_profile_for_performance_review?: boolean
  is_requisition?: boolean
  custom_fields?: CustomFieldsEntityInterface
  is_regulated?: boolean
  candidate_id?: number | null
  resignation?: number | null
  anonymized?: {
    anonymized_at?: string
    is_anonymous?: boolean
    is_shadow?: boolean
    object_id?: number
  }
  probation_template?: ProbationTemplateOptionInterface | null
  average_score?: number | null
  goal_weight_mode?: IdAndName<GoalWeightMode, string>
  requisition_id?: number | null
  performance_insights_group: PerformanceInsightsGroupInterface | null
}

export type InternalOrExternalEmployee = EmployeeInterface | ContractorInterface

export const EMPLOYEE_PROFILE_SUB_SECTIONS = {
  BASIC_INFO: '182b6a9e-ae14-423e-a567-3eacb58f0683',
  WORK: '97aaae07-e492-44b5-a114-7d4a8dbdd0d5',
  NOTES: '3e73e99b-89b9-4893-9215-5e9698eb1482',
  BIO: '093a0d4c-310b-4501-b436-6a29dd8f68da',
  ID: '1dc1e8d9-4cd5-441e-85c7-e114dc24f725',
  CONTACT_INFO: '55d26e96-46df-4a66-8f14-084e13f98f20',
  ABOUT_YOU: '0044b0f2-a82d-47f6-b7f5-ff5c6de7adc1',
  BANK_DETAILS: 'c7d7cea5-3ce2-4c68-9f40-2bcc7dba9fb0',
  CORRESPONDENCE_ADDRESS: '2b0eb934-7f23-41e3-ae65-c6d6c50da4a2',
  IDENTIFICATION_DETAILS: '1dc1e8d9-4cd5-441e-85c7-e114dc24f725',
  CONTRACTS: '48c4585d-38cf-4855-97fe-098cc93d6174',
} as const

export type EmployeeProfileSubSectionsKeys = keyof typeof EMPLOYEE_PROFILE_SUB_SECTIONS

export type EmployeeProfileSubSections =
  typeof EMPLOYEE_PROFILE_SUB_SECTIONS[EmployeeProfileSubSectionsKeys]

export const CUSTOM_FIELD_SECTIONS = {
  EMPLOYEE_PROFILE: '4fb95bbb-c887-4fcf-a3be-873f1463b607',
} as const

export type CustomFieldSectionKeys = keyof typeof CUSTOM_FIELD_SECTIONS

export type CustomFieldSections = typeof CUSTOM_FIELD_SECTIONS[CustomFieldSectionKeys]

export interface EmployeeSettingsInterface {
  id?: number
  email_integration_enabled: boolean
  calendar_integration_enabled: boolean
  field_options: FieldOptions
  office_suite_provider: OfficeSuiteProviderType | null
  consent_to_interview_ai_summarisation: boolean | null
}

export interface EmployeePositionHistoryItem {
  id: number
  start_date_time: string
  end_date_time: string | null
  seniority: IdAndName
  specialisation_seniority_sublevel: IdAndName | null
  position: IdAndName
}

export type InactivityReason =
  | 'sick_leave'
  | 'parental_leave'
  | 'sabbatical_leave'
  | 'other'

export interface EmployeeInactivityInterface {
  inactivity_reason?: IdAndName<InactivityReason> & { will_revoke_access?: boolean }
  inactivity_start_date?: string
  inactivity_end_date?: string
}

export interface EmployeeStatusTransitionsInterface extends EmployeeInactivityInterface {
  status: IdAndName<IdStatuses>
  status_transitions: OptionInterface[]
}

export interface NationalityInterface {
  id: number
  name: string
  country_code: string
}

export interface EmployeeBioInterface {
  id: number
  birth_date: string
  hide_birthday: boolean | null
  employee: EmployeeOptionInterface
  languages: NameIdInterface[]
  legal_sex: IdAndName<string>
  marital_status: OptionInterface
  nationality: NationalityInterface
  nationalities: NationalityInterface[]
  custom_fields: CustomFieldSections
  field_options: FieldOptions
}

export interface EmployeeContactsInterface {
  id: number
  personal_email: string
  phone_number: string
  address_line_1: string | null
  address_line_2: string | null
  address_line_3: string | null
  post_code: string
  city: string
  county: string
  country: IdAndName<string>
  custom_fields: CustomFieldSections
  field_options: FieldOptions
  region: IdAndName<string> | null
}

export interface EmployeeLaptopDeliveryAddressInterface {
  id: number
  laptop_delivery: IdAndName<'ship_to_my_address' | 'ship_to_different_address'>
  laptop_delivery_address_line_1: string | null
  laptop_delivery_address_line_2: string | null
  laptop_delivery_address_line_3: string | null
  laptop_delivery_post_code: string
  laptop_delivery_city: string
  laptop_delivery_county: string
  laptop_delivery_country: IdAndName<string>
  custom_fields: CustomFieldSections
  field_options: FieldOptions
}

export interface EmployeeEmergencyContactInterface {
  id: number
  employee: EmployeeOptionInterface
  emergency_contact_full_name: string
  emergency_contact_relationship: string
  emergency_contact_email: string
  emergency_contact_mobile_phone: string
  custom_fields: CustomFieldSections
  field_options: FieldOptions
}

export interface EmployeeAboutInterface {
  id: number
  about: string
  field_options: FieldOptions
}

export interface JiraLifecycleTicketCreationInterface {
  status: 'success' | 'error'
  result: {
    ticket?: string
  }
  message?: string
}

export interface EmployeesSimpleInterface {
  id: number
  first_name: string
  last_name: string
  full_name: string
  email: string
  line_manager: EmployeeOptionInterface
  location: IdAndName
  team: IdAndName
  position: IdAndName
  specialisation: IdAndName
  seniority: IdAndName
  languages: string | null
  nationalities: string | null
  access_groups: { group_id: number; group_name: string }[]
  status: IdAndName<IdStatuses>
}

export interface EmployeesSimpleStatsInterface {
  total: number
  active: number
  hired: number
  pending: number
  onboarding: number
  terminated: number
  active_externals: number
}

export interface EmployeeSimpleStatsFormattedInterface
  extends EmployeesSimpleStatsInterface,
    ImportSessionStatsInterface {}

export interface EmployeesSimpleCreateInterface {
  id: number
  first_name: string
  last_name: string
  email: string
  team: IdAndName<string | number>
  position: IdAndName
  specialisation: IdAndName<string | number>
  seniority: IdAndName
  access_groups: IdAndName
}

export interface EmployeeWorkAndOrgDetailsInterface {
  id: number
  contract_term: IdAndName<string> | null
  employee: EmployeeOptionInterface
  effective_date_time: string | null
  notice_period_after_probation: number | null
  notice_period_after_probation_unit?: IdAndName<string> | null
  notice_period_during_probation: number | null
  notice_period_during_probation_unit: IdAndName<string> | null
  salary_amount: string | null
  salary_currency: Currency | null
  salary_payment_frequency: IdAndName | null
  sign_on_bonus_amount: number | null
  sign_on_bonus_currency: Currency | null
  sign_on_bonus_type: IdAndName<'shares' | 'cash' | 'no_bonus'> | null
  weekly_working_hours: number | null
}

export interface EmployeesStatsInterface {
  active: number
  external: number
  hired: number
  internal: number
  onboarding: number
  pending: number
  total: number
}
