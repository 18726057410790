import React from 'react'
import {
  Bar,
  BarChart,
  Legend,
  Rectangle,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Relative, Token } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { ChartEmptyResults } from '@src/pages/Forms/QueryForm/components/Charts/ChartEmptyResults'
import { ResponsiveContainer } from '@src/pages/Forms/QueryForm/components/Charts/ResponsiveContainer'
import { DefaultLegend } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultLegend'
import {
  getActiveShapeColor,
  getAxisWidth,
  getMainShapeColor,
  getTicks,
} from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import {
  NumericMetricHistoryDataKey,
  NumericMetricHistoryItemData,
} from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/NumericMetricHistoryChart/ChartData'
import { METRIC_COLOR } from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/NumericMetricHistoryChart/hooks/useChartData'
import {
  ChartTooltip,
  ChartTooltipVariants,
} from '@src/pages/Forms/QueryForm/components/Charts/components/ChartTooltip'
import { DefaultTooltip } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultTooltip'
import { MetricDetailsVariants } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/constants'

const CHART_HEIGHT = 200
const LEGEND_PADDING_TOP = '8px'
const TICK_COUNT = 3
const X_AXIS_TICK_MARGIN = 8
const Y_AXIS_TICK_MARGIN = 20
const BAR_RADIUS = 2

interface Props {
  dataKey: NumericMetricHistoryDataKey
  data: NumericMetricHistoryItemData[]
  variant: MetricDetailsVariants
  isLoading: boolean
}

export const NumericMetricHistoryBarChart = ({
  data,
  dataKey,
  variant,
  isLoading,
}: Props) => {
  const yAxisValues = data.map(datum => Number(datum[dataKey.value]))
  const yAxisDomain =
    variant === MetricDetailsVariants.Default ? [0, Math.max(...yAxisValues)] : [0, 1]

  const yAxisTickFormatter = (value: number) =>
    variant === MetricDetailsVariants.Default ? String(value) : formatPercentage(value)
  const yAxisTicks = getTicks(yAxisValues, TICK_COUNT, 'bar')
  const yAxisWidth = getAxisWidth(yAxisTicks, yAxisTickFormatter) - Y_AXIS_TICK_MARGIN

  const tooltipVariant =
    variant === MetricDetailsVariants.Default
      ? ChartTooltipVariants.Default
      : ChartTooltipVariants.Percentage

  if (isLoading) {
    return <ChartSkeleton height={CHART_HEIGHT} />
  }

  if (!data.length) {
    return <ChartEmptyResults height={CHART_HEIGHT} />
  }

  return (
    <Relative width="100%" height="100%">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} layout="horizontal">
          <XAxis
            dataKey="label"
            axisLine={false}
            tickLine={false}
            tickMargin={X_AXIS_TICK_MARGIN}
          />
          <YAxis
            type="number"
            domain={yAxisDomain}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            tickCount={TICK_COUNT}
            tick={{ color: Token.color.greyTone50, fontSize: 12 }}
            tickSize={0}
            tickFormatter={yAxisTickFormatter}
            orientation="right"
            width={yAxisWidth}
          />
          <Bar
            key={dataKey.id}
            dataKey={String(dataKey.value)}
            fill={getMainShapeColor(METRIC_COLOR)}
            activeBar={<Rectangle fill={getActiveShapeColor(METRIC_COLOR)} />}
            radius={BAR_RADIUS}
          />
          <RechartsTooltip
            cursor={false}
            content={
              <ChartTooltip
                tooltip={DefaultTooltip}
                dataKeys={[dataKey]}
                variant={tooltipVariant}
              />
            }
          />
          <Legend
            content={props => (
              <DefaultLegend props={props} dataKeys={[dataKey]} justifyContent="center" />
            )}
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: LEGEND_PADDING_TOP,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Relative>
  )
}
