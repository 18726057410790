import React from 'react'
import { MeetingsSummaryInterface } from '@src/interfaces/meetings'
import { Summary } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/Feedback/OneToOnes/Summary/Summary'

interface Props {
  summary: MeetingsSummaryInterface
  managerId: number
}

export const OneToOnes = ({ summary, managerId }: Props) => {
  return <Summary summary={summary} managerId={managerId} />
}
