import { useMemo } from 'react'
import {
  PerformanceInsightsGroupInterface,
  SkillOneToOneInsightsInterface,
  SkillPerformanceInsightsInterface,
} from '@src/features/AiInsights/Performance/interfaces/insights'
import { useGetEmployee } from '@src/api/employees'
import {
  useGetEmployeeSkillOneToOneInsights,
  useGetEmployeeSkillPerformanceInsights,
} from '@src/features/AiInsights/Performance/api/insights'
import { useGetSkill } from '@src/api/skills'
import { SkillInterface } from '@src/interfaces/skills'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { useGetTalentSkillRatingHistory } from '@src/features/ReviewCycle/Analytics/api/skills'
import { TalentSkillRatingInterface } from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { useGetGrowthPlan } from '@src/api/growthPlans'
import { useGetEmployeeMeetings } from '@src/api/meetings'
import { insightsGroupModel } from '@src/features/AiInsights/Performance/models/insightsGroupModel'
import { InternalMetricSources } from '@src/features/AiInsights/Performance/interfaces/metrics'

interface Props {
  aiInsights: SkillPerformanceInsightsInterface | null
  insightsGroup: PerformanceInsightsGroupInterface | null
  skill: SkillInterface | null
  skillRatingHistoryData: TalentSkillRatingInterface[]
  growthPlan: GrowthPlanInterface | null
  oneToOneInsights: SkillOneToOneInsightsInterface[]
  oneToOneMeeting: OneToOneMeeting | null
  isLoading: boolean
}

export const usePerformanceInsights = (
  employeeId: number,
  cycleId: number,
  skillId: number,
): Props => {
  const { data: employee, isLoading: isEmployeeLoading } = useGetEmployee(employeeId)
  const { data: skill, isLoading: isSkillLoading } = useGetSkill(skillId)

  const { data: aiInsights, isLoading: isInsightsLoading } =
    useGetEmployeeSkillPerformanceInsights(employeeId, cycleId, skillId)
  const { data: skillRatingHistoryData, isLoading: isSkillRatingHistoryDataLoading } =
    useGetTalentSkillRatingHistory(employeeId, skillId)
  const { data: growthPlan, isLoading: isGrowthPlanLoading } = useGetGrowthPlan(
    aiInsights?.growth_plan?.id,
  )
  const { data: oneToOneInsights, isLoading: isOneToOneInsightsLoading } =
    useGetEmployeeSkillOneToOneInsights(employeeId, aiInsights?.id, {
      enabled:
        !!employee?.performance_insights_group &&
        insightsGroupModel.hasSourceEnabled(
          employee.performance_insights_group,
          InternalMetricSources.OneToOnes,
        ),
    })
  const [anyOneToOneInsight] = oneToOneInsights ?? []
  const { data: oneToOneMeetings, isLoading: isOneToOneMeetingsLoading } =
    useGetEmployeeMeetings(
      employeeId,
      {
        employee_id: employeeId,
        manager_id: anyOneToOneInsight?.feedback_item.manager?.id,
      },
      {
        enabled: !!anyOneToOneInsight?.feedback_item.manager?.id,
      },
    )

  return useMemo<Props>(
    () => ({
      insightsGroup: employee ? employee.performance_insights_group : null,
      aiInsights: aiInsights ?? null,
      skill: skill ?? null,
      growthPlan: growthPlan ?? null,
      skillRatingHistoryData: skillRatingHistoryData ?? [],
      oneToOneInsights: oneToOneInsights ?? [],
      oneToOneMeeting:
        oneToOneMeetings?.results?.length === 1 ? oneToOneMeetings.results[0] : null,
      isLoading:
        isInsightsLoading ||
        isEmployeeLoading ||
        isSkillLoading ||
        isSkillRatingHistoryDataLoading ||
        isGrowthPlanLoading ||
        isOneToOneInsightsLoading,
    }),
    [
      employee?.id,
      aiInsights?.id,
      skill?.id,
      growthPlan?.id,
      isInsightsLoading,
      isEmployeeLoading,
      isSkillLoading,
      isSkillRatingHistoryDataLoading,
      isGrowthPlanLoading,
      isOneToOneInsightsLoading,
      isOneToOneMeetingsLoading,
    ],
  )
}
