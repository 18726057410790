import React from 'react'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { SkillInterface, SkillType } from '@src/interfaces/skills'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Group, InputGroup, MoreBar, Subheader, useToggle } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import SettingsButtons from '@src/features/SettingsButtons'
import { PageBody } from '@components/Page/PageBody'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@components/Page/PageActions'
import SaveDraftButton from '@src/pages/Forms/SkillForm/Buttons/SaveDraftButton'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { navigateReplace } from '@src/actions/RouterActions'
import SkillExamples from '@src/pages/Forms/SkillForm/Examples'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { useGetSkillsSettings } from '@src/api/settings'
import { useQuery } from '@src/utils/queryParamsHooks'
import { FormattedMessage } from 'react-intl'
import { useCreateOptionInNewTab } from '@src/utils/useCreateOptionInNewTab'
import { Statuses } from '@src/interfaces'
import { skillTypeOptions } from '@src/features/Skills/constants/skillTypeOptions'
import { SkillMasteryInputFields } from '@src/features/Skills/components/SkillMasteryInputFields'
import { FunctionSelector } from './components/FunctionSelector'
import Instructions from './Instructions'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import { SkillResourcesInput } from './components/SkillResourcesInput'

export const SkillForm = ({
  hidePlaybookInput = false,
  typeOptions,
}: {
  hidePlaybookInput?: boolean
  typeOptions?: SkillType[]
}) => {
  const { values } = useLapeContext<SkillInterface>()
  const { data: skillsSettings } = useGetSkillsSettings()

  const skillOptions: RadioOption[] = skillTypeOptions.filter(option =>
    typeOptions ? typeOptions.includes(option.value) : true,
  )
  return (
    <>
      <Subheader>General details</Subheader>
      <InputGroup>
        <LapeNewInput label="Skill name" name="name" required />
        <LapeNewTextArea
          label="Description"
          name="description"
          required
          rows={2}
          description="A short description that will help managers conducting performance reviews understand what this skill means"
        />
        <LapeRadioSelectInput
          name="owner"
          label="Skill owner"
          selector={selectorKeys.employee}
        />
      </InputGroup>
      <Subheader>Resources</Subheader>
      <InputGroup>
        {!hidePlaybookInput && (
          <LapeMultiInput
            title="Skills playbooks"
            data-name="playbook_urls"
            valueFieldName={undefined}
            withCell
            name="playbook_urls"
            description="Company guidelines on how the skill should be assessed and developed"
          />
        )}
        <SkillResourcesInput />
      </InputGroup>
      <Subheader>Skill category</Subheader>
      <InputGroup>
        <Group>
          <LapeNewRadioButtons
            name="skill_type"
            aria-label="Skill category"
            onAfterChange={() => {
              values.function = undefined
            }}
            options={skillOptions.map(({ tooltip, ...rest }) => ({
              ...rest,
              description: tooltip,
            }))}
            variant="cell"
            direction="column"
            cellBackgroundColor="transparent"
          />
        </Group>
        <FunctionSelector />
        {!skillsSettings?.behaviours_assessment_enabled && (
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Set this skill to be assessed in upwards reviews',
              description:
                'If upwards reviews are enabled without value-based behaviours, this skill will be present in the performance cycle upwards review scorecards',
            }}
            name="is_upwards_review_skill"
          />
        )}
      </InputGroup>
      <Subheader>Skill mastery</Subheader>
      <SkillMasteryInputFields />
    </>
  )
}

export const EditSkill = () => {
  const { values, submit, initialValues } = useLapeContext<SkillInterface>()
  const [isInstructionsOpen, toggleInstructions] = useToggle()
  const [examplesOpen, toggleExamplesOpen] = useToggle()
  const { query } = useQuery<{ ref?: 'fill-skills-after-submit' }>()
  const { data: skillsSettings } = useGetSkillsSettings()
  const fillSkillAfterSubmit =
    !skillsSettings?.skills_approvals_enabled && query?.ref === 'fill-skills-after-submit'
  const { keepOptionIdInLocalStorage, showStatusPopup } = useCreateOptionInNewTab(
    LOCAL_STORAGE.SKILL_CREATED,
  )
  const showFillAfterSubmitPopup = (createdSkillId: number) => {
    keepOptionIdInLocalStorage(createdSkillId)
    showStatusPopup(
      <FormattedMessage
        id="performance.skills.successCreate.title"
        defaultMessage="Skill created"
      />,
      <FormattedMessage
        id="performance.skills.successCreate.description"
        defaultMessage="Please close this tab and return back to page."
      />,
    )
  }
  const onSubmit = () => {
    if (values.id && values.status === Statuses.draft) {
      values.status = Statuses.pending
    }
    return submit()
  }
  return (
    <PageWrapper>
      <PageHeader
        title={values.id ? `Edit skill: ${values.name}` : 'Add new skill'}
        backUrl={ROUTES.PERFORMANCE.SKILLS}
      />
      <PageBody>
        <SettingsButtons mb="s-8">
          <MoreBar.Action
            useIcon="InfoOutline"
            data-testid="open_instructions_button"
            onClick={() => {
              toggleExamplesOpen.off()
              toggleInstructions.on()
            }}
          >
            Show instructions
          </MoreBar.Action>
          <MoreBar.Action
            useIcon="InfoOutline"
            data-testid="open_examples_button"
            onClick={() => {
              toggleInstructions.off()
              toggleExamplesOpen.on()
            }}
          >
            Show examples
          </MoreBar.Action>
        </SettingsButtons>
        <SkillForm />
      </PageBody>
      <PageActions>
        <SaveDraftButton />
        <NewSaveButtonWithPopup<SkillInterface>
          useValidator
          noPopup={fillSkillAfterSubmit}
          onClick={onSubmit}
          hideWhenNoChanges={!(values.id && values.status === Statuses.draft)}
          onSubmitError={() => {
            // @ts-expect-error reset to initial state
            values.status = initialValues.status
          }}
          onAfterSubmit={res => {
            if (fillSkillAfterSubmit) {
              showFillAfterSubmitPopup(res.id)
            } else {
              navigateReplace(pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id: res.id }))
            }
          }}
        >
          {!values.id
            ? 'Submit'
            : values.id && values.status === Statuses.draft
            ? 'Save and Publish'
            : 'Save changes'}
        </NewSaveButtonWithPopup>
      </PageActions>
      <Instructions isOpen={isInstructionsOpen} onClose={toggleInstructions.off} />
      <SkillExamples
        isOpen={examplesOpen}
        onCopy={(prop, value) => {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          values[prop] = value
        }}
        onClose={toggleExamplesOpen.off}
      />
    </PageWrapper>
  )
}
