import { IconName, Color, Token } from '@revolut/ui-kit'

export const features = [
  {
    title: 'HR management',
    description: 'Features to manage the complete employee lifecycle',
    icon: 'People' as const,
    items: ['Employees', 'Lifecycle', 'Timeoff', 'Contracts', 'Documents'],
  },
  {
    title: 'Performance',
    description: 'Advanced performance features to continuously raise the bar',
    icon: 'AddContact' as const,
    items: ['Goals', 'Reviews'],
  },
  {
    title: 'Recruitment',
    description: 'End to end applicant tracking and candidate management',
    icon: 'AddContact' as const,
    items: ['Headcount planning', 'Job postings', 'Candidate management', 'Offer forms'],
  },
  {
    title: 'Organisation',
    icon: 'Bank' as const,
    items: ['Teams', 'Roles and Functions', 'Locations', 'Legal entities'],
  },
  {
    title: 'Administration and support',
    icon: 'HelpChatOutline' as const,
    items: [
      'Customer support',
      'Access management',
      'Dedicated customer success manager',
    ],
  },
]

export interface PlanFeatures {
  title: string
  icon: IconName
  items?: string[]
}

export const plansConfig: {
  id: string
  features: PlanFeatures[]
  bgColor: Color
  planDescription: string
}[] = [
  {
    id: 'performance',
    features: [
      {
        title: 'Goals and Roadmaps',
        icon: 'Target',
        items: [
          'Company and team goals',
          'Goals hierarchy and cascading',
          'Roadmaps synced with Jira, Notion, Linear and more',
        ],
      },
      {
        title: 'Performance reviews and reporting',
        icon: 'StarSemi',
        items: [
          'Competency Frameworks',
          '1-1 meeting notes',
          'Flexible review cycles',
          'PIP and probation',
          'Dashboards and analytics',
        ],
      },
      {
        title: 'Engagement surveys',
        icon: 'Questionnaire',
        items: ['Flexible audience', 'Anonymous results', 'Notifications', 'AI insights'],
      },
      {
        title: 'Organisation structure',
        icon: 'Bank',
        items: [
          'Departments and teams',
          'Roles and Functions',
          'Locations',
          'Legal entities',
        ],
      },
      {
        title: 'Administration and support',
        icon: 'HelpChatOutline',
        items: ['Customer support', 'Roles and permissions'],
      },
    ],
    bgColor: Token.color.widgetBackground,
    planDescription: 'Features:',
  },
  {
    id: 'hr',
    features: [
      {
        title: 'Time management',
        icon: 'Resort',
        items: [
          'Time off policies & approvals',
          'Public holidays',
          'Time and attendance',
        ],
      },
      {
        title: 'Document management',
        icon: 'DocumentsPair',
        items: ['Documents', 'eSignatures with DocuSign'],
      },
      {
        title: 'Employee management',
        icon: '20/AutoExchange',
        items: ['Onboarding & offboarding', 'Compensation and benefits'],
      },
      {
        title: 'Payroll reporting',
        icon: 'CreditBag',
        items: ['Employee change tracking', 'Payroll integrations'],
      },
    ],
    bgColor: Token.color.widgetBackground,
    planDescription: 'Everything in Performance, plus:',
  },
  {
    id: 'recruitment',
    features: [
      {
        title: 'Workforce planning',
        icon: 'RepairTool',
        items: ['Headcount requests', 'Approval process'],
      },
      {
        title: 'Find candidates',
        icon: 'UsersSearch',
        items: [
          'Hosted careers site',
          'Job posting management',
          'Connected job boards',
          'CRM',
          'Employee referrals',
        ],
      },
      {
        title: 'Evaluate candidates',
        icon: 'LikeDislike',
        items: [
          'AI CV screening',
          'AI interview co-pilot',
          'Workflow automations',
          'Scheduling',
        ],
      },
      {
        title: 'Hire candidates',
        icon: 'Heart',
        items: ['Offer management', 'Confidential candidates'],
      },
    ],
    bgColor: Token.color.widgetBackground,
    planDescription: 'Everything in Performance, plus:',
  },
  {
    id: 'allinone',
    features: [
      {
        title: 'Premium support',
        icon: 'Premium',
        items: ['Dedicated Slack channel', 'SLA for bugs', 'Early access to features'],
      },
      {
        title: 'Advanced communication',
        icon: 'SendMessage',
        items: ['Custom Slack bots', 'Message templates', 'Dynamic communication groups'],
      },
    ],
    bgColor: Token.color.widgetBackground,
    planDescription: 'Everything in Performance, HR and Recruitment, plus:',
  },
]
