import React from 'react'
import { HStack, Icon, Token, Text } from '@revolut/ui-kit'

import { formatPercentage } from '@src/utils/format'

interface Props {
  avgScore: number | null | undefined
  trendAvgScore: number | null | undefined
  variant?: 'xs' | 'sm'
}
export const TrendIndicator = ({ avgScore, trendAvgScore, variant = 'xs' }: Props) => {
  if (avgScore == null || trendAvgScore == null) {
    return null
  }
  const trendDiff = avgScore - trendAvgScore
  const trendDiffFormatted = formatPercentage(trendDiff / 10)
  const iconProps = { color: Token.color.greyTone50, size: variant === 'xs' ? 12 : 15 }

  if (trendDiff === 0) {
    return (
      <HStack mr="s-8" space="s-2" align="center">
        <Icon {...iconProps} name="ArrowTopDown" />
        <Text
          variant={variant === 'xs' ? 'body3' : 'emphasis1'}
          color={Token.color.greyTone50}
        >
          {trendDiffFormatted}
        </Text>
      </HStack>
    )
  }
  return (
    <HStack mr="s-8" space="s-2" align="center">
      <Icon {...iconProps} name={trendDiff > 0 ? 'ArrowRates' : 'ArrowRatesDown'} />
      <Text
        variant={variant === 'xs' ? 'body3' : 'emphasis1'}
        color={Token.color.greyTone50}
      >
        {trendDiff > 0 ? `+${trendDiffFormatted}` : trendDiffFormatted}
      </Text>
    </HStack>
  )
}
