import React from 'react'

import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { DepartmentResourcesStatsNavigation } from '@src/pages/Forms/DepartmentForm/Resources/components/StatsNavigation'

export const DepartmentResourcesTotal = () => {
  return (
    <Table.Widget>
      <Table.Widget.Info>
        <DepartmentResourcesStatsNavigation />
      </Table.Widget.Info>

      <Table.Widget.Table>
        {/* TODO: https://revolut.atlassian.net/browse/REVCOR-4013 implement with  */}
        <EmptyTableRaw title="Resources will appear here" />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
