import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import {
  List,
  Widget,
  Text,
  Item,
  Tag,
  VStack,
  HStack,
  Icon,
  Token,
} from '@revolut/ui-kit'
import { EngagementInsightsInterface } from '@src/interfaces/engagement'
import { normalizedScoreToColors } from '@src/features/Engagement/helpers/statValuesToColor'
import { getCategoryIconName, getCategoryName } from './helpers'

interface Props {
  insight: EngagementInsightsInterface
  onClose: VoidFunction
}

export const InsightDetailsSideBar = ({ insight, onClose }: Props) => {
  return (
    <SideBar
      isOpen
      title={insight.title}
      subtitle={
        <HStack align="center" gap="s-6">
          <Icon name={getCategoryIconName(insight.category.id)} size={14} />
          {getCategoryName(insight.category.id)}
        </HStack>
      }
      onClose={onClose}
    >
      <Text variant="h5">Summary</Text>
      <Widget p="s-12" mt="s-12">
        <List variant="compact" color={Token.color.greyTone50}>
          {insight.topics.map((topic, i) => (
            <List.Item key={i} useIcon="Dot">
              {topic}
            </List.Item>
          ))}
        </List>
      </Widget>
      <Text variant="h5" mt="s-24">
        Supporting comments
      </Text>
      <VStack mt="s-12" gap="s-8">
        {insight.supporting_answers.map(answer => (
          <Item key={answer.id}>
            <Item.Content>
              <Item.Title>{answer.answer_text}</Item.Title>
              <Item.Description>Q: {answer.question.question_text}</Item.Description>
            </Item.Content>
            {typeof answer.answer_score === 'number' && (
              <Item.Side>
                <Item.Value>
                  <Tag
                    variant="outlined"
                    color={normalizedScoreToColors(answer.answer_score)?.color}
                  >
                    {answer.answer_score.toFixed(1)}
                  </Tag>
                </Item.Value>
              </Item.Side>
            )}
          </Item>
        ))}
      </VStack>
    </SideBar>
  )
}
