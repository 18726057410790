import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, HStack, Subheader } from '@revolut/ui-kit'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { KeyInsights } from '@src/features/AiInsights/Performance/components/Summary/KeyInsights'
import { DetailsButton } from '@src/features/AiInsights/Performance/components/Summary/DetailsButton'
import { usePerformanceInsights } from '@src/features/AiInsights/Performance/components/SkillsOutput/hooks/usePerformanceInsights'
import { SkillPerformanceOutputProps } from '@src/features/AiInsights/Performance/components/SkillsOutput/PerformanceOutput'

interface Props {
  employeeId: number
  cycleId: number
  skillId: number
  onDetailsClick: (props: SkillPerformanceOutputProps) => void
}

export const PerformanceInsights = ({
  employeeId,
  cycleId,
  skillId,
  onDetailsClick,
}: Props) => {
  const {
    aiInsights,
    insightsGroup,
    skillRatingHistoryData,
    skill,
    growthPlan,
    oneToOneInsights,
    oneToOneMeeting,
    isLoading,
  } = usePerformanceInsights(employeeId, cycleId, skillId)

  if (!insightsGroup || !skill) {
    return null
  }

  const hasDetails =
    skillRatingHistoryData.length > 0 || !!growthPlan || oneToOneInsights.length > 0
  const hasNoContent = !aiInsights?.data

  const handleDetailsClick = () => {
    onDetailsClick({
      cycleId,
      skill,
      skillRatingHistoryData,
      growthPlan,
      oneToOneInsights,
      oneToOneMeeting,
      isLoading,
    })
  }

  if (hasNoContent) {
    return null
  }

  return (
    <Box p="s-16" data-testid="performance_skill_insights_widget">
      <Box mt="-s-12">
        <Subheader>
          <Subheader.Title>
            <HStack space="s-4" align="center">
              <AiIcon />
              <FormattedMessage
                id="performance.insights.subheader.title"
                defaultMessage="Insights"
              />
            </HStack>
          </Subheader.Title>
        </Subheader>
      </Box>
      {!!aiInsights?.data && (
        <KeyInsights
          summary={aiInsights.data}
          details={
            <>
              {(hasDetails || isLoading) && (
                <DetailsButton isLoading={isLoading} onClick={handleDetailsClick} />
              )}
            </>
          }
        />
      )}
      {!aiInsights?.data && (hasDetails || isLoading) && (
        <DetailsButton isLoading={false} onClick={handleDetailsClick} />
      )}
    </Box>
  )
}
