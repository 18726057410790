import React from 'react'
import styled from 'styled-components'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys, selectorToUrl } from '@src/constants/api'
import { Link } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { EligibleEmployeesInterface } from '@src/interfaces/supportTool/eligibleEmployees'
import { Text, Flex, Action, Token } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor, navigateTo } from '@src/actions/RouterActions'
import { ContributorType } from '@src/interfaces/talent/performance'
import { getSpecialisationTitle } from '@src/features/Roles/helpers'

const Cell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

const CustomLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  justify-self: start;

  &:hover {
    text-decoration: underline;
  }
`

export const employeeNameColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.text,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.all_employees,
  title: 'Employee',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.employee.id }),
}

export const employeeStatusColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.status.id',
  dataPoint: 'employee.status.name',
  sortKey: 'employee__status',
  filterKey: 'employee__status',
  selectorsKey: selectorKeys.employee_status_pending,
  title: 'Status',
  insert: ({ data }) => <Text>{data.employee.status?.name || '-'}</Text>,
}

export const seniorityColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const specialisationColumn = (
  specialisationsEnabled: boolean,
): ColumnInterface<EligibleEmployeesInterface> => ({
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: getSpecialisationTitle(specialisationsEnabled),
  dynamicHyperlinks: data => {
    return data.specialisation?.id
      ? pathToUrl(selectorToUrl[selectorKeys.specialisations] as string, {
          id: data.specialisation.id,
        })
      : null
  },
})

export const teamNameColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.team.id',
  dataPoint: 'employee.team.name',
  sortKey: null,
  filterKey: 'employee__team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) => {
    const team = data.team || data.employee.team

    return (
      <Cell>
        {team ? (
          <CustomLink
            to={getLocationDescriptor(
              pathToUrl(selectorToUrl[selectorKeys.team] as string, {
                id: team.id,
              }),
            )}
          >
            {team.name}
          </CustomLink>
        ) : (
          '-'
        )}
      </Cell>
    )
  },
}

export const lineManagerColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.line_manager.id',
  dataPoint: 'employee.line_manager.full_name',
  sortKey: 'line_manager__id',
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.all_employees,
  title: 'Line manager',
  insert: ({ data }) => {
    const lm = data.line_manager || data.employee.line_manager

    return (
      <Cell>
        {lm ? (
          <CustomLink
            to={getLocationDescriptor(
              pathToUrl(selectorToUrl[selectorKeys.employee] as string, {
                id: lm.id,
              }),
            )}
          >
            {lm.full_name}
          </CustomLink>
        ) : (
          '-'
        )}
      </Cell>
    )
  },
}

export const functionalManagerColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.quality_control.id',
  dataPoint: 'employee.quality_control.full_name',
  sortKey: 'functional_manager__id',
  filterKey: 'functional_manager__id',
  selectorsKey: selectorKeys.all_employees,
  title: 'Functional manager',
  insert: ({ data }) => {
    const fm = data.functional_manager || data.employee.quality_control

    return (
      <Cell>
        {fm ? (
          <CustomLink
            to={getLocationDescriptor(
              pathToUrl(selectorToUrl[selectorKeys.employee] as string, {
                id: fm.id,
              }),
            )}
          >
            {fm.full_name}
          </CustomLink>
        ) : (
          '-'
        )}
      </Cell>
    )
  },
}

export const cycleColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.text,
  idPoint: 'cycle.id',
  dataPoint: 'cycle.name',
  sortKey: 'cycle__id',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Performance cycle',
}

export const eligibilityColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'is_disabled',
  dataPoint: 'is_disabled',
  sortKey: 'is_disabled',
  filterKey: 'is_disabled',
  selectorsKey: selectorKeys.yes_no_inverted_value_options,
  title: 'Eligibility',
  insert: ({ data }) => (
    <Cell>
      {data.is_disabled ? (
        <Text color={Token.color.danger}>No</Text>
      ) : (
        <Text color={Token.color.success}>Yes</Text>
      )}
    </Cell>
  ),
}

export const createScorecardsColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'should_create_scorecard',
  dataPoint: 'should_create_scorecard',
  sortKey: 'should_create_scorecard',
  filterKey: 'should_create_scorecard',
  selectorsKey: selectorKeys.yes_no_inverted_value_options,
  title: 'Create scorecards',
  insert: ({ data }) => (
    <Cell>
      {data.should_create_scorecard ? (
        <Text color={Token.color.success}>Yes</Text>
      ) : (
        <Text color={Token.color.danger}>NO</Text>
      )}
    </Cell>
  ),
}

export const talentTypeColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'reviewed_employee_type',
  dataPoint: 'reviewed_employee_type',
  sortKey: 'reviewed_employee_type',
  filterKey: null,
  selectorsKey: selectorKeys.none, // fixme: BE doesn't support such filter
  title: 'Talent type',
  insert: ({ data }) => {
    return (
      {
        [ContributorType.IC]: 'Individual Contributor',
        [ContributorType.Mgr]: 'Manager',
        [ContributorType.PersonalKPI]: 'Individual with personal KPI',
      }[data.reviewed_employee_type] || ''
    )
  },
}

export const commentColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'reason_for_disabling',
  dataPoint: 'reason_for_disabling',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Comment',
  insert: ({ data }) => (
    <Cell>
      <Flex justifyContent="flex-start" width="100%">
        <Tooltip
          placement="top"
          text={data.reason_for_disabling}
          hide={!data.reason_for_disabling}
        >
          <Action
            onClick={() => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.ELIGIBLE_PEOPLE_EDIT.GENERAL, {
                  id: data.id,
                }),
              )
            }}
          >
            {data.reason_for_disabling ? 'View' : 'Click to add'}
          </Action>
        </Tooltip>
      </Flex>
    </Cell>
  ),
}

export const actionsColumn: ColumnInterface<EligibleEmployeesInterface> = {
  type: CellTypes.insert,
  idPoint: 'cycle.id',
  dataPoint: 'cycle.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
}
