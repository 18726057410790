import React, { useMemo } from 'react'
import {
  getDeleteRoadmapColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapOwnerColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '../../constants/columns/roadmap'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
} from '../../interfaces/data'
import AdjustableTable, {
  AdjustableTableProps,
} from '@src/components/TableV2/AdjustableTable'
import { roadmapsExtraRequests, roadmapsRequests } from '@src/api/roadmaps'
import { useShowRoadmapDetails } from '.'
import { useTable } from '@src/components/Table/hooks'
import {
  RoadmapChangelogContext,
  useRoadmapChangelog,
} from '../RoadmapChangelog/RoadmapChangelog'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { TableNames } from '@src/constants/table'
import { DeleteActionProps } from '@src/components/ColumnInserts/DeleteAction/DeleteAction'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { H5, Token } from '@revolut/ui-kit'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

type CommonTableProps = Pick<
  AdjustableTableProps<RoadmapInterface>,
  | 'dataType'
  | 'expandableType'
  | 'name'
  | 'noDataMessage'
  | 'onRowClick'
  | 'useWindowScroll'
>
export interface RoadMapTablesProps {
  defaultFilters: FilterByInterface[]
  tableProps: { name: TableNames } & Partial<CommonTableProps>
  allowEdit: boolean
  defaultSorting?: SortByInterface[]
  reviewCycle?: ReviewCyclesInterface
  row?: RowInterface<RoadmapInterface>
}
export const commonRoadmapSorting = [
  {
    sortBy: 'priority',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'due_date',
    direction: SORT_DIRECTION.DESC,
  },
]
export const useRenderRoadmapTables = (props: RoadMapTablesProps) => {
  const { defaultFilters, defaultSorting, reviewCycle, tableProps, allowEdit, row } =
    props
  const sorting = defaultSorting || commonRoadmapSorting

  const confirmationPopup = useConfirmationDialog()

  const table = useTable<RoadmapInterface, Stats>(
    roadmapsRequests,
    defaultFilters,
    sorting,
  )
  const tableWithChangeLog = useTable<RoadmapInterface, Stats>(
    roadmapsExtraRequests,
    defaultFilters,
    sorting,
  )
  const { changelogState, changelogSwitcher } = useRoadmapChangelog(reviewCycle || null)
  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: changelogState.active,
  })

  const readOnlyRows: RowInterface<RoadmapInterface> = useMemo(
    () =>
      row || {
        cells: [
          {
            ...roadmapGenericNameColumn,
            width: 250,
          },
          {
            ...roadmapPriorityColumn,
            width: 65,
          },
          {
            ...roadmapProgressColumn,
            width: 110,
          },
          {
            ...roadmapStatusColumn,
            width: 80,
          },
          {
            ...roadmapStartDate,
            width: 95,
          },
          {
            ...roadmapDueDate,
            width: 95,
          },
          {
            ...roadmapResolutionAtColumn,
            width: 90,
          },
          {
            ...roadmapOwnerColumn,
            width: 70,
          },
          {
            ...roadmapJiraLabelColumn,
            width: 80,
          },
          {
            ...roadmapEpicUrlColumn,
            width: 80,
          },
        ],
      },
    [row],
  )

  const renderTable = (override: Partial<AdjustableTableProps<RoadmapInterface>>) => {
    const getEditableRows = (
      handleDelete: DeleteActionProps['handleDelete'],
    ): RowInterface<RoadmapInterface> => {
      return {
        cells: [
          ...readOnlyRows.cells,
          {
            ...getDeleteRoadmapColumn({ handleDelete }),
            width: 60,
          },
        ],
      }
    }

    const onDeleteRoadMap = async (itemId: string | number) => {
      confirmationPopup.show({
        showHeader: false,
        body: (
          <H5 color={Token.color.foreground}>
            Are you sure you want to delete this roadmap?
          </H5>
        ),
        noMessage: 'Cancel',
        yesMessage: 'Confirm',
        onConfirm: async () => {
          await roadmapsExtraRequests.deleteItem(itemId)
          table.refresh()
        },
      })
    }
    const currentTable = changelogState.active ? tableWithChangeLog : table
    const currentTableProps: AdjustableTableProps<RoadmapInterface> = {
      ...currentTable,
      dataType: 'roadmap item',
      emptyState: <EmptyTableRaw title="All your roadmap items will appear here." />,
      expandableType: 'chevron',
      onRowClick: showRoadmapDetails,
      useWindowScroll: true,
      count: currentTable.data.length,
      data: currentTable.data,
      row:
        allowEdit && !changelogState.active
          ? getEditableRows(onDeleteRoadMap)
          : readOnlyRows,
      ...tableProps,
      ...override,
    }

    return (
      <RoadmapChangelogContext.Provider value={changelogState}>
        <AdjustableTable<RoadmapInterface> {...currentTableProps} />
        {renderSidebar({ canEdit: allowEdit, onAfterUpdate: () => table.refresh() })}
      </RoadmapChangelogContext.Provider>
    )
  }
  return { renderTable, tableWithChangeLog, table, changelogSwitcher, changelogState }
}
