import React from 'react'

import SideBar from '@src/components/SideBar/SideBar'
import Form from '@src/features/Form/Form'

import { probationTemplateRequests } from '@src/api/probationTemplate'
import { ProbationTemplateFormBody } from './ProbationTemplateFormBody'

interface Props {
  onClose: VoidFunction
  onAfterSubmit: VoidFunction
  templateId: number
}

export const EditProbationTemplateSidebar = ({
  onClose,
  onAfterSubmit,
  templateId,
}: Props) => {
  return (
    <SideBar title="Edit template" onClose={onClose} isOpen>
      <Form
        forceParams={{ id: String(templateId) }}
        disableLocalStorageCaching
        api={probationTemplateRequests}
      >
        <ProbationTemplateFormBody onClose={onClose} onAfterSubmit={onAfterSubmit} />
      </Form>
    </SideBar>
  )
}
