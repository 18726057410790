import React from 'react'
import {
  Side,
  Button,
  InputGroup,
  HStack,
  Avatar,
  Text,
  Subheader,
  Item,
  Icon,
  useToggle,
  useTooltip,
  Tooltip,
} from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { connect } from 'lape'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Country } from '@src/interfaces/selectors'
import { getCountryFlagImageByCode } from '@src/utils/getCountryFlagImageByCode'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ProbationTemplateInterface,
  ProbationTemplatePreviewRequest,
  ProbationTimeUnits,
  ResultOptionType,
} from '@src/interfaces/probationTemplate'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ItemSwitch } from '@src/components/Inputs/ItemSwitch/ItemSwitch'
import { EligibleEmployeesSidebar } from './EligibleEmployeesSidebar'
import pluralize from 'pluralize'
import { useGetProbationTemplateData } from '@src/pages/Forms/ProbationTemplate/common'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { IdAndName } from '@src/interfaces'
import { templateResultToOutcomeLabel } from '@src/features/Probation/Templates/constants'

interface Props {
  onClose: VoidFunction
  onAfterSubmit: VoidFunction
}

export const ProbationTemplateFormBody = connect(({ onAfterSubmit, onClose }: Props) => {
  const { values } = useLapeContext<ProbationTemplateInterface>()
  const tooltip = useTooltip()
  const [isElibibleEmployeesSidebarOpen, toggleEligibleEmployeesSidebar] = useToggle()

  const {
    previewData,
    lastTimelineInd,
    weeksOptions,
    numberOfCheckpointsOptions,
    recalculatePreview,
    loading,
  } = useGetProbationTemplateData()

  const rulesCount = values.rules && Object.values(values.rules).length
  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput<Country>
          name="country"
          label="Country"
          selector={selectorKeys.countries}
          inputProps={{
            renderPrefix: () =>
              values.country?.country_code ? (
                <Avatar
                  size={20}
                  variant="brand"
                  image={getCountryFlagImageByCode(values.country.country_code)}
                />
              ) : null,
          }}
        >
          {option => (
            <HStack gap="s-8" align="center">
              <Avatar
                size={20}
                variant="brand"
                image={getCountryFlagImageByCode(option.value.country_code)}
              />
              <Text>{option.value.name}</Text>
            </HStack>
          )}
        </LapeRadioSelectInput>
        <LapeNewInput name="name" label="Name" required />
        <ItemSwitch
          title={'Enable this template'}
          checked={values.status === 'enabled'}
          onChange={() => {
            if (values.status === 'disabled') {
              values.status = 'enabled'
            } else {
              values.status = 'disabled'
            }
          }}
        />
        <LapeRadioSelectInput
          name="entity"
          label="Enitity"
          selector={selectorKeys.entity}
        />
      </InputGroup>
      <Subheader>Employees</Subheader>
      <Item use="button" type="button" onClick={toggleEligibleEmployeesSidebar.on}>
        <Item.Avatar>
          <Avatar useIcon="People" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            {rulesCount ? `${pluralize('filter', rulesCount, true)} selected` : 'All'}
          </Item.Title>
        </Item.Content>
        <Item.Side>
          <Icon name="Pencil" />
        </Item.Side>
      </Item>
      <Subheader>
        <HStack gap="s-8" align="center">
          <Text>Timeline</Text>
          <Icon name="InfoOutline" {...tooltip.getAnchorProps()} />
          <Tooltip {...tooltip.getTargetProps()}>
            This is the default timeline setting, make sure this works in your country
          </Tooltip>
        </HStack>
      </Subheader>
      <InputGroup>
        <InputGroup variant="horizontal">
          <LapeNewInput
            name="duration"
            type="number"
            label="Duration"
            required
            defaultValue={3}
          />
          <LapeRadioSelectInput<IdAndName<ProbationTimeUnits>>
            name="duration_time_unit"
            selector={selectorKeys.probation_template_time_units}
            searchable={false}
            required
          />
        </InputGroup>
        <LapeRadioSelectInput<{ id: number }>
          name="num_checkpoints"
          label="Number of checkpoints"
          searchable={false}
          options={numberOfCheckpointsOptions}
          value={
            numberOfCheckpointsOptions.find(el => el.value.id === values.num_checkpoints)
              ?.value
          }
          onChange={option => {
            if (option) {
              values.num_checkpoints = option?.id
              recalculatePreview({
                ...previewData,
                num_checkpoints: option.id,
              } as ProbationTemplatePreviewRequest)
            }
          }}
          disabled={loading}
        />
        <InputGroup variant="horizontal">
          <LapeNewInput
            name="notice_period"
            type="number"
            label="Notice period"
            required
            defaultValue={3}
          />
          <LapeRadioSelectInput<IdAndName<ProbationTimeUnits>>
            name="notice_period_time_unit"
            selector={selectorKeys.probation_template_time_units}
            searchable={false}
            required
          />
        </InputGroup>
      </InputGroup>
      <Subheader variant="nested">Goal setting deadline</Subheader>
      <LapeRadioSelectInput<{ id: number }>
        name={`timelines[0].weeks_due_date`}
        label="Time after joining"
        options={weeksOptions}
        value={
          weeksOptions.find(el => el.value.id === values.timelines?.[0].weeks_due_date)
            ?.value
        }
        onAfterChange={option => {
          if (option) {
            recalculatePreview({
              ...previewData,
              goals_setting_week_after_start: option.id,
            } as ProbationTemplatePreviewRequest)
          }
        }}
        disabled={loading}
      />
      <Subheader variant="nested">Final checkpoint deadline</Subheader>
      <LapeRadioSelectInput<{ id: number }>
        name={`timelines[${values.timelines?.[lastTimelineInd]}]?.weeks_due_date`}
        label="Time before probation end"
        message="Consider the required notice period"
        options={weeksOptions}
        value={
          weeksOptions.find(
            el => el.value.id === values.timelines?.[lastTimelineInd]?.weeks_due_date,
          )?.value
        }
        onAfterChange={option => {
          if (option) {
            recalculatePreview({
              ...previewData,
              final_decision_week_before_end: option.id,
            } as ProbationTemplatePreviewRequest)
          }
        }}
        disabled={loading}
      />

      <Subheader>Outcome</Subheader>
      <LapeNewMultiSelect<IdAndName<ResultOptionType>>
        name="result_options"
        placeholder="Possible outcomes"
        required
        value={values.result_options?.map(opt => ({
          id: opt,
          label: templateResultToOutcomeLabel[opt],
          value: { id: opt, name: templateResultToOutcomeLabel[opt] },
        }))}
        onChange={options => {
          values.allow_extension = options.some(opt => opt.value.id === 'extend')
          values.result_options = options.map(opt => opt.value.id)
        }}
        selector={selectorKeys.probation_outcomes}
      />

      <Subheader>Link to policy</Subheader>
      <LapeNewInput name="policy_document_link" label="Link" />

      <Side.Actions horizontal gradient="high">
        <Button type="button" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <NewSaveButtonWithPopup onAfterSubmit={onAfterSubmit} elevated useValidator>
          {values.id ? 'Update' : 'Confirm'}
        </NewSaveButtonWithPopup>
      </Side.Actions>
      {isElibibleEmployeesSidebarOpen && (
        <EligibleEmployeesSidebar onClose={toggleEligibleEmployeesSidebar.off} />
      )}
    </>
  )
})
