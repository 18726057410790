import React from 'react'
import { Flex, Icon, TextSkeleton, IconName, Token, VStack } from '@revolut/ui-kit'
import {
  EngagementInsightCategory,
  EngagementInsightsInterface,
  EngagementInsightStatus,
} from '@src/interfaces/engagement'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export type InsightsWidgetStatus = 'no_insights' | 'generating' | 'completed' | 'error'

export const getInsightsWidgetStatus = ({
  status,
  haveInsights,
  error,
  isInsightsLoading,
}: {
  status?: EngagementInsightStatus
  haveInsights?: boolean
  error?: boolean
  isInsightsLoading: boolean
}): InsightsWidgetStatus => {
  if (haveInsights && !isInsightsLoading) {
    return 'completed'
  }

  if (status === 'success' && !haveInsights && !isInsightsLoading) {
    return 'no_insights'
  }

  if (error || status === 'failed') {
    return 'error'
  }

  if (
    !status ||
    status === 'not_started' ||
    status === 'in_progress' ||
    isInsightsLoading
  ) {
    return 'generating'
  }

  return 'no_insights'
}

export const getStatusText = (
  status: InsightsWidgetStatus,
): { title: string; subtitle: string } => {
  switch (status) {
    case 'generating':
      return {
        title: 'We are generating your insights',
        subtitle: 'This may take a few minutes. Feel free to check back later!',
      }
    case 'error':
      return {
        title: 'Unable to generate insights',
        subtitle: 'We had some issue generating our insights, please try again soon.',
      }
    case 'no_insights':
    default:
      return {
        title: 'Not enough results',
        subtitle: 'There isn’t enough data to generate insights for this survey',
      }
  }
}

export const getStatusIndicator = (status: InsightsWidgetStatus) => {
  switch (status) {
    case 'generating':
      return (
        <VStack width="240px" height="60px" align="center">
          <TextSkeleton width="100%" variant="caption" />
          <TextSkeleton width="100%" variant="caption" />
          <TextSkeleton width="100%" variant="caption" />
        </VStack>
      )
    case 'error':
      return (
        <Flex height="60px" alignItems="center">
          <Icon name="CrossCircle" size={52} color={Token.color.greyTone50} />
        </Flex>
      )
    case 'no_insights':
    default:
      return (
        <Flex height="60px" alignItems="center">
          <Icon name="BarChartSearch" size={52} color={Token.color.greyTone50} />
        </Flex>
      )
  }
}

const categoryToIconNameMap: Record<EngagementInsightCategory, IconName> = {
  area_of_development: 'SocialDislike',
  strength: 'SocialLike',
  upward_trend: 'ArrowRates',
  downward_trend: 'ArrowRatesDown',
}

export const getCategoryIconName = (category: EngagementInsightCategory): IconName =>
  categoryToIconNameMap[category]

const categoryToNameMap: Record<EngagementInsightCategory, string> = {
  area_of_development: 'Weaknesses',
  strength: 'Strengths',
  upward_trend: 'Upward trends',
  downward_trend: 'Downward trends',
}

export const getCategoryName = (category: EngagementInsightCategory): string =>
  categoryToNameMap[category]

export const getLink = ({
  insight,
  surveyId,
  surveyRoundId,
}: {
  insight: EngagementInsightsInterface
  surveyId: number
  surveyRoundId: number
}) =>
  pathToUrl(
    ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.CATEGORY,
    {
      id: surveyId,
      itemId: insight.driver.id,
    },
    { survey_round_id: String(surveyRoundId) },
  )
