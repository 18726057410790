import React from 'react'
import { DataPoint, FlexProps, Token } from '@revolut/ui-kit'

interface Props extends Omit<FlexProps, 'label' | 'value'> {
  label: React.ReactNode
  value: React.ReactNode
  metric: React.ReactNode
}

export const SummaryDataPoint = ({ label, value, metric, ...flexProps }: Props) => (
  <DataPoint p="s-12" radius="r16" bg={Token.color.widgetBackground} {...flexProps}>
    <DataPoint.Label textAlign="center">{label}</DataPoint.Label>
    <DataPoint.Value textAlign="center">{value}</DataPoint.Value>
    <DataPoint.Label textAlign="center" color={Token.color.greyTone50}>
      {metric}
    </DataPoint.Label>
  </DataPoint>
)
