import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, HStack, Subheader, useToggle, Widget } from '@revolut/ui-kit'
import {
  ExternalMetricSources,
  InternalMetricSources,
  JiraCommonMetrics,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { insightsGroupModel } from '@src/features/AiInsights/Performance/models/insightsGroupModel'
import SideBar from '@components/SideBar/SideBar'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { PerformanceOutput } from '@src/features/AiInsights/Performance/components/PerformanceOutput/PerformanceOutput'
import { KeyInsights } from '@src/features/AiInsights/Performance/components/Summary/KeyInsights'
import { usePerformanceInsights } from '@src/features/AiInsights/Performance/components/PerformanceOutput/hooks/usePerformanceInsights'
import { DetailsButton } from '@src/features/AiInsights/Performance/components/Summary/DetailsButton'

interface Props {
  employeeId: number
  cycleId: number
}

export const PerformanceInsights = ({ employeeId, cycleId }: Props) => {
  const {
    insightsGroup,
    aiInsights,
    selectedOrgUnit,
    orgUnits,
    employeeJiraMetrics,
    employeeBitbucketMetrics,
    employeeInterviewsMetrics,
    orgUnitJiraMetrics,
    orgUnitBitbucketMetrics,
    orgUnitInterviewsMetrics,
    jiraEpicsTable,
    isLoading,
    isJiraMetricsLoading,
    isBitbucketMetricsLoading,
    isInterviewsMetricsLoading,
    onSelectOrgUnit,
  } = usePerformanceInsights(employeeId, cycleId)

  const [isDetailsSidebarOpen, detailsSidebarToggler] = useToggle()

  if (!insightsGroup || !selectedOrgUnit) {
    return null
  }

  const hasJiraMetrics =
    (!!employeeJiraMetrics &&
      !!orgUnitJiraMetrics &&
      insightsGroupModel.hasAnyNumericMetricEnabledForSource(
        insightsGroup,
        ExternalMetricSources.Jira,
      )) ||
    (jiraEpicsTable.count > 0 &&
      insightsGroupModel.hasMetricEnabled(
        insightsGroup,
        ExternalMetricSources.Jira,
        JiraCommonMetrics.EpicContribution,
      ))
  const hasBitbucketMetrics =
    !!employeeBitbucketMetrics &&
    !!orgUnitBitbucketMetrics &&
    insightsGroupModel.hasAnyMetricEnabledForSource(
      insightsGroup,
      ExternalMetricSources.Bitbucket,
    )
  const hasInterviewsMetrics =
    !!employeeInterviewsMetrics &&
    !!orgUnitInterviewsMetrics &&
    insightsGroupModel.hasAnyMetricEnabledForSource(
      insightsGroup,
      InternalMetricSources.Interviews,
    )

  const hasDetails = hasJiraMetrics || hasBitbucketMetrics || hasInterviewsMetrics
  const isDetailsLoading =
    isJiraMetricsLoading || isBitbucketMetricsLoading || isInterviewsMetricsLoading
  const hasNoContent = insightsGroup.sources.length === 0 || !aiInsights?.data

  if (hasNoContent) {
    return null
  }

  return (
    <Box data-testid="performance_insights_widget">
      <Widget p="s-16">
        <Box mt="-s-12">
          <Subheader>
            <Subheader.Title>
              <HStack space="s-4" align="center">
                <AiIcon />
                <FormattedMessage
                  id="performance.insights.subheader.title"
                  defaultMessage="Insights"
                />
              </HStack>
            </Subheader.Title>
          </Subheader>
        </Box>
        {!!aiInsights?.data && (
          <KeyInsights
            variant="list"
            summary={aiInsights.data}
            details={
              <>
                {(hasDetails || isDetailsLoading) && (
                  <DetailsButton
                    isLoading={isDetailsLoading}
                    onClick={detailsSidebarToggler.on}
                  />
                )}
              </>
            }
          />
        )}
        {!aiInsights?.data && (hasDetails || isDetailsLoading) && (
          <DetailsButton
            isLoading={isDetailsLoading}
            onClick={detailsSidebarToggler.on}
          />
        )}
      </Widget>
      <SideBar
        isOpen={isDetailsSidebarOpen}
        title={
          <FormattedMessage
            id="performance.insights.details.sidebar.title"
            defaultMessage="Performance output"
          />
        }
        onClose={detailsSidebarToggler.off}
      >
        <Box mt="-s-12">
          <PerformanceOutput
            employeeId={employeeId}
            aiInsights={aiInsights}
            insightsGroup={insightsGroup}
            selectedOrgUnit={selectedOrgUnit}
            orgUnits={orgUnits}
            employeeJiraMetrics={employeeJiraMetrics}
            employeeBitbucketMetrics={employeeBitbucketMetrics}
            employeeInterviewsMetrics={employeeInterviewsMetrics}
            orgUnitJiraMetrics={orgUnitJiraMetrics}
            orgUnitBitbucketMetrics={orgUnitBitbucketMetrics}
            orgUnitInterviewsMetrics={orgUnitInterviewsMetrics}
            jiraEpicsTable={jiraEpicsTable}
            isLoading={isLoading}
            isJiraMetricsLoading={isJiraMetricsLoading}
            isBitbucketMetricsLoading={isBitbucketMetricsLoading}
            isInterviewsMetricsLoading={isInterviewsMetricsLoading}
            isJiraMetricsVisible={hasJiraMetrics || isJiraMetricsLoading}
            isBitbucketMetricsVisible={hasBitbucketMetrics || isBitbucketMetricsLoading}
            isInterviewsMetricsVisible={
              hasInterviewsMetrics || isInterviewsMetricsLoading
            }
            onSelectOrgUnit={onSelectOrgUnit}
          />
        </Box>
      </SideBar>
    </Box>
  )
}
