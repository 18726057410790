import {
  BitbucketNumericMetrics,
  InterviewNumericMetrics,
  JiraNumericMetrics,
  Metrics,
  NumericMetrics,
} from '@src/features/AiInsights/Performance/interfaces/metrics'
import { PerformanceInsightsGroupSourceMetricInterface } from '@src/features/AiInsights/Performance/interfaces/insights'

export const jiraNumericMetrics: JiraNumericMetrics[] = Object.values(JiraNumericMetrics)

export const bitbucketNumericMetrics: BitbucketNumericMetrics[] = Object.values(
  BitbucketNumericMetrics,
)

export const interviewsNumericMetrics: InterviewNumericMetrics[] = Object.values(
  InterviewNumericMetrics,
)

interface MetricModel {
  isNumeric: <T extends NumericMetrics>(
    metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
  ) => metric is PerformanceInsightsGroupSourceMetricInterface<T>
  isEnabled: <T extends Metrics>(
    metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
  ) => metric is PerformanceInsightsGroupSourceMetricInterface<T>
  isByName: <T extends Metrics>(
    metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
    name: T,
  ) => metric is PerformanceInsightsGroupSourceMetricInterface<T>
}

const isNumeric = <T extends NumericMetrics>(
  metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
): metric is PerformanceInsightsGroupSourceMetricInterface<T> =>
  !![...jiraNumericMetrics, ...bitbucketNumericMetrics, ...interviewsNumericMetrics].find(
    metricName => metricName === metric.name,
  )

const isEnabled = <T extends Metrics>(
  metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
): metric is PerformanceInsightsGroupSourceMetricInterface<T> => metric.enabled

const isByName = <T extends Metrics>(
  metric: PerformanceInsightsGroupSourceMetricInterface<Metrics>,
  name: T,
): metric is PerformanceInsightsGroupSourceMetricInterface<T> => metric.name === name

const createPerformanceInsightsGroupModel = (): MetricModel => ({
  isNumeric,
  isEnabled,
  isByName,
})

export const metricModel = createPerformanceInsightsGroupModel()
