import React, { useCallback, useState } from 'react'
import {
  Action,
  Avatar,
  Button,
  DragAndDrop,
  Flex,
  Group,
  HStack,
  Text,
  IconButton,
  Item,
  ItemSkeleton,
  Side,
  Subheader,
  Token,
  Tag,
  Spacer,
} from '@revolut/ui-kit'

import SideBar from '@src/components/SideBar/SideBar'
import { OrganisationStructureTab } from '@src/pages/Settings/Structures/types'
import { move } from '@src/utils/move'

type OrganisationStructureTabWithNonNullableState = OrganisationStructureTab & {
  state: NonNullable<OrganisationStructureTab['state']>
}

const filterNonNullableStructureState = (
  val: OrganisationStructureTab,
): val is OrganisationStructureTabWithNonNullableState => {
  return val.state !== null
}

interface StructureTabsSidebarProps {
  isOpen: boolean
  onChange: (tabs: OrganisationStructureTab[]) => void
  onClose: VoidFunction
  onTabClick?: (id: number) => void
  tabs?: OrganisationStructureTab[]
  title: React.ReactNode
  subtitle: React.ReactNode
}

export const StructureTabsSidebar = ({
  isOpen,
  onChange,
  onClose,
  onTabClick,
  tabs,
  title,
  subtitle,
}: StructureTabsSidebarProps) => {
  return (
    <SideBar isOpen={isOpen} onClose={onClose} title={title} subtitle={subtitle}>
      {tabs ? (
        <StructureTabsSidebarLoaded
          onChange={onChange}
          onClose={onClose}
          tabs={tabs.filter(filterNonNullableStructureState)}
          onTabClick={onTabClick}
        />
      ) : (
        <Group>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </Group>
      )}
    </SideBar>
  )
}

interface StructureTabsSidebarLoadedProps {
  onClose: VoidFunction
  onChange: (tabs: OrganisationStructureTab[]) => void
  tabs: OrganisationStructureTabWithNonNullableState[]
  onTabClick?: (id: number) => void
}

const StructureTabsSidebarLoaded = ({
  onClose,
  onChange,
  tabs,
  onTabClick,
}: StructureTabsSidebarLoadedProps) => {
  const [activeId, setActiveId] = useState<number | null>(null)
  const [isReorderActive, setIsReorderActive] = useState(false)

  const onDragStart = useCallback(event => setActiveId(event.active.id), [])
  const onDragCancel = useCallback(() => setActiveId(null), [])

  const onDragEnd = useCallback(
    event => {
      if (event.over) {
        const startIndex = event.active.data.current.sortable.index
        const endIndex = event.over.data.current.sortable.index
        onChange(startIndex === endIndex ? tabs : move(tabs, startIndex, endIndex))
      }

      setActiveId(null)
    },
    [tabs],
  )

  const activeItem = activeId && tabs.find(item => item.id === activeId)

  return (
    <>
      <Subheader>
        <Subheader.Title>Department tabs</Subheader.Title>
        <Subheader.Side>
          <Action onClick={() => setIsReorderActive(prev => !prev)}>
            {isReorderActive ? 'Done' : 'Reorder'}
          </Action>
        </Subheader.Side>
      </Subheader>
      <Flex flexDirection="column" gap="s-16">
        <DragAndDrop.Provider
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          <Group>
            <DragAndDrop.Sortable
              id="sortable-table-preferences"
              items={tabs.map(item => item.id)}
            >
              {sortable => {
                const tab = tabs.find(item => item.id === sortable.id)
                return tab ? (
                  <Item
                    {...(isReorderActive || !onTabClick
                      ? {}
                      : {
                          variant: 'disclosure',
                          use: 'button',
                          type: 'button',
                          onClick: () => {
                            onTabClick?.(tab.id)
                          },
                        })}
                    ref={sortable.setNodeRef}
                    style={{
                      transform: sortable.transform
                        ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                        : undefined,
                      transition: sortable.transition || 'none',
                      opacity: sortable.isDragging ? 0 : undefined,
                    }}
                  >
                    <Item.Avatar>
                      <Avatar useIcon={tab.icon} />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>
                        <HStack space="s-4">
                          <Text>{tab.name}</Text>
                          <Spacer />
                          <StatusTag tab={tab} />
                        </HStack>
                      </Item.Title>
                      <Item.Description>{tab.description}</Item.Description>
                    </Item.Content>
                    {isReorderActive && (
                      <Item.Side>
                        <IconButton
                          aria-label="Drag"
                          useIcon="Drag"
                          color={Token.color.greyTone20}
                          {...sortable.attributes}
                          {...sortable.listeners}
                          size={16}
                        />
                      </Item.Side>
                    )}
                  </Item>
                ) : null
              }}
            </DragAndDrop.Sortable>
          </Group>
          <DragAndDrop.DragOverlay>
            {activeItem && (
              <Item>
                <Item.Avatar>
                  <Avatar useIcon={activeItem.icon} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <HStack space="s-4">
                      <Text>{activeItem.name}</Text>
                      <Spacer />
                      <StatusTag tab={activeItem} />
                    </HStack>
                  </Item.Title>
                  <Item.Description>{activeItem.description}</Item.Description>
                </Item.Content>
                <Item.Side>
                  <IconButton
                    useIcon="Drag"
                    color={Token.color.greyTone20}
                    onClick={() => {}}
                    size={16}
                  />
                </Item.Side>
              </Item>
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
      </Flex>

      <Side.Actions>
        <Button elevated onClick={onClose}>
          Confirm
        </Button>
      </Side.Actions>
    </>
  )
}

interface StatusTagProps {
  tab: OrganisationStructureTabWithNonNullableState
}

const StatusTag = ({ tab }: StatusTagProps) => {
  return tab.state.id === 'disabled' || tab.state.id === 'selected' ? (
    <Tag variant="outlined" color={Token.color.greyTone50}>
      {tab.state.id === 'disabled' ? 'Disabled' : null}
      {tab.state.id === 'selected' ? 'Enabled for selected departments' : null}
    </Tag>
  ) : null
}
